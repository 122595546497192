import { getApiByResourceName } from "./api";

// positions-search
export async function getPlaybackData(dispatch, timeRange) {
  return await getApiByResourceName(
    dispatch,
    "positions-search",
    "PlaybackData",
    "get",
    null,
    timeRange
  );
}
