import { useState } from "react";
import { ReactComponent as Caret } from "../../assets/caret-down.svg";
import { EMPTY_TIME } from "../../constants";
import { getUserInfo } from "../../userUtils";
import {
  formatHoursMins,
  formatTime,
  isEmptyList,
  isNullOrUndefined,
} from "../../utils";
import { getProcessedEvents } from "../../turnaroundUtils";

function TurnaroundModuleServiceRecordRows(props) {
  const { item, timezone } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  const userInfo = !isNullOrUndefined(item.user)
    ? getUserInfo(item.user)
    : null;

  const displayName = !isNullOrUndefined(userInfo)
    ? userInfo.fullName
    : !isNullOrUndefined(item?.groundVehicle)
    ? item?.groundVehicle.name
    : "";

  const displayDetails = !isNullOrUndefined(item?.groundVehicle)
    ? item?.groundVehicle.gseType.name
    : null;

  const itemId = !isNullOrUndefined(userInfo)
    ? userInfo.uuid
    : !isNullOrUndefined(item?.groundVehicle)
    ? item?.groundVehicle.uuid
    : "";

  const processedEvents = !isEmptyList(item?.events)
    ? getProcessedEvents(item?.events)
    : null;

  return (
    <>
      <div
        className={`turnarounds-module-service-records-row${
          isExpanded ? " expanded" : ""
        }`}
        key={itemId}
        data-row-uuid={itemId}
        onClick={() => {
          if (!isEmptyList(item?.events)) {
            setIsExpanded((prev) => !prev);
          }
        }}
      >
        <div>
          <div className="display-name">
            <div className="toggle">
              {!isEmptyList(processedEvents?.eventPairs) && <Caret />}
            </div>
            <div className="display-name-values">
              <div>{displayName}</div>
              {!isNullOrUndefined(displayDetails) && (
                <div>
                  <label>{displayDetails}</label>
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <div>
            {!isNullOrUndefined(processedEvents.firstStart)
              ? formatTime(processedEvents.firstStart, timezone)
              : EMPTY_TIME}
          </div>
        </div>
        <div>
          <div>
            {!isNullOrUndefined(processedEvents.lastEnd)
              ? formatTime(processedEvents.lastEnd, timezone)
              : EMPTY_TIME}
          </div>
        </div>
        <div>
          <div>
            {!isNullOrUndefined(processedEvents.duration)
              ? formatHoursMins(processedEvents.duration)
              : ""}
          </div>
        </div>
      </div>
      {isExpanded &&
        !isEmptyList(processedEvents?.eventPairs) &&
        processedEvents.eventPairs.map((event, idx) => {
          return (
            <div
              className="turnarounds-module-service-records-row detail"
              key={`${itemId}=${idx}`}
              data-row-uuid={`${itemId}=${idx}`}
              data-parent-uuid={itemId}
            >
              <div>
                <div className="display-name">
                  <div></div>
                </div>
              </div>
              <div>
                <div>
                  {event.startTime
                    ? formatTime(event.startTime, timezone)
                    : EMPTY_TIME}
                </div>
              </div>
              <div>
                <div>
                  {event.endTime
                    ? formatTime(event.endTime, timezone)
                    : EMPTY_TIME}
                </div>
              </div>
              <div>
                <div>
                  {!isNullOrUndefined(event.duration)
                    ? formatHoursMins(event.duration)
                    : ""}
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
}

export default TurnaroundModuleServiceRecordRows;
