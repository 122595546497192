import moment from "moment-timezone";
import {
  formatHoursMins,
  formatTime,
  getNearestTimeInterval,
  isEmptyList,
  isNullOrUndefined,
} from "../../utils";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getMinMaxDate, TaskInfoType } from "../../turnaroundUtils";
import { isDateAfter } from "../../dateUtils";

function TurnaroundModuleServiceTimeline(props) {
  const { t } = useTranslation();
  const { taskInfoList, turnaroundInfo, timezone, taskInfoFilters } = props;
  const [isInitialized, setIsInitialized] = useState(false);
  const [pointMarkerTime, setPointMarkerTime] = useState(false);
  const [pointMarkerLeftOffset, setPointMarkerLeftOffset] = useState(0);
  const [timelineWidth, setTimelineWidth] = useState(0);
  const taskInfoListToRender = [];
  if (!isEmptyList(taskInfoList)) {
    taskInfoList.forEach((i) => {
      if (i.type === TaskInfoType.GSE && taskInfoFilters.showGse) {
        taskInfoListToRender.push(i);
      } else if (i.type === TaskInfoType.CREW && taskInfoFilters.showUsers) {
        taskInfoListToRender.push(i);
      } else if (
        i.type === TaskInfoType.OPERATION &&
        taskInfoFilters.showOperations
      ) {
        taskInfoListToRender.push(i);
      }
    });
  }
  const minMax = getMinMaxDate(taskInfoListToRender, "start", "end");

  // Factor in the expected times so that they can fit on the timeline
  const minMaxExpected = getMinMaxDate(
    taskInfoListToRender,
    "expectedStart",
    "expectedEnd"
  );
  const absMinMax = {
    min: isDateAfter(minMax.min, minMaxExpected.min)
      ? minMaxExpected.min
      : minMax.min,
    max: isDateAfter(minMaxExpected.max, minMax.max)
      ? minMaxExpected.max
      : minMax.max,
  };

  const gateIn = turnaroundInfo.resolvedInboundTimes.displayTime;
  const gateOut = turnaroundInfo.resolvedOutboundTimes.displayTime;

  const lowerTime = isDateAfter(absMinMax.min, gateIn) ? gateIn : absMinMax.min;
  const upperTime = isDateAfter(absMinMax.max, gateOut)
    ? absMinMax.max
    : gateOut;

  const mUpperLabel = getNearestTimeInterval(
    moment(upperTime).tz(timezone),
    -15 / 2
  );
  const mLowerLabel = getNearestTimeInterval(
    moment(lowerTime).tz(timezone),
    15 / 2
  );

  const rangeStart = getNearestTimeInterval(lowerTime, -15);
  const rangeEnd = getNearestTimeInterval(upperTime, 30);
  const rangeStartAdj = moment(rangeStart); //'.add(0, "minute");

  // 15 min increments
  const timeBuckets = [];
  const timeInterval = moment(rangeStart);
  while (timeInterval.isBefore(rangeEnd)) {
    if (timeInterval.isAfter(rangeStart)) {
      timeBuckets.push(moment(timeInterval));
    }
    timeInterval.add(15, "minute");
  }

  const containerRef = useRef(null);
  useEffect(() => {
    if (!isNullOrUndefined(containerRef.current)) {
      setIsInitialized(true);
      const width = containerRef.current.getBoundingClientRect().width;
      setTimelineWidth(width);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (!isNullOrUndefined(containerRef.current)) {
        const width = containerRef.current.getBoundingClientRect().width;
        setTimelineWidth(width);
      }
    };
    setTimeout(() => {
      handleResize();
    }, 100);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [containerRef]);

  const gridColumns = {
    gridTemplateColumns: `repeat(${timeBuckets.length + 1}, minmax(0,1fr))`,
  };
  const gridRows = {
    gridTemplateRows: `repeat(${taskInfoListToRender.length}, minmax(0,1fr))`,
  };

  function getTimelineBarStyle(taskInfo) {
    const mTaskStart = moment(taskInfo.start).tz(timezone);
    const durationFromRangeStart = mTaskStart.diff(rangeStartAdj, "minutes");
    const timelineWidth = getTimeAsPixels(taskInfo.duration);
    const timelineLeft = getTimeAsPixels(durationFromRangeStart);
    if (taskInfo.duration > 0) {
      return {
        width: `${timelineWidth}px`,
        left: `${timelineLeft}px`,
      };
    } else {
      return {
        left: `${timelineLeft}px`,
        transform: "translate(-50%)",
      };
    }
  }

  function getTimelineMarkerStyle(markerTime) {
    if (isNullOrUndefined(markerTime)) return {};
    const mMakerTime = moment(markerTime).tz(timezone);
    const durationFromRangeStart = mMakerTime.diff(rangeStartAdj, "minutes");
    const timelineLeft = getTimeAsPixels(durationFromRangeStart);
    return {
      left: `${timelineLeft}px`,
    };
  }

  // Styles to position the markers
  const gateInMarkerStyle = getTimelineMarkerStyle(gateIn);
  const gateOutMarkerStyle = getTimelineMarkerStyle(gateOut);

  function getTimeAsPixels(minutes) {
    const containerEl = containerRef.current;
    const containerWidth = !isNullOrUndefined(containerEl)
      ? timelineWidth - 2
      : 0;
    const totalWidth = containerWidth;
    const totalDuration = rangeEnd.diff(rangeStartAdj, "minutes");
    return minutes * (totalWidth / totalDuration);
  }

  function getPixelsAsTime(pixels) {
    const containerEl = containerRef.current;
    const containerWidth = !isNullOrUndefined(containerEl)
      ? timelineWidth - 2
      : 0;
    const totalWidth = containerWidth;
    const totalDuration = rangeEnd.diff(rangeStartAdj, "minutes");
    const minutes = pixels * (totalDuration / totalWidth);
    return moment(rangeStartAdj).add(minutes, "minute");
  }

  function handleHoverOver(uuid) {
    const relatedEls = document.querySelectorAll(`[data-task-uuid="${uuid}"]`);
    if (!isEmptyList(relatedEls)) {
      for (const element of relatedEls) {
        element.classList.add("hovered");
      }
    }
  }
  function handleHoverOut(uuid) {
    const relatedEls = document.querySelectorAll(`[data-task-uuid="${uuid}"]`);
    if (!isEmptyList(relatedEls)) {
      for (const element of relatedEls) {
        element.classList.remove("hovered");
      }
    }
  }
  const contentRef = useRef(null);
  const pointMarkerRef = useRef(null);
  const gridlinesRef = useRef(null);
  const markersRef = useRef(null);
  function handleShowPointMarker(e) {
    if (
      !isNullOrUndefined(gridlinesRef.current) &&
      !isNullOrUndefined(e?.pageX) &&
      !isNullOrUndefined(pointMarkerRef.current)
    ) {
      const distanceInPixels =
        e.pageX - gridlinesRef.current.getBoundingClientRect().left;
      const pointerTime = getPixelsAsTime(distanceInPixels);
      setPointMarkerTime(formatTime(pointerTime, timezone));
      setPointMarkerLeftOffset(distanceInPixels);
      pointMarkerRef.current.style.display = "grid";
    }
  }
  function handleHidePointMarker() {
    if (!isNullOrUndefined(pointMarkerRef.current)) {
      pointMarkerRef.current.style.display = "none";
    }
  }

  // Need to correct the vertical position of the label so that it doesn't get covered by header
  function handleContentScroll() {
    const scrollEl = contentRef.current;
    const markersEl = markersRef.current;
    if (isNullOrUndefined(scrollEl) || isNullOrUndefined(markersEl)) return;

    const scrollTop = scrollEl.scrollTop;
    markersEl.style.top = `${scrollTop}px`;
  }
  return (
    <div
      className="turnarounds-module-content"
      onScroll={handleContentScroll}
      ref={contentRef}
    >
      <div className="turnarounds-module-tasks-header">
        <div>
          <div></div>
          <div className="value-cell value-label">{t("expected")}</div>
          <div className="value-cell value-label">{t("actual")}</div>
        </div>
        <div style={gridColumns}>
          {timeBuckets &&
            timeBuckets.map((timeBucket, idx) => {
              const shouldDisplay =
                timeBuckets.length > 60
                  ? idx % (timeBuckets.length / 10) === 0
                  : timeBuckets.length > 40
                  ? idx % 8 === 0
                  : timeBuckets.length > 30
                  ? idx % 6 === 0
                  : timeBuckets.length > 20
                  ? idx % 4 === 0
                  : timeBuckets.length > 10
                  ? idx % 3 === 0
                  : true;

              return (
                <div key={idx}>
                  <div className="value-cell value-label">
                    {shouldDisplay &&
                      timeBucket.isAfter(mLowerLabel) &&
                      timeBucket.isBefore(mUpperLabel) &&
                      formatTime(timeBucket, timezone)}
                  </div>
                </div>
              );
            })}
          <div></div>
        </div>
      </div>
      <div className="turnarounds-module-tasks">
        <div className="turnarounds-module-tasks-sidepanel" style={gridRows}>
          {taskInfoListToRender &&
            taskInfoListToRender.map((taskInfo) => {
              return (
                <div
                  className="turnarounds-module-row"
                  key={taskInfo.keyVal}
                  data-task-uuid={taskInfo.uuid}
                  data-task-parent-uuid={taskInfo.parentUuid}
                  onMouseOver={() => {
                    handleHoverOver(taskInfo.uuid, taskInfo.parentUuid);
                  }}
                  onMouseOut={() => {
                    handleHoverOut(taskInfo.uuid, taskInfo.parentUuid);
                  }}
                >
                  <div className="task-label parent-task">
                    <div>{taskInfo.name}</div>
                    {!isNullOrUndefined(taskInfo.detail) && (
                      <div className="task-detail">{taskInfo.detail}</div>
                    )}
                  </div>
                  <div>
                    {getTimeDisplay(
                      taskInfo.expectedStart,
                      taskInfo.expectedEnd,
                      timezone
                    )}
                  </div>
                  <div>
                    {getTimeDisplay(taskInfo.start, taskInfo.end, timezone)}
                  </div>
                </div>
              );
            })}
        </div>
        <div className="turnarounds-module-tasks-container" ref={containerRef}>
          <div
            className="gridlines"
            style={gridRows}
            onMouseMove={handleShowPointMarker}
            onMouseOut={handleHidePointMarker}
            ref={gridlinesRef}
          >
            {isEmptyList(taskInfoListToRender) && (
              <div className="empty-state de-emphasize">
                {t("not_available")}
              </div>
            )}
            {!isEmptyList(taskInfoListToRender) &&
              taskInfoListToRender.map((taskInfo) => (
                <div
                  className="turnarounds-module-task"
                  data-task-uuid={taskInfo.uuid}
                  data-task-parent-uuid={taskInfo.parentUuid}
                  onMouseOver={() => {
                    handleHoverOver(taskInfo.uuid, taskInfo.parentUuid);
                  }}
                  onMouseOut={() => {
                    handleHoverOut(taskInfo.uuid, taskInfo.parentUuid);
                  }}
                  key={taskInfo.keyVal}
                  style={gridColumns}
                >
                  {timeBuckets &&
                    timeBuckets.map((timeBucket, idx) => (
                      <div
                        data-bucket={formatTime(timeBucket, timezone)}
                        key={idx}
                      ></div>
                    ))}
                  <div></div>
                </div>
              ))}
          </div>
          {isInitialized && (
            <div
              className="timelinebars"
              style={gridRows}
              onMouseMove={handleShowPointMarker}
              onMouseOut={handleHidePointMarker}
            >
              {taskInfoListToRender &&
                taskInfoListToRender.map((taskInfo) => {
                  const hasDuration = taskInfo.duration >= 0;
                  const timelineBarStyle = getTimelineBarStyle(taskInfo);

                  const hasExpectedDuration = taskInfo.expectedDuration >= 0;
                  const expectedTimelineBarStyle = getTimelineBarStyle({
                    start: taskInfo.expectedStart,
                    duration: taskInfo.expectedDuration,
                  });
                  return (
                    <div key={taskInfo.keyVal}>
                      {hasExpectedDuration && (
                        <TimelineBar
                          isExpectedTime={true}
                          taskInfo={{
                            start: taskInfo.expectedStart,
                            end: taskInfo.expectedEnd,
                            duration: taskInfo.expectedDuration,
                          }}
                          onMouseOver={() => {
                            handleHoverOver(taskInfo.uuid, taskInfo.parentUuid);
                          }}
                          onMouseOut={() => {
                            handleHoverOut(taskInfo.uuid, taskInfo.parentUuid);
                          }}
                          timelineBarStyle={expectedTimelineBarStyle}
                        />
                      )}
                      {hasDuration && (
                        <>
                          {isEmptyList(taskInfo.eventPairs) && (
                            <TimelineBar
                              taskInfo={taskInfo}
                              onMouseOver={() => {
                                handleHoverOver(
                                  taskInfo.uuid,
                                  taskInfo.parentUuid
                                );
                              }}
                              onMouseOut={() => {
                                handleHoverOut(
                                  taskInfo.uuid,
                                  taskInfo.parentUuid
                                );
                              }}
                              timelineBarStyle={timelineBarStyle}
                            />
                          )}

                          {!isEmptyList(taskInfo.eventPairs) &&
                            taskInfo.eventPairs.map(
                              (eventPair, eventPairIdx) => {
                                const eventPairUuid = `${taskInfo.uuid}_${eventPairIdx}`;
                                const eventPairStyle = getTimelineBarStyle({
                                  start: eventPair.startTime,
                                  end: eventPair.endTime,
                                  duration: eventPair.duration,
                                });
                                return (
                                  <TimelineBar
                                    key={`${eventPairUuid}`}
                                    taskInfo={{
                                      uuid: eventPairUuid,
                                      duration: eventPair.duration,
                                      type: taskInfo.type,
                                    }}
                                    onMouseOver={() => {
                                      handleHoverOver(eventPairUuid);
                                    }}
                                    onMouseOut={() => {
                                      handleHoverOut(eventPairUuid);
                                    }}
                                    timelineBarStyle={eventPairStyle}
                                  />
                                );
                              }
                            )}
                        </>
                      )}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
      <div className="turnarounds-module-markers" ref={markersRef}>
        {!isEmptyList(taskInfoListToRender) && (
          <>
            <div className="timeline-marker" style={gateInMarkerStyle}>
              <div>
                <div className="timeline-marker-label-anchor">
                  <div className="timeline-marker-label">
                    <div>
                      <div className="timeline-marker-label-title">
                        {t("arrival")}
                      </div>
                      <div className="timeline-marker-label-value">
                        {formatTime(gateIn, timezone)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="timeline-marker-line"></div>
              </div>
            </div>
            <div className="timeline-marker" style={gateOutMarkerStyle}>
              <div>
                <div className="timeline-marker-label-anchor">
                  <div className="timeline-marker-label">
                    <div>
                      <div className="timeline-marker-label-title">
                        {t("departure")}
                      </div>
                      <div className="timeline-marker-label-value">
                        {formatTime(gateOut, timezone)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="timeline-marker-line"></div>
              </div>
            </div>
            <div
              className="timeline-marker point-marker"
              ref={pointMarkerRef}
              style={{
                left: `${pointMarkerLeftOffset}px`,
                display: "none",
              }}
            >
              <div>
                <div className="timeline-marker-label-anchor">
                  <div className="timeline-marker-label">
                    <div className="timeline-marker-label-value">
                      <div>{pointMarkerTime}</div>
                    </div>
                  </div>
                </div>
                <div className="timeline-marker-line"></div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function getTimeDisplay(start, end, timezone) {
  const timeDisplay =
    !isNullOrUndefined(start) && !isNullOrUndefined(end)
      ? `${formatTime(start, timezone)} - ${formatTime(end, timezone)}`
      : !isNullOrUndefined(start)
      ? formatTime(start, timezone)
      : "";

  return timeDisplay;
}

function TimelineBar(props) {
  const {
    isExpectedTime,
    taskInfo,
    timelineBarStyle,
    onMouseOver,
    onMouseOut,
  } = props;
  return (
    <div
      className={`turnarounds-module-timelinebar${
        taskInfo.duration === 0 ? " point" : ""
      }${!isNullOrUndefined(isExpectedTime) ? " expected" : ""}${
        !isNullOrUndefined(taskInfo.type)
          ? ` ${taskInfo.type.toLowerCase()}`
          : ""
      }`}
      style={timelineBarStyle}
      data-task-uuid={taskInfo.uuid}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <div>
        <div className="turnarounds-module-timelinebar-label">
          {formatHoursMins(taskInfo.duration)}
        </div>
      </div>
    </div>
  );
}
export default TurnaroundModuleServiceTimeline;
