import { getByFieldValue, isEmptyList, isNullOrUndefined } from "../../utils";
import { t } from "i18next";
import CrewInfo from "../CrewInfo";
import { getUserInfo } from "../../userUtils";

function TurnaroundModuleCrewSlot(props) {
  const {
    crewAssignment,
    users,
    positions,
    turnarounds,
    onRemove,
    isProfileEditable,
    isDisabled,
  } = props;
  const user =
    !isNullOrUndefined(crewAssignment?.userUuid) && !isEmptyList(users)
      ? getByFieldValue(users, "uuid", crewAssignment?.userUuid)
      : null;
  const userInfo = !isNullOrUndefined(user)
    ? getUserInfo(user, turnarounds, positions)
    : null;

  return (
    <div
      className={`turnaround-module-slot${
        isNullOrUndefined(userInfo) ? " empty" : ""
      }`}
    >
      <div>
        {!isNullOrUndefined(userInfo) && (
          <>
            <div>
              <CrewInfo user={userInfo.user} mapStatus={userInfo.mapStatus} />
            </div>
            <div className="actions">
              <div></div>
              <div>
                {isProfileEditable && (
                  <button
                    className="secondary"
                    onClick={() => {
                      onRemove(userInfo.uuid);
                    }}
                    disabled={isDisabled}
                  >
                    {t("remove")}
                  </button>
                )}
              </div>
            </div>
          </>
        )}
        {isNullOrUndefined(userInfo) && (
          <>
            <div className="unassigned">
              <CrewInfo
                user={{
                  firstName: t("allocated"),
                  lastName: "",
                }}
                hideMapStatus={true}
                details={
                  <div className="timestamps de-emphasize">
                    <div>{t("crew_member_not_assigned")}</div>
                  </div>
                }
              />
            </div>
            <div className="actions">
              <div></div>
              <div>
                {isProfileEditable && (
                  <button
                    className="secondary"
                    onClick={() => {
                      onRemove();
                    }}
                    disabled={isDisabled}
                  >
                    {t("remove")}
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default TurnaroundModuleCrewSlot;
