import { useEffect, useRef, useState } from "react";
import PlaybackButtons from "./PlaybackButtons";
import PlaybackTimeline from "./PlaybackTimeline";
import { formatTimeSeconds, isNullOrUndefined } from "../../utils";
import moment from "moment-timezone";
import { usePlaybackDispatchContext } from "../../PlaybackContext";
import { selectInputNoCaretFix } from "../../styleUtils";

function PlaybackControls(props) {
  const { playbackTimeRange, onTimeValueClick, timezone } = props;
  const [currentTime, setCurrentTime] = useState(playbackTimeRange.startTime);
  const [playState, setPlayState] = useState(false);
  const [playbackRateState, setPlaybackRateState] = useState("1");
  const playbackDispatch = usePlaybackDispatchContext();

  const startTimeRef = useRef(null);
  const requestRef = useRef(null);

  // These need to be kept in-sync with state
  const isPlaying = useRef(false);
  const playbackRate = useRef("1");
  const timeRangeRef = useRef(playbackTimeRange);

  useEffect(() => {
    const updatedTime = moment(currentTime).tz(timezone).startOf("second");
    playbackDispatch({
      type: "setCurrentPlaybackTime",
      value: updatedTime.toDate().toISOString(),
    });
  }, [playbackDispatch, currentTime, timezone]);

  useEffect(() => {
    setCurrentTime(playbackTimeRange.startTime);
    timeRangeRef.current = playbackTimeRange;
  }, [playbackTimeRange]);

  const play = (timestamp) => {
    if (isNullOrUndefined(startTimeRef.current)) {
      startTimeRef.current = timestamp;
    }
    const progress = timestamp - startTimeRef.current;

    startTimeRef.current = timestamp;
    if (isPlaying.current) {
      setCurrentTime((prev) => {
        const secsToAdd =
          progress / ((1 / parseInt(playbackRate.current)) * 1000);
        const nextTime = moment(prev).tz(timezone).add(secsToAdd, "second");

        if (
          nextTime.isAfter(moment(timeRangeRef.current.endTime).tz(timezone))
        ) {
          // Exit and stop playing
          isPlaying.current = false;
          setPlayState(false);
        }
        const nextVal = nextTime.toDate().toISOString();
        return nextVal;
      });
    }
    requestRef.current = requestAnimationFrame(play);
  };

  function handleStep(seconds) {
    setCurrentTime((prev) => {
      const nextTime = moment(prev).startOf("second").add(seconds, "second");
      return nextTime.toDate().toISOString();
    });
  }

  function handleReset() {
    setCurrentTime(playbackTimeRange.startTime);
  }

  useEffect(() => {
    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  function handlePlay() {
    if (isPlaying.current) {
      isPlaying.current = false;
    } else {
      isPlaying.current = true;
      if (!isNullOrUndefined(requestRef.current)) {
        requestRef.current = null;
      }
      requestRef.current = requestAnimationFrame(play);
    }
    setPlayState(isPlaying.current);
  }

  return (
    <div className="playback-tool-controls drop-shadow">
      <div>
        <div className="playback-tool-controls-content">
          <div>
            <PlaybackButtons
              currentTime={currentTime}
              playbackTimeRange={playbackTimeRange}
              timezone={timezone}
              isPlaying={playState}
              onPlay={() => {
                handlePlay();
              }}
              onBack={() => {
                handleStep(-1);
              }}
              onForward={() => {
                handleStep(1);
              }}
              onReset={() => {
                handleReset();
              }}
            />
          </div>
          <div>
            <div className="playback-rate">
              <select
                value={playbackRateState}
                onChange={(e) => {
                  const rateUpdated = e.target.value;
                  setPlaybackRateState(rateUpdated);
                  playbackRate.current = rateUpdated;
                }}
                style={selectInputNoCaretFix}
              >
                <option value="1">1x</option>
                <option value="2">2x</option>
                <option value="4">4x</option>
                <option value="8">8x</option>
              </select>
            </div>
          </div>
          <div>
            <div className="playback-tool-timeline">
              <PlaybackTimeline
                startTime={playbackTimeRange.startTime}
                endTime={playbackTimeRange.endTime}
                currentTime={currentTime}
                timezone={timezone}
                onSlide={(minuteDelta) => {
                  setCurrentTime((prev) => {
                    let nextTime = moment(prev)
                      .tz(timezone)
                      .add(minuteDelta, "minute");
                    if (
                      minuteDelta < 0 &&
                      nextTime.isBefore(
                        moment(playbackTimeRange.startTime).tz(timezone)
                      )
                    ) {
                      nextTime = moment(playbackTimeRange.startTime).tz(
                        timezone
                      ); // Hard stop at startTime
                    } else if (
                      minuteDelta > 0 &&
                      nextTime.isAfter(
                        moment(playbackTimeRange.endTime).tz(timezone)
                      )
                    ) {
                      nextTime = moment(playbackTimeRange.endTime).tz(timezone); // Hard stop at endTime
                    }
                    return nextTime.toDate().toISOString();
                  });
                }}
              />
            </div>
          </div>
          <div>
            <div
              className="playback-tool-display-time"
              onClick={() => {
                onTimeValueClick();
                isPlaying.current = false;
              }}
            >
              {formatTimeSeconds(currentTime, timezone)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlaybackControls;
