import { Modal } from "@mui/material";
import {
  deepCopy,
  getByFieldValue,
  isBlank,
  isEmptyList,
  isNullOrUndefined,
  logAnalyticsEvent,
  sortByField,
} from "../../utils";

import { ReactComponent as Cross } from "../../assets/cross.svg";
import { ReactComponent as CriticalIcon } from "../../assets/critical.svg";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import SearchSelector from "../SearchSelector";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { getAircraftTypes, getAirlines } from "../../flightsApi";
import { ANALYTICS_EVENTS } from "../../constants";
import { createTemplate } from "../../api";

function TemplateCreateModal(props) {
  const { isOpen, onClose, onSave, allTemplates } = props;
  const { t } = useTranslation();
  const mainContext = useMainContext();
  const dispatch = useMainDispatchContext();
  const { airlines, aircraftTypes } = mainContext;

  const [templateToCreate, setTemplateToCreate] = useState({
    aircraftTypeName: "",
    airlineUuid: "",
  });

  useEffect(() => {
    getAirlines(dispatch);
    getAircraftTypes(dispatch);
  }, [dispatch]);

  const [isProcessing, setIsProcessing] = useState(false);

  function resetAll() {
    setTemplateToCreate({
      aircraftTypeName: null,
      airlineUuid: null,
    });
  }

  function handleClose() {
    resetAll();
    onClose();
  }

  async function handleSave() {
    setIsProcessing(true);
    // Create the save request
    let createRequest = {
      aircraftTypeName: templateToCreate.aircraftTypeName,
      airlineUuid: templateToCreate.airlineUuid,
    };

    const result = await createTemplate(dispatch, createRequest);
    if (result) {
      const airline = getByFieldValue(
        airlines,
        "uuid",
        templateToCreate.airlineUuid
      );
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("saved_web", {
          name: `${templateToCreate.aircraftTypeName} ${airline.name}`,
        }),
      });

      if (!isNullOrUndefined(onSave)) {
        onSave();
      }
    }
    logAnalyticsEvent(
      dispatch,
      ANALYTICS_EVENTS.TURNAROUND_TEMPLATE_CREATE_SAVE
    );
    setIsProcessing(false);
    resetAll();
    onSave();
  }

  function isTemplateConfigValid(templateToCheck) {
    if (
      !isEmptyList(allTemplates) &&
      !isBlank(templateToCheck?.aircraftTypeName) &&
      !isBlank(templateToCheck?.airlineUuid)
    ) {
      // Check for existing
      for (let i = 0; i < allTemplates.length; i++) {
        const template = allTemplates[i];
        if (
          template.aircraftType.name === templateToCheck.aircraftTypeName &&
          template.airline.uuid === templateToCheck.airlineUuid
        ) {
          return false;
        }
      }
    }
    return true;
  }

  const isValid =
    !isNullOrUndefined(templateToCreate) &&
    !isBlank(templateToCreate.aircraftTypeName) &&
    !isBlank(templateToCreate.airlineUuid);

  const isConfigValid = isTemplateConfigValid(templateToCreate);

  if (!isEmptyList(airlines)) {
    sortByField(airlines, "name");
  }

  if (!isEmptyList(aircraftTypes)) {
    sortByField(aircraftTypes, "name");
  }

  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <div className="modal template-create-modal modal-medium">
          <div>
            <div className="modal-header">
              <div>
                <h2>{t("create_template")}</h2>
                {!isConfigValid && (
                  <div className="messages">
                    <div>
                      <CriticalIcon />
                    </div>
                    <div>{t("template_configuration_exists_msg")}</div>
                  </div>
                )}
              </div>
              <div className="button-icon" onClick={handleClose}>
                <Cross />
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-container">
              <div className="template-create-modal-rows">
                <div className="template-create-modal-row">
                  <div>
                    <label>{t("airline")}</label>
                  </div>
                  <div>
                    <SearchSelector
                      onSelect={(value) => {
                        const templateToCreateUpdated =
                          deepCopy(templateToCreate);
                        templateToCreateUpdated.airlineUuid = value.uuid;
                        setTemplateToCreate(templateToCreateUpdated);
                      }}
                      items={airlines}
                      searchableName="name"
                      selectedFieldName="uuid"
                      value={templateToCreate.airlineUuid}
                      allowClear={false}
                    />
                  </div>
                </div>
                <div className="template-create-modal-row">
                  <div>
                    <label>{t("aircraft_type_non_cap")}</label>
                  </div>
                  <div>
                    <SearchSelector
                      onSelect={(value) => {
                        const templateToCreateUpdated =
                          deepCopy(templateToCreate);
                        templateToCreateUpdated.aircraftTypeName = value.name;
                        setTemplateToCreate(templateToCreateUpdated);
                      }}
                      items={aircraftTypes}
                      searchableName="name"
                      selectedFieldName="name"
                      value={templateToCreate.aircraftTypeName}
                      allowClear={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="modal-footer">
              <div>
                <button className="secondary" onClick={handleClose}>
                  {t("cancel")}
                </button>
              </div>
              <div>
                <button
                  onClick={handleSave}
                  disabled={isProcessing || !isValid || !isConfigValid}
                >
                  {t("save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default TemplateCreateModal;
