import { isBlank, isNullOrUndefined } from "../../utils";
import { ReactComponent as Cross } from "../../assets/cross.svg";
import { useState, useRef, useEffect } from "react";
import { ReactComponent as CloseBtn } from "../../assets/close-btn.svg";
import { useTranslation } from "react-i18next";

function GateChangePanel(props) {
  const {
    isOpen,
    onCloseHandler,
    selectedTurnaround,
    gates,
    onSaveHandler,
    gateChangeSaving,
  } = props;
  const { t } = useTranslation();
  const [searchInputMode, setSearchInputMode] = useState("search");
  const [searchText, setSearchText] = useState("");
  const [gateToChange, setGateToChange] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const searchInputRef = useRef(null);

  const resetModal = () => {
    setGateToChange(null);
    setSearchText("");
    setShowOptions(false);
  };

  useEffect(() => {
    if (!isOpen) {
      resetModal();
    }
  }, [isOpen]);

  if (isNullOrUndefined(selectedTurnaround)) return null;
  const options = [];
  if (!isNullOrUndefined(gates)) {
    for (let i = 0; i < gates.length; i++) {
      const gate = gates[i];
      if (gate.uuid !== selectedTurnaround.standId) {
        if (!isBlank(searchText)) {
          const searchIdx = gate.name
            .toLowerCase()
            .indexOf(searchText.toLowerCase());
          if (searchIdx === 0) {
            options.unshift(gate);
          } else if (searchIdx > 0) {
            options.push(gate);
          }
        } else {
          options.push(gate);
        }
      }
    }
  }
  const handleClose = () => {
    if (!isNullOrUndefined(onCloseHandler)) {
      onCloseHandler();
    }
    resetModal();
  };

  const isValid = !isNullOrUndefined(gateToChange);
  return (
    <>
      <div className="gate-change-panel">
        <div className="panel-module">
          <div className="panel-module-header">
            <div className="panel-module-header-box">
              <div></div>
              <div className="close-btn" onClick={handleClose}>
                <CloseBtn />
              </div>
            </div>
          </div>
          <div className="panel-module-body">
            <div className="panel-module-body-box">
              <div className="panel-field-row">
                <label>{t("current_stand")}</label>
                <h3>{selectedTurnaround?.snappedStand?.name}</h3>
              </div>
              <div className="panel-field-row">
                <label>{t("new_stand")}</label>
                <div
                  className={`search-field ${
                    isBlank(gateToChange?.name) ? "search" : searchInputMode
                  }`}
                >
                  <div className="search-field-normal">
                    <h3
                      onClick={() => {
                        setSearchText("");
                        setSearchInputMode("search");
                        searchInputRef.current.focus();
                      }}
                      className="new-stand"
                    >
                      {gateToChange?.name}
                    </h3>
                  </div>
                  <div className="search-field-search">
                    <div
                      className={`search-input-box ${
                        showOptions && options?.length > 0 ? "show-options" : ""
                      }`}
                    >
                      <input
                        ref={searchInputRef}
                        type="text"
                        value={searchText}
                        placeholder={t("start_typing")}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                        onFocus={() => {
                          setShowOptions(true);
                        }}
                      />
                      <Cross
                        onClick={() => {
                          setSearchText("");
                          setGateToChange(null);
                          setShowOptions(false);
                          searchInputRef.current.blur();
                        }}
                      />
                    </div>
                    {showOptions && options?.length > 0 && (
                      <div className="gate-options">
                        {options &&
                          options.map((item) => (
                            <div
                              key={`gate-${item.uuid}`}
                              alt={item.uuid}
                              className="gate-option"
                              onClick={() => {
                                setGateToChange(item);
                                setSearchInputMode("normal");
                              }}
                            >
                              <div>{item.name}</div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="panel-module-actions">
                <button
                  className="primary"
                  onClick={() => {
                    if (!isNullOrUndefined(onSaveHandler)) {
                      onSaveHandler(gateToChange);
                    }
                  }}
                  disabled={gateChangeSaving || !isValid}
                >
                  {t("confirm_change")}
                </button>
                <button className="alternate" onClick={handleClose}>
                  {t("cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GateChangePanel;
