import { getByFieldValue, isNullOrUndefined } from "../../utils";

// Returns positions for anything at the stand for given aircraft registration
export function getResourcesAtStand(registration, positions) {
  const resourcesAtStand = {
    aircrafts: [],
    groundVehicles: [],
    users: [],
  };
  const aircraftPosition = getByFieldValue(
    positions?.aircrafts,
    "registration",
    registration
  );
  if (
    !isNullOrUndefined(aircraftPosition?.currentStandName) &&
    !isNullOrUndefined(positions)
  ) {
    const keys = Object.keys(positions);
    keys.forEach((key) => {
      if (key in resourcesAtStand) {
        const positionItems = positions[key];
        positionItems.forEach((i) => {
          if (i.currentStandName === aircraftPosition?.currentStandName) {
            resourcesAtStand[key].push(i);
          }
        });
      }
    });
  }
  return resourcesAtStand;
}
