import "./styles.css";
import { useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import {
  getByFieldValue,
  isBlank,
  isEmptyList,
  isNullOrUndefined,
} from "../../utils";
import { DEFAULT_TIMEZONE } from "../../constants";
import {
  getAircraftInfoForMap,
  getVehicleInfoForMap,
  MAP_ZOOM_LEVELS,
  MAPBOX_STANDARD_STYLE,
  MAPINFO_MARKER_TYPE,
} from "../../mapUtils";
import moment from "moment-timezone";
import PlaybackControls from "./PlaybackControls";
import { debugLog } from "../../logging";
import PlaybackTimeSelectorModal from "./PlaybackTimeSelectorModal";
import {
  usePlaybackContext,
  usePlaybackDispatchContext,
} from "../../PlaybackContext";
import { getPlaybackData } from "../../playbackApi";
import { getAircrafts, getUsers, getVehicles } from "../../api";
import { addMapMarker, updateMapMarker } from "./playbackUtils";
import { useSearchParams } from "react-router-dom";
import LoadingIndicator from "../LoadingIndicator";

function PlaybackTool(props) {
  const mainContext = useMainContext();
  const dispatch = useMainDispatchContext();
  const playbackContext = usePlaybackContext();
  const playbackDispatch = usePlaybackDispatchContext();
  const { currentUser, dynamicEnv, users, aircrafts, vehicles } = mainContext;
  const {
    globalError,
    globalErrorList,
    playbackData,
    playbackDataLoading,
    currentPlaybackTime,
  } = playbackContext;
  const airport = currentUser?.airport;
  const airportTimezone = !isNullOrUndefined(airport)
    ? airport.timezone
    : DEFAULT_TIMEZONE;

  const [isTimeSelectorOpen, setIsTimeSelectorOpen] = useState(
    isNullOrUndefined(false)
  );
  const [isMapboxReady, setIsMapboxReady] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [playbackTimeRange, setPlaybackTimeRange] = useState(null);
  const [playbackDataForRender, setPlaybackDataForRender] = useState(null);

  // Try using the startTime from the url
  const [searchParams, setSearchParams] = useSearchParams();
  const startTime = searchParams.get("startTime");
  useEffect(() => {
    if (!isNullOrUndefined(airport?.timezone)) {
      if (!isBlank(startTime)) {
        try {
          const mStartTime = moment(startTime)
            .tz(airport.timezone)
            .startOf("minute");
          setPlaybackTimeRange({
            startTime: !isNullOrUndefined(mStartTime)
              ? mStartTime.toDate().toISOString()
              : null,
            endTime: !isNullOrUndefined(mStartTime)
              ? moment(mStartTime).add(1, "minute").toDate().toISOString()
              : null,
          });
        } catch (e) {
          debugLog(`Invlaid startTime: ${e}`);
        }
      } else {
        // Prompt for playbackTimeRange
        setIsTimeSelectorOpen(true);
      }
    }
  }, [airport, startTime]);

  const mapRef = useRef(null);
  const mapContainer = useRef(null);
  const markers = useRef({});
  const mNow = moment().tz(airportTimezone);

  // Load supporting data
  useEffect(() => {
    getUsers(dispatch);
    getAircrafts(dispatch);
    getVehicles(dispatch);
  }, [dispatch]);

  // Load position data
  useEffect(() => {
    if (
      !isNullOrUndefined(currentUser) &&
      !isNullOrUndefined(playbackTimeRange?.startTime) &&
      !isNullOrUndefined(playbackTimeRange?.endTime)
    ) {
      getPlaybackData(playbackDispatch, playbackTimeRange);
    }
  }, [playbackDispatch, playbackTimeRange, currentUser]);

  useEffect(() => {
    if (!isNullOrUndefined(dynamicEnv?.mapBoxToken)) {
      mapboxgl.accessToken = dynamicEnv.mapBoxToken;
      setIsMapboxReady(true);
    }
  }, [dynamicEnv]);

  useEffect(() => {
    if (!isMapboxReady) return () => {};
    if (isNullOrUndefined(airport)) return () => {};
    if (isNullOrUndefined(mapRef.current)) {
      // initialize map only once
      const airportLat = !isNullOrUndefined(airport)
        ? (airport.northLatitude + airport.southLatitude) / 2
        : null;
      const airportLng = !isNullOrUndefined(airport)
        ? (airport.westLongitude + airport.eastLongitude) / 2
        : null;
      const airportCenter = [airportLng, airportLat];

      const mapStyle = MAPBOX_STANDARD_STYLE;

      mapRef.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: mapStyle,
        center: airportCenter,
        zoom: MAP_ZOOM_LEVELS.AIRPORT,
        trackResize: true,
      });

      const mapBox = mapRef.current;

      mapBox.on("style.load", () => {
        try {
          // We should be able to call getConfig, but seems like it's not supported???
          mapBox.setConfigProperty(
            "basemap",
            "showPointOfInterestLabels",
            false
          );
          mapBox.setConfigProperty("basemap", "showTransitLabels", false);
          mapBox.setConfigProperty("basemap", "showPlaceLabels", false);
          mapBox.setConfigProperty(
            "basemap",
            "showPointOfInterestLabels",
            false
          );
          mapBox.setConfigProperty("basemap", "show3dObjects", true);
          mapBox.setConfigProperty("basemap", "showPedestrianRoads", true);
          mapBox.setConfigProperty("basemap", "theme", "default");
          mapBox.setConfigProperty("basemap", "lightPreset", "dusk");

          setIsReady(true);
        } catch (ignored) {
          debugLog(`Map initialization: ${ignored}`);
        }
      });
    }
  }, [airport, isMapboxReady]);

  // This is simply proxy for the setGlobalError on the main dispatch
  useEffect(() => {
    if (!isBlank(globalError)) {
      dispatch({
        type: "setGlobalError",
        globalError: globalError,
        globalErrorList: globalErrorList,
      });
    }
  }, [playbackDispatch, dispatch, globalError, globalErrorList]);

  // Process playback data for rendering positions
  useEffect(() => {
    if (isNullOrUndefined(playbackData)) {
      return () => {};
    }
    const dataForRender = {
      aircrafts: {},
      groundVehicles: {},
      users: {},
    };
    if (!isEmptyList(playbackData?.groundVehicles)) {
      for (let i = 0; i < playbackData.groundVehicles.length; i++) {
        const item = playbackData.groundVehicles[i];
        if (!(item.code in dataForRender.groundVehicles)) {
          const vehicle = getByFieldValue(vehicles, "trackerCode", item.code);
          if (!isNullOrUndefined(vehicle)) {
            // New entry
            dataForRender.groundVehicles[vehicle.trackerCode] = {
              vehicle: vehicle,
              keyPositions: {},
              initialPositionTimestamp: null,
            };
          }
        }
        const entry = dataForRender.groundVehicles[item.code];
        if (!isNullOrUndefined(entry)) {
          const mTimestamp = moment(item.timestamp * 1000).tz(airportTimezone);
          const key = mTimestamp.toDate().toISOString();
          if (
            isNullOrUndefined(entry.initialPositionTimestamp) ||
            mTimestamp.isBefore(moment(entry.initialPositionTimestamp))
          ) {
            entry.initialPositionTimestamp = key;
          }
          entry.keyPositions[key] = item;
        }
      }
    }
    if (!isEmptyList(playbackData?.aircrafts)) {
      for (let i = 0; i < playbackData.aircrafts.length; i++) {
        const item = playbackData.aircrafts[i];
        if (!(item.registration in dataForRender.aircrafts)) {
          const aircraft = getByFieldValue(
            aircrafts,
            "registration",
            item.registration
          );
          if (!isNullOrUndefined(aircraft)) {
            // New entry
            dataForRender.aircrafts[aircraft.registration] = {
              aircraft: aircraft,
              keyPositions: {},
              initialPositionTimestamp: null,
            };
          }
        }
        const entry = dataForRender.aircrafts[item.registration];
        if (!isNullOrUndefined(entry)) {
          const mTimestamp = moment(item.timestamp * 1000).tz(airportTimezone);
          const key = mTimestamp.toDate().toISOString();
          if (
            isNullOrUndefined(entry.initialPositionTimestamp) ||
            mTimestamp.isBefore(moment(entry.initialPositionTimestamp))
          ) {
            entry.initialPositionTimestamp = key;
          }
          entry.keyPositions[key] = item;
        }
      }
    }
    setPlaybackDataForRender(dataForRender);
  }, [playbackData, users, aircrafts, vehicles, airportTimezone]);

  // Handle initializing the positions
  useEffect(() => {
    if (isNullOrUndefined(playbackDataForRender)) {
      return () => {};
    }
    if (!isNullOrUndefined(playbackDataForRender.groundVehicles)) {
      // Initialize the markers for GSE
      const keys = Object.keys(playbackDataForRender.groundVehicles);
      if (!isEmptyList(keys)) {
        keys.forEach((key) => {
          const entry = playbackDataForRender.groundVehicles[key];
          const initialPosition =
            entry.keyPositions[entry.initialPositionTimestamp];

          const vehicleInfo = getVehicleInfoForMap(
            entry.vehicle,
            initialPosition
          );
          const existingMarker = markers.current[vehicleInfo.uuid];
          const markerInfo = {
            type: MAPINFO_MARKER_TYPE.VEHICLE,
            markerInfo: vehicleInfo,
            existingMarker: existingMarker,
          };
          if (!isNullOrUndefined(existingMarker)) {
            updateMapMarker(markerInfo);
          } else {
            const marker = addMapMarker(mapboxgl, mapRef, markerInfo);
            markers.current[vehicleInfo.uuid] = marker;
          }
        });
      }
    }
    if (!isNullOrUndefined(playbackDataForRender.aircrafts)) {
      // Initialize the markers for aircrafts
      const keys = Object.keys(playbackDataForRender.aircrafts);
      if (!isEmptyList(keys)) {
        keys.forEach((key) => {
          const entry = playbackDataForRender.aircrafts[key];
          const initialPosition =
            entry.keyPositions[entry.initialPositionTimestamp];

          const aircraftInfo = getAircraftInfoForMap(
            entry.aircraft,
            initialPosition
          );
          const existingMarker = markers.current[aircraftInfo.registration];
          const markerInfo = {
            type: MAPINFO_MARKER_TYPE.AIRCRAFT,
            markerInfo: aircraftInfo,
            existingMarker: existingMarker,
          };
          if (!isNullOrUndefined(existingMarker)) {
            updateMapMarker(markerInfo);
          } else {
            const marker = addMapMarker(mapboxgl, mapRef, markerInfo);
            markers.current[aircraftInfo.registration] = marker;
          }
        });
      }
    }
  }, [playbackDataForRender]);

  // Handle updating the positions based on the new playback time
  useEffect(() => {
    if (!isNullOrUndefined(playbackDataForRender?.groundVehicles)) {
      // Update the markers
      const keys = Object.keys(playbackDataForRender.groundVehicles);
      if (!isEmptyList(keys)) {
        keys.forEach((key) => {
          const entry = playbackDataForRender.groundVehicles[key];
          const keyPosition = entry.keyPositions[currentPlaybackTime];
          if (!isNullOrUndefined(keyPosition)) {
            const vehicleInfo = getVehicleInfoForMap(
              entry.vehicle,
              keyPosition
            );
            const existingMarker = markers.current[vehicleInfo.uuid];
            if (!isNullOrUndefined(existingMarker)) {
              const markerInfo = {
                type: MAPINFO_MARKER_TYPE.VEHICLE,
                markerInfo: vehicleInfo,
                existingMarker: existingMarker,
              };

              updateMapMarker(markerInfo);
            }
          }
        });
      }
    }
    if (!isNullOrUndefined(playbackDataForRender?.aircrafts)) {
      // Update the markers
      const keys = Object.keys(playbackDataForRender.aircrafts);
      if (!isEmptyList(keys)) {
        keys.forEach((key) => {
          const entry = playbackDataForRender.aircrafts[key];
          const keyPosition = entry.keyPositions[currentPlaybackTime];
          if (!isNullOrUndefined(keyPosition)) {
            const aircraftInfo = getAircraftInfoForMap(
              entry.aircraft,
              keyPosition
            );
            const existingMarker = markers.current[aircraftInfo.registration];
            if (!isNullOrUndefined(existingMarker)) {
              const markerInfo = {
                type: MAPINFO_MARKER_TYPE.AIRCRAFT,
                markerInfo: aircraftInfo,
                existingMarker: existingMarker,
              };
              updateMapMarker(markerInfo);
            }
          }
        });
      }
    }
  }, [currentPlaybackTime, playbackDataForRender]);

  return (
    <div className={`playback-tool${playbackDataLoading ? " loading" : ""}`}>
      <div className="playback-tool-content">
        {playbackDataLoading && (
          <div className="loading-state">
            <LoadingIndicator />
          </div>
        )}
        <div className="playback-tool-map" ref={mapContainer}></div>
      </div>

      {isReady && !isNullOrUndefined(playbackTimeRange) && (
        <PlaybackControls
          playbackTimeRange={playbackTimeRange}
          onTimeValueClick={() => {
            setIsTimeSelectorOpen(true);
          }}
          timezone={airportTimezone}
        />
      )}
      {isTimeSelectorOpen && (
        <PlaybackTimeSelectorModal
          isOpen={isTimeSelectorOpen}
          onClose={() => {
            setIsTimeSelectorOpen(false);
          }}
          onChange={(startTime) => {
            const updatedStartTime = moment(startTime)
              .tz(airportTimezone)
              .startOf("minute")
              .toDate()
              .toISOString();
            const updatedEndTime = moment(updatedStartTime)
              .tz(airportTimezone)
              .startOf("minute")
              .add(1, "minute")
              .toDate()
              .toISOString();

            // Reset the playback
            setPlaybackDataForRender(null);

            setPlaybackTimeRange({
              startTime: updatedStartTime,
              endTime: updatedEndTime,
            });
            setIsTimeSelectorOpen(false);
            setSearchParams((prev) => {
              return {
                ...prev,
                startTime: moment(startTime)
                  .tz(airportTimezone)
                  .startOf("minute")
                  .toDate()
                  .toISOString(),
              };
            });
          }}
          startTime={
            !isNullOrUndefined(playbackTimeRange?.startTime)
              ? playbackTimeRange?.startTime
              : mNow.toDate().toISOString()
          }
          timezone={airportTimezone}
          allowClose={!isNullOrUndefined(playbackTimeRange)}
        />
      )}
    </div>
  );
}

export default PlaybackTool;
