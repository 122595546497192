import { useMainContext, useMainDispatchContext } from "../../MainContext";
import {
  archiveTemplate,
  getTurnaroundTemplate,
  getTurnaroundTemplates,
  patchTurnaroundTemplate,
} from "../../api";
import {
  ResourceActions,
  userHasAnyResourceAction,
} from "../../resourceActionsUtils";
import {
  deepCopy,
  isNullOrUndefined,
  logAnalyticsEvent,
  sortByField,
} from "../../utils";
import TemplateEditor from "./TemplateEditor";
import "./styles.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Plus } from "../../assets/plus.svg";
import { ANALYTICS_EVENTS } from "../../constants";
import TemplateCreateModal from "./TemplateCreateModal";
import { useNavigate, useParams } from "react-router-dom";
import { getTemplatePatchRequest } from "../../templateUtils";
import DataTable from "../DataTable";
import { TemplateColumns } from "../DataTable/TemplateColumns";
import { debugLog } from "../../logging";

function Templates() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { turnaroundTemplate, turnaroundTemplates, currentUser, searchQuery } =
    mainContext;

  const { templateUuid } = useParams();
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isTemplateSaving, setIsTemplateSaving] = useState(false);
  const [isTemplateCreateOpen, setIsTemplateCreateOpen] = useState(false);

  useEffect(() => {
    // Load template
    if (!isNullOrUndefined(templateUuid)) {
      getTurnaroundTemplate(dispatch, templateUuid);
    } else {
      setSelectedTemplate(null);
    }
    return () => {
      // Clear the template
      dispatch({
        type: "setTurnaroundTemplate",
        value: null,
      });
    };
  }, [dispatch, templateUuid]);

  useEffect(() => {
    if (
      !isNullOrUndefined(turnaroundTemplate) &&
      templateUuid === turnaroundTemplate.uuid
    ) {
      setSelectedTemplate(deepCopy(turnaroundTemplate));
    }
  }, [turnaroundTemplate, templateUuid]);

  const hasEditTurnTemplate = userHasAnyResourceAction(
    currentUser,
    ResourceActions.EditTurnTemplate
  );
  // NOTE: Temp only allow INTERNAL users for CREATE and DELETE
  const hasCreateTurnTemplate = userHasAnyResourceAction(
    currentUser,
    ResourceActions.EditTurnTemplate
  );
  const hasDeleteTurnTemplate = userHasAnyResourceAction(
    currentUser,
    ResourceActions.EditTurnTemplate
  );

  useEffect(() => {
    getTurnaroundTemplates(dispatch);
  }, [dispatch]);

  async function handleSaveTemplate(templateToSave) {
    if (isNullOrUndefined(templateToSave)) return;
    if (isTemplateSaving) return;

    setIsTemplateSaving(true);
    let result = null;

    // V2 Template endpoint will handle Create/Update/Delete for Operations and Requirements
    const templatePatchRequest = getTemplatePatchRequest(
      selectedTemplate,
      templateToSave
    );
    result = await patchTurnaroundTemplate(dispatch, templatePatchRequest);

    if (result) {
      const airline = selectedTemplate?.airline
        ? selectedTemplate?.airline.name
        : "-";
      const airport = selectedTemplate?.airport
        ? selectedTemplate?.airport.iata
        : "-";
      const aircraft = selectedTemplate?.aircraftType
        ? selectedTemplate?.aircraftType.name
        : "-";
      const templateName = `${aircraft} - ${airline} ${airport}`;
      getTurnaroundTemplates(dispatch);
      // Clear the template
      dispatch({
        type: "setTurnaroundTemplate",
        value: null,
      });
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("saved_web", { name: templateName }),
      });
    } else {
      debugLog(
        `Error updating template: ${JSON.stringify(templatePatchRequest)}`
      );
    }
    // reset
    setSelectedTemplate(null);
    setIsTemplateSaving(false);
    logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.TURNAROUND_TEMPLATE_SAVE);
    navigate("/templates");
  }

  const templatesList = deepCopy(turnaroundTemplates);
  sortByField(templatesList, "airline.name", ["aircraftType.name"]);
  const templatesByAircraftType = {};
  if (!isNullOrUndefined(templatesList)) {
    for (let i = 0; i < templatesList.length; i++) {
      const template = templatesList[i];
      const aircraftType = template?.aircraftType.name;
      if (aircraftType in templatesByAircraftType) {
        templatesByAircraftType[aircraftType] =
          templatesByAircraftType[aircraftType] + 1;
      } else {
        templatesByAircraftType[aircraftType] = 1;
      }
    }
  }

  function handleCreateTemplate() {
    setIsTemplateCreateOpen(true);
    logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.TURNAROUND_TEMPLATE_CREATE);
  }

  async function handleDelete(templateToDelete) {
    const result = await archiveTemplate(dispatch, templateToDelete.uuid);
    if (result) {
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("deleted_web", {
          name: `${templateToDelete.aircraftType.name} ${templateToDelete.airline.name}`,
        }),
      });
      dispatch({
        type: "setConfirmation",
        confirmation: null,
      });
      getTurnaroundTemplates(dispatch);
    }
    logAnalyticsEvent(
      dispatch,
      ANALYTICS_EVENTS.TURNAROUND_TEMPLATE_DELETE_SAVE
    );
  }

  const handleDeleteTemplate = (templateToDelete) => {
    dispatch({
      type: "setConfirmation",
      confirmation: {
        message: t("delete_msg_web", {
          name: `${templateToDelete.aircraftType.name} ${templateToDelete.airline.name}`,
        }),
        actionOK: () => {
          handleDelete(templateToDelete);
        },
        actionCancel: () => {
          dispatch({
            type: "setConfirmation",
            confirmation: null,
          });
        },
      },
    });
  };

  return (
    <div className="templates">
      <div
        className={`templates-container${
          !isNullOrUndefined(selectedTemplate) ? " editor" : " listing"
        }`}
      >
        {isNullOrUndefined(selectedTemplate) && (
          <>
            <div className="templates-content-header page-toolbar">
              <div>
                <h2>{t("turnaround_templates")}</h2>
              </div>
              <div>
                {hasCreateTurnTemplate && (
                  <button
                    onClick={() => {
                      handleCreateTemplate();
                    }}
                  >
                    <Plus /> {t("create_template")}
                  </button>
                )}
              </div>
            </div>
            <div className="templates-content">
              <div>
                <DataTable
                  initialSort={TemplateColumns.Airline.id}
                  dataList={templatesList}
                  searchQuery={searchQuery}
                  columnNames={[
                    TemplateColumns.Airline,
                    TemplateColumns.AircraftType,
                    TemplateColumns.Airport,
                  ]}
                  rowActions={{
                    editAction: (template) => {
                      logAnalyticsEvent(
                        dispatch,
                        ANALYTICS_EVENTS.TURNAROUND_TEMPLATE_EDIT
                      );
                      navigate(`/templates/${template.uuid}`);
                    },
                    deleteAction: hasDeleteTurnTemplate
                      ? (template) => {
                          handleDeleteTemplate(template);
                          logAnalyticsEvent(
                            dispatch,
                            ANALYTICS_EVENTS.TURNAROUND_TEMPLATE_DELETE
                          );
                        }
                      : null,
                  }}
                  gridColumns={"repeat(3, 1fr) 96px"}
                />
              </div>
            </div>
          </>
        )}
        {!isNullOrUndefined(selectedTemplate) && (
          <TemplateEditor
            template={selectedTemplate}
            onBack={() => {
              navigate("/templates");
              setSelectedTemplate(null);
            }}
            onSave={(templateToSave) => {
              handleSaveTemplate(templateToSave);
            }}
            isTemplateSaving={isTemplateSaving}
            isReadOnly={!hasEditTurnTemplate}
          />
        )}
      </div>
      {isTemplateCreateOpen && (
        <TemplateCreateModal
          isOpen={isTemplateCreateOpen}
          onClose={() => {
            setIsTemplateCreateOpen(false);
          }}
          onSave={() => {
            setIsTemplateCreateOpen(false);
            // Reload templates
            getTurnaroundTemplates(dispatch);
          }}
          allTemplates={templatesList}
        />
      )}
    </div>
  );
}

export default Templates;
