import { ReactComponent as Arrival } from "../../assets/turnarounds/arrival.svg";
import { ReactComponent as Departure } from "../../assets/turnarounds/departure.svg";
import { ReactComponent as CriticalIcon } from "../../assets/critical.svg";
import { Fade, Tooltip } from "@mui/material";
import { tooltipLightStyles } from "../../styleUtils";
import { useTranslation } from "react-i18next";
import { isNullOrUndefined } from "../../utils";

function TurnaroundName(props) {
  const { item, detail } = props;
  const { t } = useTranslation();

  const hasDeparted = !isNullOrUndefined(
    item?.outboundFlight?.timestamps?.actualGateOut
  );
  const hasArrived =
    !hasDeparted &&
    !isNullOrUndefined(item?.inboundFlight?.timestamps?.actualGateIn);

  return (
    <Tooltip
      title={
        !item.hasValidTurnaroundTime ? (
          <div className="tooltip-content">
            <div className="critical">
              {t("turnaround_time_invalid_message")}
            </div>
          </div>
        ) : null
      }
      placement="top"
      componentsProps={tooltipLightStyles}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 500 }}
      enterDelay={500}
      arrow
    >
      <div className={`turnaround-flights`}>
        <div className="flight-status">
          {!item.hasValidTurnaroundTime && (
            <div className="flight-critical-indicator">
              <div>
                <CriticalIcon />
              </div>
            </div>
          )}
          {item.hasValidTurnaroundTime && (
            <div
              className={`flight-status-icon${
                !hasArrived && !hasDeparted ? " status-pending" : ""
              }`}
            >
              {hasArrived && <Arrival />}
              {hasDeparted && <Departure />}
            </div>
          )}
        </div>
        <div className="flight-names-container">
          <div className="flight-names">{item?.combinedFlightName}</div>
          <div className="flight-detail">{detail}</div>
        </div>
      </div>
    </Tooltip>
  );
}
export default TurnaroundName;
