import "./styles.css";
import moment from "moment-timezone";
import { isNullOrUndefined } from "../../utils";
import ButtonDatePicker from "../ButtonDatePicker";
import { MIN_DATE } from "../../constants";
import TimeInput from "../TimeRangeInput/TimeInput";

function DateTimeInput(props) {
  const { onChange, timeValue, timezone } = props;
  const mToday = moment().tz(timezone).startOf("day");

  return (
    <div className="date-time-input">
      <div className="date-time-input-value">
        {!isNullOrUndefined(timeValue) && (
          <div className="date-time-input-container">
            <div>
              <TimeInput
                timeValue={timeValue}
                timezone={timezone}
                onTimeChange={(dateStrVal) => {
                  const newVal = moment(dateStrVal).tz(timezone);
                  const updatedVal = moment(timeValue).tz(timezone);
                  updatedVal.set("hours", newVal.hours());
                  updatedVal.set("minutes", newVal.minutes());
                  onChange(updatedVal.toISOString());
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="date-time-input-date-value">
        {!isNullOrUndefined(timeValue) && (
          <ButtonDatePicker
            label={moment(timeValue).tz(timezone).format("MM/DD/YYYY")}
            value={moment(timeValue).tz(timezone)}
            onChange={(newValue) => {
              // update
              const updatedVal = moment(timeValue).tz(timezone);
              updatedVal.set("date", newValue.date());
              updatedVal.set("month", newValue.month());
              updatedVal.set("year", newValue.year());
              onChange(updatedVal.toISOString());
            }}
            minDate={moment(MIN_DATE)}
            maxDate={moment(mToday).add(2, "month")}
          />
        )}
      </div>
    </div>
  );
}

export default DateTimeInput;
