import i18next from "./i18n";
import {
  getAllGseRecordsForTurnaround,
  getAllOperationsForTurnaround,
} from "./gseUtils";
import {
  formatHoursMins,
  formatTime,
  getByFieldValue,
  isBlank,
  isEmptyList,
  isNullOrUndefined,
  sortByDateField,
} from "./utils";
import moment from "moment-timezone";

import {
  EMPTY_VALUE,
  FliteTimeTypes,
  RepositionOperationNames,
} from "./constants";
import { getUserInfo } from "./userUtils";
import { getDuration, isDateAfter, isDateBefore } from "./dateUtils";

// TURNAROUND LEVEL STATUS
export const TurnaroundStatus = {
  TurnaroundPlanned: 1,
  TurnaroundInProgress: 2,
  TurnaroundComplete: 3,
  TurnaroundNotConfigured: 4,
};

// Deprecated
// Sync with: https://github.com/Moonware-Inc/halo/blob/7c9e430f9143eea1046474e89cd1ba2af240a143/src/web-api/enums/turnaround_operation_status.go
export const OperationStatus = {
  PLANNED: 1,
  DELAYED: 2,
  IN_PROGRESS: 3,
  COMPLETE: 4,
};

// Sync with https://github.com/Moonware-Inc/halo/blob/5b023b79155a4f29724a7d0a1dabf60ed33c1eb0/src/web-api/enums/turnaround_operation_status.go#L4
export const TurnaroundOperationStatus = {
  PLANNED: 100,
  IN_PROGRESS: 200,
  IN_PROGRESS_LATE: 201,
  COMPLETED: 300,
  COMPLETED_LATE: 301,
  COMPLETED_PARTIALLY: 302,
  MISSING: 400,
};

// Helper functions for TurnaroundOperationStatus
export function isOperationInProgress(turnaroundOperationStatus) {
  return (
    turnaroundOperationStatus === TurnaroundOperationStatus.IN_PROGRESS ||
    turnaroundOperationStatus === TurnaroundOperationStatus.IN_PROGRESS_LATE
  );
}
export function isOperationCompleted(turnaroundOperationStatus) {
  return (
    turnaroundOperationStatus === TurnaroundOperationStatus.COMPLETED ||
    turnaroundOperationStatus === TurnaroundOperationStatus.COMPLETED_LATE ||
    turnaroundOperationStatus === TurnaroundOperationStatus.COMPLETED_PARTIALLY
  );
}

export const OperationOffsetType = {
  INBOUND: 1,
  OUTBOUND: 2,
  FULL: 3,
};

export function getNameKeyForOperationOffsetType(operationOffsetType) {
  if (operationOffsetType === OperationOffsetType.INBOUND) {
    return "inbound";
  } else if (operationOffsetType === OperationOffsetType.OUTBOUND) {
    return "outbound";
  } else if (operationOffsetType === OperationOffsetType.FULL) {
    return "full_duration";
  }
}

export const TurnaroundPhaseType = {
  INBOUND: 0,
  TURNAROUND: 1,
  OUTBOUND: 2,
};

export const InfoType = {
  TURNAROUND: "TURNAROUND",
  FLIGHT: "FLIGHT",
};

export const TaskInfoType = {
  OPERATION: "OPERATION",
  CREW: "CREW",
  GSE: "GSE",
};

// Returns true if this is upcoming (according to our definition) relative to now
export function isUpcomingTurnaround(turnaroundInfo, airportTimezone) {
  if (isNullOrUndefined(turnaroundInfo)) return false;
  const now = moment().tz(airportTimezone).startOf("day");
  const outboundFlight = turnaroundInfo.outboundFlight;
  const inboundFlight = turnaroundInfo.inboundFlight;

  if (!isNullOrUndefined(outboundFlight)) {
    if (!isNullOrUndefined(turnaroundInfo.gateOut)) {
      return moment(turnaroundInfo.gateOut).tz(airportTimezone).isAfter(now);
    } else if (!isNullOrUndefined(turnaroundInfo.takeoff)) {
      return moment(turnaroundInfo.takeoff).tz(airportTimezone).isAfter(now);
    }
  } else if (!isNullOrUndefined(inboundFlight)) {
    if (!isNullOrUndefined(turnaroundInfo.gateIn)) {
      return moment(turnaroundInfo.gateIn).tz(airportTimezone).isAfter(now);
    } else if (!isNullOrUndefined(turnaroundInfo.landing)) {
      return moment(turnaroundInfo.landing).tz(airportTimezone).isAfter(now);
    }
  }
  return false;
}

// Deprecate
export function getUpcomingTurnarounds(turnarounds, airportTimezone) {
  const turnaroundsSorted = [];
  if (!isNullOrUndefined(turnarounds)) {
    for (let i = 0; i < turnarounds.length; i++) {
      const turnaround = turnarounds[i];
      if (isUpcomingTurnaround(turnaround, airportTimezone)) {
        turnaroundsSorted.push(turnaround);
      }
    }
    turnaroundsSorted.sort((a, b) => {
      const val1 = new Date(a);
      const val2 = new Date(b);
      if (val1 === val2) return 0;
      return val1 > val2 ? 1 : -1;
    });
  }
  return turnaroundsSorted;
}

export function getStand(turnaround) {
  if (isNullOrUndefined(turnaround)) return null;
  return !isNullOrUndefined(turnaround.stand) ? turnaround.stand.name : null;
}
export function getPreviousStand(turnaround) {
  if (isNullOrUndefined(turnaround)) return null;
  return !isNullOrUndefined(turnaround.previousStand)
    ? turnaround.previousStand.name
    : null;
}

// NOTE: This turnaround info is a highly stripped down version
export function getTurnaroundInfoForTurnaroundSummary(turnaroundSummary) {
  const {
    inboundFlightSummary: inboundFlight,
    outboundFlightSummary: outboundFlight,
    status,
  } = turnaroundSummary;
  const turnaroundInfo = {
    _infoType: InfoType.TURNAROUND,
    _source: turnaroundSummary,
    _sourceType: "summary",
    uuid: turnaroundSummary.uuid,
    registration: turnaroundSummary.registration,
    status: status,
    assignedStandName: turnaroundSummary.assignedStandName,
    snappedStandName: turnaroundSummary.actualStandName,
    activeSnappedStandName: turnaroundSummary.snappedStandName, // TODO: this will be refactored to get from positions response
    previouslyAssignedStandName: turnaroundSummary.previouslyAssignedStandName,
    remarks: turnaroundSummary.remarks,
    leadsUuids: turnaroundSummary.leadsUuids,
    finalized: turnaroundSummary.finalized,
    operationSummary: turnaroundSummary.operationSummary,
    closedAt: turnaroundSummary.closedAt,
  };

  turnaroundInfo.hasIncompleteOperations = hasIncompleteOperations(
    turnaroundSummary?.operationSummary
  );
  turnaroundInfo.operationPercentComplete = getOperationPercentComplete(
    turnaroundInfo.operationSummary
  );

  // Resolved timestamps - in/outbound
  turnaroundInfo.resolvedInboundTimes = resolveTurnaroundTime(
    turnaroundInfo,
    true
  );
  turnaroundInfo.resolvedOutboundTimes = resolveTurnaroundTime(
    turnaroundInfo,
    false
  );
  turnaroundInfo.hasValidTurnaroundTime =
    isNullOrUndefined(turnaroundInfo?.resolvedOutboundTimes?.displayTime) ||
    isDateAfter(
      turnaroundInfo.resolvedOutboundTimes.displayTime,
      turnaroundInfo.resolvedInboundTimes.displayTime
    ); // Outbound is after inbound

  turnaroundInfo.sortableTimeValue = !isNullOrUndefined(
    turnaroundInfo.resolvedInboundTimes
  )
    ? turnaroundInfo.resolvedInboundTimes.displayTime
    : null;

  if (!isNullOrUndefined(status)) {
    turnaroundInfo.statusCode = status.statusCode;
    turnaroundInfo.statusDisplayName = status.displayName;
    turnaroundInfo.percentComplete = status.percentComplete;
  }
  if (!isNullOrUndefined(inboundFlight)) {
    turnaroundInfo.airportCode = inboundFlight.destAirportIata;
    turnaroundInfo.airportName = inboundFlight.destAirportIata;
  } else if (!isNullOrUndefined(outboundFlight)) {
    turnaroundInfo.airportCode = outboundFlight.originAirportIata;
    turnaroundInfo.airportName = outboundFlight.originAirportIata;
  }

  if (!isNullOrUndefined(inboundFlight)) {
    turnaroundInfo.inboundFlight = inboundFlight;
    turnaroundInfo.inboundFlightId = inboundFlight.uuid;
    turnaroundInfo.inboundAirline = inboundFlight?.airlineIata;
    turnaroundInfo.inboundFlightNumber = inboundFlight.flightNumber;
    turnaroundInfo.inboundFlightName = `${turnaroundInfo.inboundAirline} ${inboundFlight.flightNumber}`;
    turnaroundInfo.originAirport = inboundFlight.originAirportIata;
    turnaroundInfo.turnaroundAirport = inboundFlight.destinationAirportIata;

    // Arrival Stand
    turnaroundInfo.arrivalStand = {
      name: inboundFlight.assignedStandName,
    };

    // Aircraft Type
    turnaroundInfo.inboundAircraftType = null;
    //inboundFlight?.aircraft?.aircraftVariant?.displayName;
    turnaroundInfo.inboundAircraftTypeModel = null;
    //inboundFlight?.aircraft?.aircraftVariant?.aircraftType?.name;
  }
  if (!isNullOrUndefined(outboundFlight)) {
    turnaroundInfo.outboundFlight = outboundFlight;
    turnaroundInfo.outboundFlightId = outboundFlight.uuid;
    turnaroundInfo.outboundFlightAirline = outboundFlight?.airlineIata;
    turnaroundInfo.outboundFlightNumber = outboundFlight.flightNumber;
    turnaroundInfo.outboundFlightName = `${turnaroundInfo.outboundFlightAirline} ${outboundFlight.flightNumber}`;
    turnaroundInfo.destinationAirport = outboundFlight.destAirportIata;

    // Departure Stand
    turnaroundInfo.departureStand = {
      name: outboundFlight.assignedStandName,
    };

    // Aircraft Type
    turnaroundInfo.outboundAircraftType = null;
    // outboundFlight?.aircraft?.aircraftVariant?.displayName;
    turnaroundInfo.outboundAircraftTypeModel = null;
    // outboundFlight?.aircraft?.aircraftVariant?.aircraftType?.name;
  }
  turnaroundInfo.gateIn = inboundFlight?.gateInTime;
  turnaroundInfo.gateOut = outboundFlight?.gateOutTime;
  turnaroundInfo.landing = inboundFlight?.landingTime;
  turnaroundInfo.takeoff = outboundFlight?.takeoffTime;
  const mGateIn = !isNullOrUndefined(turnaroundInfo?.gateIn)
    ? moment(turnaroundInfo?.gateIn)
    : null;
  const mGateOut = !isNullOrUndefined(turnaroundInfo?.gateOut)
    ? moment(turnaroundInfo?.gateOut)
    : null;
  const mTakeOff = !isNullOrUndefined(turnaroundInfo?.takeoff)
    ? moment(turnaroundInfo?.takeoff)
    : null;
  const mTakeLanding = !isNullOrUndefined(turnaroundInfo?.landing)
    ? moment(turnaroundInfo?.landing)
    : null;
  turnaroundInfo.gateTimeMinutes =
    !isNullOrUndefined(mGateIn) && !isNullOrUndefined(mGateOut)
      ? mGateOut.diff(mGateIn, "minutes")
      : null;
  turnaroundInfo.groundTimeMinutes =
    !isNullOrUndefined(mTakeOff) && !isNullOrUndefined(mTakeLanding)
      ? mTakeOff.diff(mTakeLanding, "minutes")
      : null;

  // Actual snapped stand for historical purposes (will not be cleared on unsnap)
  turnaroundInfo.snappedStand = {
    name: turnaroundSummary.actualStandName,
  };
  // TODO: This needs to be refactored to get the active stand from positions response
  turnaroundInfo.activeSnappedStand = {
    name: turnaroundInfo.activeSnappedStandName,
  };
  turnaroundInfo.stand = turnaroundSummary.actualStandName;

  // dispatch-related fields
  turnaroundInfo.combinedFlightName = `${turnaroundInfo.inboundFlightName}${
    !isNullOrUndefined(turnaroundInfo.outboundFlightName)
      ? ` - ${turnaroundInfo.outboundFlightName}`
      : ""
  }`;

  turnaroundInfo.isCompleted =
    turnaroundInfo.statusCode === TurnaroundStatus.TurnaroundComplete;

  // TODO: Remove approval stuff
  // turnaroundInfo.isReadyForDispatch = false;
  turnaroundInfo.isReadyForDispatch = true;
  turnaroundInfo.isActive = !turnaroundInfo.isCompleted;
  turnaroundInfo.isDispatched = false;
  turnaroundInfo.isApproved = false;

  turnaroundInfo.turnaroundLevelOperationStatus = {};

  // TODO: Remove this logic is obsolete since we are deprecating Critical
  if (!isNullOrUndefined(turnaroundSummary.status)) {
    turnaroundInfo.turnaroundLevelOperationStatus.numCriticalIssues =
      (turnaroundSummary.status.numMissingOps || 0) +
      (turnaroundSummary.status.numDelayedOps || 0);

    if (turnaroundSummary.status.numMissingOps > 0) {
      turnaroundInfo.turnaroundLevelOperationStatus.primaryStatus =
        TurnaroundOperationStatus.MISSING;
    } else if (turnaroundSummary.status.numDelayedOps > 0) {
      turnaroundInfo.turnaroundLevelOperationStatus.primaryStatus =
        TurnaroundOperationStatus.IN_PROGRESS_LATE;
    }
    turnaroundInfo.turnaroundLevelOperationStatus[
      TurnaroundOperationStatus.MISSING
    ] = turnaroundSummary.status.numMissingOps;
    turnaroundInfo.turnaroundLevelOperationStatus[
      TurnaroundOperationStatus.IN_PROGRESS_LATE
    ] = turnaroundSummary.status.numDelayedOps;
  }

  // NOTE: This should come directly from the status code
  turnaroundInfo.isInProgress =
    turnaroundInfo.statusCode === TurnaroundStatus.TurnaroundInProgress;
  turnaroundInfo.isCompleted =
    turnaroundInfo.statusCode === TurnaroundStatus.TurnaroundComplete;

  //NOTE: We are in the process of removing this
  turnaroundInfo.isCritical = false;
  turnaroundInfo.isActive = !turnaroundInfo.isCompleted;
  // turnaroundUsers are all user uuids that are associated to this turnaround
  turnaroundInfo.turnaroundUsers = {};

  // Resources at aircraft
  turnaroundInfo.inProgressTrackers = turnaroundSummary.inProgressTrackers;
  turnaroundInfo.inProgressUsers = turnaroundSummary.inProgressUsers;

  turnaroundInfo.needsRepositionToHardStand = !isNullOrUndefined(
    turnaroundSummary?.needsRepositionToHardStand
  )
    ? turnaroundSummary?.needsRepositionToHardStand
    : false;
  turnaroundInfo.needsRepositionToDepartureStand = !isNullOrUndefined(
    turnaroundSummary?.needsRepositionToDepartureStand
  )
    ? turnaroundSummary?.needsRepositionToDepartureStand
    : false;
  return turnaroundInfo;
}

// Use only with the FULL turnaround details
export function getTurnaroundInfo(turnaround) {
  const { inboundFlight, outboundFlight, status } = turnaround;
  const turnaroundInfo = {
    uuid: turnaround.uuid,
    chatChannelId: turnaround?.chatChannelId,
    _source: turnaround,
    _sourceType: "details",
    _infoType: InfoType.TURNAROUND,
    assignedStand: turnaround.assignedStand,
    assignedStandName: turnaround.assignedStand?.name,
    snappedStandName: turnaround.actualStand?.name,
    previouslyAssignedStandName: turnaround.previouslyAssignedStand?.name,
    remarks: turnaround.remarks,
    finalized: turnaround.finalized,
    closedAt: turnaround.closedAt,
    userRecords: turnaround.userRecords,
    gseRecords: turnaround.gseRecords,
  };
  if (!isNullOrUndefined(status)) {
    turnaroundInfo.statusCode = status.statusCode;
    turnaroundInfo.statusDisplayName = status.displayName;
    turnaroundInfo.percentComplete = status.percentComplete;
  }

  // Resolved timestamps - in/outbound
  turnaroundInfo.resolvedInboundTimes = resolveTurnaroundTime(
    turnaroundInfo,
    true
  );
  turnaroundInfo.resolvedOutboundTimes = resolveTurnaroundTime(
    turnaroundInfo,
    false
  );
  turnaroundInfo.hasValidTurnaroundTime =
    isNullOrUndefined(turnaroundInfo?.resolvedOutboundTimes?.displayTime) ||
    isDateAfter(
      turnaroundInfo.resolvedOutboundTimes.displayTime,
      turnaroundInfo.resolvedInboundTimes.displayTime
    ); // Outbound is after inbound

  // If sorting by date then use inbound
  turnaroundInfo.sortableTimeValue = !isNullOrUndefined(
    turnaroundInfo.resolvedInboundTimes
  )
    ? turnaroundInfo.resolvedInboundTimes.displayTime
    : null;

  if (!isNullOrUndefined(inboundFlight)) {
    turnaroundInfo.airportCode = inboundFlight.destinationAirport.iata;
    turnaroundInfo.airportTimezone = inboundFlight.destinationAirport.timezone;
    turnaroundInfo.airportName = inboundFlight.destinationAirport.name;
    turnaroundInfo.aircraftType =
      inboundFlight?.aircraft?.aircraftVariant?.displayName;
    turnaroundInfo.aircraftTypeModel =
      inboundFlight?.aircraft?.aircraftVariant?.aircraftType?.name;
    turnaroundInfo.registration = inboundFlight?.aircraft?.registration;
    // Gate
    turnaroundInfo.terminal = !isBlank(inboundFlight.actualArrivalTerminal)
      ? inboundFlight.actualArrivalTerminal
      : inboundFlight.scheduledArrivalTerminal;
    turnaroundInfo.gate = !isBlank(inboundFlight.actualArrivalGate)
      ? inboundFlight.actualArrivalGate
      : inboundFlight.scheduledlArrivalGate;
  } else if (!isNullOrUndefined(outboundFlight)) {
    turnaroundInfo.airportCode = outboundFlight.originAirport.iata;
    turnaroundInfo.airportTimezone = outboundFlight.originAirport.timezone;
    turnaroundInfo.airportName = outboundFlight.originAirport.name;
    turnaroundInfo.aircraftType =
      outboundFlight?.aircraft?.aircraftVariant?.displayName;
    turnaroundInfo.aircraftTypeModel =
      outboundFlight?.aircraft?.aircraftVariant?.aircraftType?.name;
    turnaroundInfo.registration = outboundFlight?.aircraft?.registration;

    // Gate
    turnaroundInfo.terminal = !isBlank(outboundFlight.actualDepartureTerminal)
      ? outboundFlight.actualDepartureTerminal
      : outboundFlight.scheduledDepartureTerminal;
    turnaroundInfo.gate = !isBlank(outboundFlight.actualDepartureGate)
      ? outboundFlight.actualDepartureGate
      : outboundFlight.scheduledlDepartureGate;
  }

  if (!isNullOrUndefined(inboundFlight)) {
    turnaroundInfo.inboundFlight = inboundFlight;
    turnaroundInfo.inboundAirline = !isNullOrUndefined(inboundFlight.airline)
      ? inboundFlight.airline.iata
      : null;
    turnaroundInfo.inboundFlightAirlineUuid = !isNullOrUndefined(
      inboundFlight.airline
    )
      ? inboundFlight.airline.uuid
      : null;
    turnaroundInfo.inboundFlightId = inboundFlight.uuid;
    turnaroundInfo.inboundFlightNumber = inboundFlight.flightNumber;
    turnaroundInfo.inboundFlightName = `${turnaroundInfo.inboundAirline} ${inboundFlight.flightNumber}`;
    turnaroundInfo.originAirport = inboundFlight.originAirport.iata;
    turnaroundInfo.turnaroundAirport = inboundFlight.destinationAirport.iata;
    // We don't have timezones for all airports, so display times in the turnaround airport's timezone
    // turnaroundInfo.originAirportTimezone = inboundFlight.originAirport.timezone;

    // Aircraft Type
    turnaroundInfo.inboundAircraftType =
      inboundFlight?.aircraft?.aircraftVariant?.displayName;
    turnaroundInfo.inboundAircraftTypeModel =
      inboundFlight?.aircraft?.aircraftVariant?.aircraftType?.name;
    turnaroundInfo.inboundRegistration = inboundFlight?.aircraft?.registration;

    // Gate
    turnaroundInfo.inboundArrivalTerminal = !isBlank(
      inboundFlight.actualArrivalTerminal
    )
      ? inboundFlight.actualArrivalTerminal
      : inboundFlight.scheduledArrivalTerminal;
    turnaroundInfo.inboundArrivalGate = !isBlank(
      inboundFlight.actualArrivalGate
    )
      ? inboundFlight.actualArrivalGate
      : inboundFlight.scheduledArrivalGate;

    // Status
    turnaroundInfo.inboundGateIn = inboundFlight.gateInTime;
    turnaroundInfo.inboundGateOut = inboundFlight.gateOutTime;
    turnaroundInfo.inboundLanding = inboundFlight.landingTime;
    turnaroundInfo.inboundTakeoff = inboundFlight.takeoffTime;
    turnaroundInfo.inboundDisplayStatus = inboundFlight.displayStatus;
    turnaroundInfo.scheduledGateInTime = inboundFlight.scheduledGateInTime;
    // Arrival Stand
    turnaroundInfo.arrivalStand = inboundFlight.arrivalStand;
  }
  if (!isNullOrUndefined(outboundFlight)) {
    turnaroundInfo.outboundFlight = outboundFlight;
    turnaroundInfo.outboundFlightId = outboundFlight.uuid;
    turnaroundInfo.outboundFlightAirline = !isNullOrUndefined(
      outboundFlight.airline
    )
      ? outboundFlight.airline.iata
      : null;
    turnaroundInfo.outboundFlightAirlineUuid = !isNullOrUndefined(
      outboundFlight.airline
    )
      ? outboundFlight.airline.uuid
      : null;
    turnaroundInfo.outboundFlightNumber = outboundFlight.flightNumber;
    turnaroundInfo.outboundFlightName = `${turnaroundInfo.outboundFlightAirline} ${outboundFlight.flightNumber}`;
    turnaroundInfo.destinationAirport = outboundFlight.destinationAirport.iata;

    // Aircraft Type
    turnaroundInfo.outboundAircraftType =
      outboundFlight?.aircraft?.aircraftVariant?.displayName;
    turnaroundInfo.outboundAircraftTypeModel =
      outboundFlight?.aircraft?.aircraftVariant?.aircraftType?.name;
    turnaroundInfo.outboundRegistration =
      outboundFlight?.aircraft?.registration;

    // Gate
    turnaroundInfo.outboundDepartureTerminal = !isBlank(
      outboundFlight.actualDepartureTerminal
    )
      ? outboundFlight.actualDepartureTerminal
      : outboundFlight.scheduledDepartureTerminal;
    turnaroundInfo.outboundDepartureGate = !isBlank(
      outboundFlight.actualDepartureGate
    )
      ? outboundFlight.actualDepartureGate
      : outboundFlight.scheduledDepartureGate;

    turnaroundInfo.outboundGateIn = outboundFlight.gateInTime;
    turnaroundInfo.outboundGateOut = outboundFlight.gateOutTime;
    turnaroundInfo.outboundLanding = outboundFlight.landingTime;
    turnaroundInfo.outboundTakeoff = outboundFlight.takeoffTime;
    turnaroundInfo.scheduledGateOutTime = outboundFlight.scheduledGateOutTime;
    // Status
    turnaroundInfo.outboundDisplayStatus = outboundFlight.displayStatus;
    // Departure Stand
    turnaroundInfo.departureStand = outboundFlight.departureStand;
  }

  turnaroundInfo.gateIn = turnaroundInfo.inboundGateIn;
  turnaroundInfo.landing = turnaroundInfo.inboundLanding;
  turnaroundInfo.gateOut = turnaroundInfo.outboundGateOut;
  turnaroundInfo.takeoff = turnaroundInfo.outboundTakeoff;

  // Use to determine if AC is snapped to a stand, it is updated when leaves stand
  turnaroundInfo.snappedStand = turnaround.actualStand;
  turnaroundInfo.standId = turnaround?.stand?.uuid;
  turnaroundInfo.stand = getStand(turnaround);
  turnaroundInfo.previousStand = getStand(turnaround);
  // Hardstand (Reposition Stand)
  turnaroundInfo.hardStand = turnaround.hardStand;
  turnaroundInfo.turnaroundLevelOperationStatus =
    getTurnaroundLevelOperationStatus(turnaround);

  // dispatch-related fields
  turnaroundInfo.combinedFlightName = `${turnaroundInfo.inboundFlightName}${
    !isNullOrUndefined(turnaroundInfo.outboundFlightName)
      ? ` - ${turnaroundInfo.outboundFlightName}`
      : ""
  }`;

  // NOTE: This should come directly from the status code
  turnaroundInfo.isInProgress =
    turnaroundInfo.statusCode === TurnaroundStatus.TurnaroundInProgress;
  turnaroundInfo.isCompleted =
    turnaroundInfo.statusCode === TurnaroundStatus.TurnaroundComplete;

  // TODO: We can deprecate this approval stuff
  turnaroundInfo.isReadyForDispatch = !isNullOrUndefined(turnaround.profile);
  turnaroundInfo.isActive =
    !isNullOrUndefined(turnaround.monitor) && !turnaroundInfo.isCompleted;
  turnaroundInfo.isDispatched = !isNullOrUndefined(turnaround.monitor);
  turnaroundInfo.isApproved = !isNullOrUndefined(
    turnaround.profile?.approvedAt
  );
  turnaroundInfo.approvalDeadline = turnaround.profile?.approvalDeadline;
  turnaroundInfo.approvedAt = isNullOrUndefined(
    turnaround.profile?.approvedByUser
  )
    ? turnaround.profile?.approvalDeadline
    : turnaround.profile?.approvedAt;
  turnaroundInfo.approvedByUser = turnaround.profile?.approvedByUser;
  // dispatchedAt is deprecated but is determined by this following
  turnaroundInfo.dispatchedAt = !isNullOrUndefined(turnaround.monitor)
    ? turnaroundInfo.approvalDeadline
    : null;

  const compareFn = (a, b) => {
    if (a === b) return 0;
    return a < b ? -1 : 1;
  };

  // TODO: Remove gtat stuff because initiatedTime/completedTime fields are deprecated
  // Ground turnaround start/end is determined by
  // earliest initiated and latest completed at the operation level
  const allOperations = getAllOperationsForTurnaround(turnaround);

  // turnaroundUsers are all user uuids that are associated to this turnaround
  turnaroundInfo.turnaroundUsers = {};

  if (!isNullOrUndefined(allOperations) && allOperations.length > 0) {
    const operationInitiatedTimes = [];
    const operationCompletedTimes = [];
    for (let i = 0; i < allOperations.length; i++) {
      const operation = allOperations[i];
      if (!isNullOrUndefined(operation.initiatedTime)) {
        operationInitiatedTimes.push(operation.initiatedTime);
      }
      if (!isNullOrUndefined(operation.completedTime)) {
        operationCompletedTimes.push(operation.completedTime);
      }
      if (operation.enabled) {
        // Collect all users associated to the turnaround
        const allCrewAssignments = [];
        const requirements = operation?.turnaroundRequirements;
        for (let j = 0; j < requirements.length; j++) {
          const requirement = requirements[j];
          allCrewAssignments.push.apply(
            allCrewAssignments,
            requirement.crewAssignments
          );
        }
        for (let j = 0; j < allCrewAssignments.length; j++) {
          const crewAssignment = allCrewAssignments[j];
          if (!isNullOrUndefined(crewAssignment.user)) {
            turnaroundInfo.turnaroundUsers[crewAssignment.userUuid] =
              crewAssignment.userUuid;
          }
        }
        const unassignedUserRecords = operation?.unassignedUserRecords;
        if (!isNullOrUndefined(unassignedUserRecords)) {
          for (let j = 0; j < unassignedUserRecords.length; j++) {
            const unassignedUserRecord = unassignedUserRecords[j];
            const userUuid = unassignedUserRecord?.user?.uuid;
            turnaroundInfo.turnaroundUsers[userUuid] = userUuid;
          }
        }
      }
    }
    operationInitiatedTimes.sort(compareFn);
    operationCompletedTimes.sort(compareFn);
    turnaroundInfo.gtatStart = operationInitiatedTimes[0];
    turnaroundInfo.gtatEnd =
      operationCompletedTimes[operationCompletedTimes.length - 1];

    // Operation summary
    turnaroundInfo.operationSummary = getOperationSummaryForTurnaround(
      turnaround?.profile
    );
    turnaroundInfo.hasIncompleteOperations = hasIncompleteOperations(
      turnaroundInfo.operationSummary
    );
    turnaroundInfo.operationPercentComplete = getOperationPercentComplete(
      turnaroundInfo.operationSummary
    );
  }

  const gseRecords = getAllGseRecordsForTurnaround(turnaround);
  if (!isNullOrUndefined(gseRecords) && gseRecords.length > 0) {
    // No longer using the GSE start/end times to determine the operation duration
    // Only use this to determine the operation expected start
    const gseExpectedStartTimes = [];
    for (let i = 0; i < gseRecords.length; i++) {
      const gseRecord = gseRecords[i];
      if (
        !isNullOrUndefined(gseRecord.operationInfo) &&
        !isNullOrUndefined(gseRecord.operationInfo.expectedStartTime)
      ) {
        gseExpectedStartTimes.push(
          new Date(gseRecord.operationInfo.expectedStartTime)
        );
      }
    }
    gseExpectedStartTimes.sort(compareFn);
    turnaroundInfo.gtatExpectedStartTime = gseExpectedStartTimes[0];
  }

  turnaroundInfo.operations = allOperations;
  turnaroundInfo.alerts = turnaround.alerts;
  turnaroundInfo.timelineEvents = turnaround.timelineEvents;
  turnaroundInfo.originalProfile = turnaround.profile;
  turnaroundInfo.originalMonitor = turnaround.monitor;

  // Override labels
  turnaroundInfo.turnaroundTemplateUuid =
    turnaround?.profile?.turnaroundTemplateUuid;
  turnaroundInfo.activeLabels = turnaround?.profile?.activeLabels;

  turnaroundInfo.needsRepositionToHardStand =
    turnaround.needsRepositionToHardStand;
  turnaroundInfo.needsRepositionToDepartureStand =
    turnaround.needsRepositionToDepartureStand;
  return turnaroundInfo;
}

// TODO: Refactor to use operation types
// For reposition reminder
export function getUpcomingRepositionOperations(operations, tz) {
  const repositionOperations = [];
  for (let i = 0; i < operations.length; i++) {
    const operationToCheck = operations[i];
    const mNow = moment().tz(tz);
    if (
      RepositionOperationNames.REPOSITION_1.toLowerCase() ===
        operationToCheck.name.toLowerCase() ||
      RepositionOperationNames.REPOSITION_2.toLowerCase() ===
        operationToCheck.name.toLowerCase()
    ) {
      const mStartTime = moment(operationToCheck.expectedStartTime).tz(tz);
      if (moment(mNow).isAfter(mStartTime)) {
        repositionOperations.push(operationToCheck);
      }
    }
  }
  return repositionOperations;
}

// Returns the list of turnarounds sorted by the earliest Gate-in
export function getTurnaroundInfoListForDay(
  turnarounds,
  airportTimezone,
  mStartDate,
  mEndDate,
  selectedTurnaroundUuid
) {
  const turnaroundsSorted = [];
  if (!isNullOrUndefined(turnarounds)) {
    for (let i = 0; i < turnarounds.length; i++) {
      const turnaround = turnarounds[i];
      const turnaroundInfo = getTurnaroundInfo(turnaround);
      if (!isNullOrUndefined(selectedTurnaroundUuid)) {
        if (selectedTurnaroundUuid === turnaroundInfo.uuid) {
          turnaroundsSorted.push(turnaroundInfo);
        }
      } else {
        const mGateIn = moment(turnaroundInfo.gateIn).tz(airportTimezone);
        const mGateOut = moment(turnaroundInfo.gateOut).tz(airportTimezone);
        if (
          !isNullOrUndefined(turnaround.inboundFlight) &&
          (mGateIn.isBetween(mStartDate, mEndDate, null, "[]") ||
            mGateOut.isBetween(mStartDate, mEndDate, null, "[]"))
        ) {
          turnaroundsSorted.push(turnaroundInfo);
        }
      }
    }
    turnaroundsSorted.sort((a, b) => {
      const val1 = new Date(a.gateIn);
      const val2 = new Date(b.gateIn);
      if (val1 === val2) return 0;
      return val1 > val2 ? 1 : -1;
    });
  }
  return turnaroundsSorted;
}

export function getOperationStartEndTimes(operation) {
  let startTime = null;
  let endTime = null;
  const { gseRecords } = operation;
  let allComplete = false;

  if (!isNullOrUndefined(gseRecords)) {
    allComplete = true;
    for (let i = 0; i < gseRecords.length; i++) {
      const gseRecord = gseRecords[i];
      const gseStartTime = !isNullOrUndefined(gseRecord.startTime)
        ? new Date(gseRecord.startTime)
        : null;
      const gseEndTime = !isNullOrUndefined(gseRecord.endTime)
        ? new Date(gseRecord.endTime)
        : null;
      const dStartTime = new Date(startTime);
      const dEndTime = new Date(endTime);
      if (
        !isNullOrUndefined(gseStartTime) &&
        (startTime === null || gseStartTime < dStartTime)
      ) {
        startTime = gseRecord.startTime;
      }
      if (
        !isNullOrUndefined(gseEndTime) &&
        (endTime === null || gseEndTime > dEndTime)
      ) {
        endTime = gseRecord.endTime;
      }
      if (isNullOrUndefined(gseEndTime)) {
        allComplete = false;
      }
    }
  }
  return { startTime, endTime, allComplete };
}

export function getOperationInfoListForTurnaround(item, timezone) {
  const mGtatStart = moment(item.gtatStart).tz(timezone).startOf("minute");
  const operationsList = [];
  if (!isNullOrUndefined(item.operations)) {
    for (let i = 0; i < item.operations.length; i++) {
      const operation = { ...item.operations[i] };
      operation.originalOperation = operation;

      if (!isNullOrUndefined(operation.initiatedTime)) {
        operation.mStartTime = moment(operation.initiatedTime)
          .tz(timezone)
          .startOf("minute");
        operation.isInProgress =
          operation?.status?.statusId ===
            TurnaroundOperationStatus.IN_PROGRESS ||
          operation?.status?.statusId ===
            TurnaroundOperationStatus.IN_PROGRESS_LATE;
        operation.isCompleted =
          operation?.status?.statusId === TurnaroundOperationStatus.COMPLETED ||
          operation?.status?.statusId ===
            TurnaroundOperationStatus.COMPLETED_LATE;

        if (!isNullOrUndefined(operation.completedTime)) {
          operation.mEndTime = moment(operation.completedTime)
            .tz(timezone)
            .startOf("minute");
        }
        if (
          !isNullOrUndefined(operation.mStartTime) &&
          !isNullOrUndefined(operation.mEndTime)
        ) {
          const mDiff = moment.duration(
            operation.mEndTime.diff(operation.mStartTime)
          );
          operation.durationMinutes = parseInt(mDiff.as("minutes"));
          operation.durationDisplay = formatHoursMins(
            operation.durationMinutes
          );
          operation.expectedDurationDisplay = formatHoursMins(
            operation.duration
          );

          const startOffsetDiff = moment.duration(
            operation.mStartTime.diff(mGtatStart)
          );
          operation.startOffsetMinutes = parseInt(
            startOffsetDiff.as("minutes")
          );

          operation.mExpectedStartTime = !isNullOrUndefined(
            operation.expectedStartTime
          )
            ? moment(operation.expectedStartTime).tz(timezone).startOf("minute")
            : null;

          operation.mExpectedEndTime = !isNullOrUndefined(
            operation.expectedEndTime
          )
            ? moment(operation.expectedEndTime).tz(timezone).startOf("minute")
            : null;

          // If operation did not start on time, this would be a positive number
          const useExpectedEndTime = !isNullOrUndefined(operation.duration);
          operation.minutesFromExpectedStartTime =
            !useExpectedEndTime &&
            !isNullOrUndefined(operation.status.delayMinutes) &&
            operation.mStartTime.isAfter(operation.mExpectedStartTime)
              ? parseInt(
                  moment
                    .duration(
                      operation.mStartTime.diff(operation.mExpectedStartTime)
                    )
                    .as("minutes")
                )
              : 0;

          // If operation did not end on time, this would be a positive number
          operation.minutesFromExpectedEndTime =
            useExpectedEndTime &&
            !isNullOrUndefined(operation.status.delayMinutes) &&
            operation.mEndTime.isAfter(operation.mExpectedEndTime)
              ? operation.status.delayMinutes
              : 0;

          operation.titleText = (
            <>
              <div>{operation?.type.name}</div>
              {!isNullOrUndefined(operation.mExpectedStartTime) && (
                <div>
                  {`Expected time:
                  ${formatTime(
                    operation.mExpectedStartTime.toDate(),
                    timezone,
                    false
                  )}${
                    !isNullOrUndefined(operation.mExpectedEndTime)
                      ? ` - ${formatTime(
                          operation.mExpectedEndTime.toDate(),
                          timezone,
                          false
                        )}`
                      : ""
                  }`}
                </div>
              )}
              {!isNullOrUndefined(operation.mStartTime) && (
                <div>
                  {`Actual time:
                  ${formatTime(
                    operation.mStartTime.toDate(),
                    timezone,
                    false
                  )}${
                    !isNullOrUndefined(operation.mEndTime)
                      ? ` - ${formatTime(
                          operation.mEndTime.toDate(),
                          timezone,
                          false
                        )}`
                      : ""
                  }`}
                </div>
              )}
              {!isNullOrUndefined(operation.expectedDurationDisplay) && (
                <div>
                  Expected duration: {operation.expectedDurationDisplay}
                </div>
              )}
              {!isNullOrUndefined(operation.durationDisplay) && (
                <div>Actual duration: {operation.durationDisplay}</div>
              )}
            </>
          );
        }
        operationsList.push(operation);
      }
    }
  }
  operationsList.sort((a, b) => {
    const val1 = a.mStartTime.toDate();
    const val2 = b.mStartTime.toDate();
    if (val1 === val2) return 0;
    return val1 > val2 ? 1 : -1;
  });
  return operationsList;
}

// Returns the most urgent status for the entire turnaround as primaryStatus
// Includes the count of operations with an urgent status (missing or delayed)
function getTurnaroundLevelOperationStatus(turnaround) {
  if (isNullOrUndefined(turnaround)) return null;
  if (turnaround?.status?.percentComplete === 100) {
    // Do not need the operation status if already completed
    return null;
  }
  const operations = getAllOperationsForTurnaround(turnaround);
  const turnaroundLevelOperationStatus = {};
  if (!isNullOrUndefined(operations)) {
    for (let i = 0; i < operations.length; i++) {
      const operation = operations[i];
      const statusId = operation?.status?.statusId;
      if (
        statusId === TurnaroundOperationStatus.MISSING ||
        statusId === TurnaroundOperationStatus.IN_PROGRESS_LATE
      ) {
        const statusValue = turnaroundLevelOperationStatus[statusId];
        if (isNullOrUndefined(statusValue)) {
          turnaroundLevelOperationStatus[statusId] = 1;
        } else {
          turnaroundLevelOperationStatus[statusId] = statusValue + 1;
        }
        // Set primary to MISSING, if present otherwise it can be anything else
        if (statusId === TurnaroundOperationStatus.MISSING) {
          turnaroundLevelOperationStatus.primaryStatus = statusId;
        } else if (
          isNullOrUndefined(turnaroundLevelOperationStatus.primaryStatus)
        ) {
          turnaroundLevelOperationStatus.primaryStatus = statusId;
        }
      }
    }
  }
  return turnaroundLevelOperationStatus;
}

export function getCurrentTurnaroundPhase(turnaroundInfo) {
  if (isNullOrUndefined(turnaroundInfo)) return null;
  if (turnaroundInfo?.inboundFlight?.progressPercent < 100) {
    return TurnaroundPhaseType.INBOUND;
  } else if (turnaroundInfo?.outboundFlight?.progressPercent > 0) {
    return TurnaroundPhaseType.OUTBOUND;
  } else {
    return TurnaroundPhaseType.TURNAROUND;
  }
}

export function hasCriticalTurnarounds(turnaroundInfoList) {
  if (isNullOrUndefined(turnaroundInfoList)) return false;
  return turnaroundInfoList.some((item) => item.isCritical);
}

export function getCriticalOperationsFromTurnaround(turnaroundInfo) {
  if (isNullOrUndefined(turnaroundInfo)) return null;
  const turnaroundOperations =
    turnaroundInfo?.originalMonitor?.turnaroundOperations;
  const criticalOperations = [];
  if (!isEmptyList(turnaroundOperations)) {
    for (let i = 0; i < turnaroundOperations.length; i++) {
      const turnaroundOperation = turnaroundOperations[i];
      const isUnackedCriticalOperation =
        turnaroundOperation.enabled &&
        isNullOrUndefined(turnaroundOperation.ackedAt) &&
        (turnaroundOperation?.status?.statusId ===
          TurnaroundOperationStatus.MISSING ||
          turnaroundOperation?.status?.statusId ===
            TurnaroundOperationStatus.IN_PROGRESS_LATE);
      if (isUnackedCriticalOperation) {
        criticalOperations.push(turnaroundOperation);
      }
    }
  }
  return criticalOperations;
}

export function getTaskInfoForTurnaround(turnaroundInfo, vehicles) {
  if (
    isEmptyList(turnaroundInfo?.originalProfile?.turnaroundOperations) &&
    isEmptyList(turnaroundInfo?.originalMonitor?.turnaroundOperations)
  )
    return null;

  const turnaroundOperations = !isEmptyList(
    turnaroundInfo?.originalProfile?.turnaroundOperations
  )
    ? turnaroundInfo.originalProfile.turnaroundOperations
    : turnaroundInfo.originalMonitor.turnaroundOperations;
  const tasks = [];

  // Process GSE records
  const gseRecords = turnaroundInfo.gseRecords;
  if (!isEmptyList(gseRecords)) {
    for (let j = 0; j < gseRecords.length; j++) {
      const gseRecord = gseRecords[j];
      const vehicle = getByFieldValue(
        vehicles,
        "uuid",
        gseRecord.groundVehicle.uuid
      );
      if (!isNullOrUndefined(vehicle)) {
        const processedEvents = getProcessedEvents(gseRecord.events);
        if (
          isValidTimestampForTimeline(
            processedEvents.firstStart,
            processedEvents.lastEnd
          )
        ) {
          tasks.push({
            uuid: vehicle.uuid,
            keyVal: `${turnaroundInfo.uuid}-${vehicle.uuid}`,
            name: vehicle.name,
            detail: vehicle.gseType.name,
            type: TaskInfoType.GSE,
            typeSortKey: 2,
            start: processedEvents.firstStart,
            end: processedEvents.lastEnd,
            duration: processedEvents.duration,
            eventPairs: processedEvents.eventPairs,
          });
        }
      }
    }
  }

  // Process User service records
  const userRecords = turnaroundInfo.userRecords;
  if (!isEmptyList(userRecords)) {
    for (let j = 0; j < userRecords.length; j++) {
      const userRecord = userRecords[j];
      const user = userRecord.user;
      if (!isNullOrUndefined(user)) {
        const userInfo = getUserInfo(user);
        const mStart = !isNullOrUndefined(userRecord.startTime)
          ? moment(userRecord.startTime).startOf("minute")
          : null;
        const mEnd = !isNullOrUndefined(userRecord.endTime)
          ? moment(userRecord.endTime).startOf("minute")
          : null;
        const durationInMinutes =
          !isNullOrUndefined(mEnd) && !isNullOrUndefined(mStart)
            ? mEnd.diff(mStart, "minutes")
            : 0;

        if (isValidTimestampForTimeline(mStart, mEnd)) {
          tasks.push({
            uuid: user.uuid,
            keyVal: `${turnaroundInfo.uuid}-${user.uuid}`,
            name: userInfo.fullName,
            type: TaskInfoType.CREW,
            typeSortKey: 1,
            start: userRecord.startTime,
            end: userRecord.endTime,
            duration: durationInMinutes,
          });
        }
      }
    }
  }

  // Include Operations start/stop
  for (let i = 0; i < turnaroundOperations.length; i++) {
    const turnaroundOperation = turnaroundOperations[i];
    if (turnaroundOperation.enabled) {
      // Note: Operation start/stop determined by manual start/stop only
      // (service records no longer tied to operations)
      const operationStartTime = turnaroundOperation.startedAt;
      const operationEndTime = turnaroundOperation.completedAt;

      const mStart = !isNullOrUndefined(operationStartTime)
        ? moment(operationStartTime).startOf("minute")
        : null;

      const mEnd = !isNullOrUndefined(operationEndTime)
        ? moment(operationEndTime).startOf("minute")
        : null;
      const durationInMinutes =
        !isNullOrUndefined(mEnd) && !isNullOrUndefined(mStart)
          ? mEnd.diff(mStart, "minutes")
          : 0;

      const operationExpectedStartTime = turnaroundOperation.expectedStartTime;
      const operationExpectedEndTime = turnaroundOperation.expectedEndTime;
      const mExpectedStart = !isNullOrUndefined(operationExpectedStartTime)
        ? moment(operationExpectedStartTime).startOf("minute")
        : null;
      const mExpectedEnd = !isNullOrUndefined(operationExpectedEndTime)
        ? moment(operationExpectedEndTime).startOf("minute")
        : null;
      const expectedDurationInMinutes =
        !isNullOrUndefined(mExpectedEnd) && !isNullOrUndefined(mExpectedStart)
          ? mExpectedEnd.diff(mExpectedStart, "minutes")
          : 0;

      const isValidTimetamp = isValidTimestampForTimeline(mStart, mEnd);
      if (isValidTimetamp) {
        tasks.push({
          uuid: turnaroundOperation.uuid,
          keyVal: turnaroundOperation.uuid,
          name: turnaroundOperation.type.name,
          detail: i18next.t(
            getNameKeyForOperationOffsetType(turnaroundOperation.offsetTypeId)
          ),
          type: TaskInfoType.OPERATION,
          typeSortKey: 0,
          start: operationStartTime,
          end: operationEndTime,
          expectedStart: operationExpectedStartTime,
          expectedEnd: operationExpectedEndTime,
          expectedDuration: expectedDurationInMinutes,
          duration: durationInMinutes,
        });
      }
    }
  }
  // Sort by chronological order
  sortByDateField(tasks, "start", false, ["typeSortKey", "name"]);
  return tasks;
}

// Determine the operation start/end times by service records
// Not longer using the deprecated fields initiatedTime/completedTime
export function getOperationServiceStartTime(turnaroundOperation) {
  const operationServiceRecords =
    getTurnaroundOperationServiceRecords(turnaroundOperation);

  sortByDateField(operationServiceRecords, "startTime");
  return !isEmptyList(operationServiceRecords)
    ? operationServiceRecords[0].startTime
    : null;
}
export function getOperationServiceEndTime(turnaroundOperation) {
  const operationServiceRecords =
    getTurnaroundOperationServiceRecords(turnaroundOperation);
  sortByDateField(operationServiceRecords, "endTime");
  return !isEmptyList(operationServiceRecords)
    ? operationServiceRecords[operationServiceRecords.length - 1].endTime
    : null;
}

function getTurnaroundOperationServiceRecords(turnaroundOperation) {
  const operationServiceRecords = [];
  turnaroundOperation?.gseRecords?.forEach((item) => {
    operationServiceRecords.push(item);
  });
  turnaroundOperation?.userRecords?.forEach((item) => {
    operationServiceRecords.push(item);
  });
  return operationServiceRecords;
}

function isValidTimestampForTimeline(mTaskStart, mTaskEnd) {
  if (isNullOrUndefined(mTaskStart)) {
    return false;
  }
  if (!isNullOrUndefined(mTaskEnd) && isDateBefore(mTaskEnd, mTaskStart)) {
    return false;
  }
  return true;
}

export function getMinMaxDate(list, fieldNameStart, fieldNameEnd) {
  const minMax = {
    min: null,
    max: null,
  };
  if (!isEmptyList(list)) {
    // Only check items that have a value for the given fieldName
    list.forEach((i) => {
      const start = !isNullOrUndefined(i[fieldNameStart])
        ? new Date(i[fieldNameStart])
        : null;
      // If there is no end, treat same as start
      const end = !isNullOrUndefined(i[fieldNameEnd])
        ? new Date(i[fieldNameEnd])
        : start;
      if (!isNullOrUndefined(start)) {
        if (isNullOrUndefined(minMax.min) || start < minMax.min) {
          minMax.min = start;
        }
      }
      if (!isNullOrUndefined(end)) {
        if (isNullOrUndefined(minMax.max) || end > minMax.max) {
          minMax.max = end;
        }
      }
    });
  }
  return minMax;
}

export function getGseRecordsByGseType(gseRecords) {
  const gseRecordsForType = {};
  if (!isEmptyList(gseRecords)) {
    for (let i = 0; i < gseRecords.length; i++) {
      const gseRecord = gseRecords[i];
      const typeUuid = gseRecord.groundVehicle.gseType.uuid;
      if (typeUuid in gseRecordsForType) {
        gseRecordsForType[typeUuid].push(gseRecord);
      } else {
        gseRecordsForType[typeUuid] = [gseRecord];
      }
    }
  }
  return gseRecordsForType;
}

export function getCrewRecordsForCrewAssignments(userRecords, crewAssignments) {
  const crewRecordsForCrewAssignments = [];
  const assignedCrewUsers = !isEmptyList(crewAssignments)
    ? crewAssignments.map((i) => i.userUuid)
    : [];

  if (!isEmptyList(userRecords)) {
    for (let i = 0; i < userRecords.length; i++) {
      const userRecord = userRecords[i];
      const userUuid = userRecord.user.uuid;
      if (assignedCrewUsers.includes(userUuid)) {
        crewRecordsForCrewAssignments.push(userRecord);
      }
    }
  }
  return crewRecordsForCrewAssignments;
}

export function resolveTurnaroundStands(item) {
  const assignedStandName = item.assignedStandName;
  const snappedStandName = item.snappedStandName;
  const previouslyAssignedStandName = item.previouslyAssignedStandName;

  let currentStand = EMPTY_VALUE;
  let prevStand = null;
  if (!isBlank(snappedStandName)) {
    currentStand = snappedStandName;
    prevStand = assignedStandName !== currentStand ? assignedStandName : null;
  } else {
    currentStand = assignedStandName;
    prevStand =
      previouslyAssignedStandName !== currentStand
        ? previouslyAssignedStandName
        : null;
  }
  return {
    currentStand: currentStand,
    prevStand: prevStand,
  };
}

// isInbound specifies if you want the in time or out time
export function resolveTurnaroundTime(item, isInbound) {
  // Use new timestamp fields
  // TODO: Make this better
  const itemSummary = item?._source;
  if (isNullOrUndefined(itemSummary)) return null;

  const inboundFlight =
    "inboundFlight" in itemSummary
      ? itemSummary?.inboundFlight
      : itemSummary?.inboundFlightSummary;
  const outboundFlight =
    "outboundFlight" in itemSummary
      ? itemSummary?.outboundFlight
      : itemSummary?.outboundFlightSummary;

  const flight = isInbound ? inboundFlight : outboundFlight;
  return resolveFlightTime(flight, isInbound);
}

// isInbound specifies if you want the in/out time
export function resolveFlightTime(flight, isInbound) {
  const scheduledTime = isInbound
    ? flight?.timestamps?.scheduledGateIn
    : flight?.timestamps?.scheduledGateOut;
  let estimatedTime = isInbound
    ? flight?.timestamps?.estimatedGateIn
    : flight?.timestamps?.estimatedGateOut;
  let actualTime = isInbound
    ? flight?.timestamps?.actualGateIn
    : flight?.timestamps?.actualGateOut;

  let displayTime = scheduledTime;
  let displayTimeType = FliteTimeTypes.SCHEDULED;
  if (!isBlank(actualTime)) {
    displayTime = actualTime;
    displayTimeType = FliteTimeTypes.ACTUAL;
  } else if (!isBlank(estimatedTime)) {
    displayTime = estimatedTime;
    displayTimeType = FliteTimeTypes.ESTIMATED;
  }

  const mDisplayTime = moment(displayTime).startOf("minute");
  const mScheduledTime = moment(scheduledTime).startOf("minute");
  const timeChangeDiff = mDisplayTime.diff(mScheduledTime, "minutes");
  const hasTimeChanged = timeChangeDiff !== 0;

  let displayTimeLabel = {
    inbound: "scheduled_time_arrival_abbr",
    outbound: "scheduled_time_departure_abbr",
  };

  if (displayTimeType === FliteTimeTypes.ESTIMATED) {
    displayTimeLabel.inbound = "estimated_time_arrival_abbr";
    displayTimeLabel.outbound = "estimated_time_departure_abbr";
  } else if (displayTimeType === FliteTimeTypes.ACTUAL) {
    displayTimeLabel.inbound = "actual_time_arrival_abbr";
    displayTimeLabel.outbound = "actual_time_departure_abbr";
  }

  return {
    displayTime: displayTime,
    displayTimeType: displayTimeType,
    scheduledTime: scheduledTime,
    hasTimeChanged: hasTimeChanged,
    timeChangeDiff: timeChangeDiff,
    displayTimeLabel: displayTimeLabel,
  };
}

// Dedupes based on renderOnMap
export function getDedupedTurnarounds(turnarounds) {
  const turnaroundsToRenderOnMap = [];
  if (!isEmptyList(turnarounds)) {
    turnarounds.forEach((i) => {
      if (i.renderOnMap) {
        turnaroundsToRenderOnMap.push(i);
      }
    });
  }
  return turnaroundsToRenderOnMap;
}

export function hasIncompleteOperations(operationSummary) {
  if (isNullOrUndefined(operationSummary)) return true;
  const enabledCount = operationSummary.enabledCount || 0;
  const completedCount = operationSummary.completedCount || 0;
  return completedCount === 0 || completedCount < enabledCount;
}

export function getOperationPercentComplete(operationSummary) {
  if (isNullOrUndefined(operationSummary)) return 0;
  const enabledCount = operationSummary.enabledCount || 0;
  const completedCount = operationSummary.completedCount || 0;
  return enabledCount === 0 || completedCount === 0
    ? 0
    : completedCount / enabledCount;
}

function getOperationSummaryForTurnaround(turnaroundProfile) {
  if (isEmptyList(turnaroundProfile?.turnaroundOperations)) return null;
  const operationSummary = {
    completedCount: 0,
    enabledCount: 0,
    startedCount: 0,
  };
  for (let i = 0; i < turnaroundProfile.turnaroundOperations.length; i++) {
    const operation = turnaroundProfile.turnaroundOperations[i];
    if (operation.enabled) {
      operationSummary.enabledCount++;
      if (!isNullOrUndefined(operation.startedAt)) {
        operationSummary.startedCount++;
      }
      if (!isNullOrUndefined(operation.completedAt)) {
        operationSummary.completedCount++;
      }
    }
  }
  return operationSummary;
}

export function getProcessedEvents(events) {
  const processedEvents = {
    eventPairs: [],
    firstStart: null,
    lastEnd: null,
    duration: 0,
  };
  if (!isEmptyList(events)) {
    sortByDateField(events, "timestmap");
    let startTime = null;
    let endTime = null;
    for (let i = 0; i < events.length; i++) {
      const event = events[i];
      if (event.entered) {
        startTime = event.timestamp;
        if (
          isNullOrUndefined(processedEvents.firstStart) ||
          isDateBefore(startTime, processedEvents.firstStart)
        ) {
          processedEvents.firstStart = startTime;
        }
      } else {
        endTime = event.timestamp;
        if (
          isNullOrUndefined(processedEvents.lastEnd) ||
          isDateAfter(endTime, processedEvents.lastEnd)
        ) {
          processedEvents.lastEnd = endTime;
        }
      }
      if (!isNullOrUndefined(startTime) && !isNullOrUndefined(endTime)) {
        processedEvents.eventPairs.push({
          startTime: startTime,
          endTime: endTime,
          duration: getDuration(startTime, endTime),
        });
        startTime = null;
        endTime = null;
      }
    }
  }
  if (
    !isNullOrUndefined(processedEvents.firstStart) &&
    !isNullOrUndefined(processedEvents.lastEnd)
  ) {
    processedEvents.duration = getDuration(
      processedEvents.firstStart,
      processedEvents.lastEnd
    );
  }
  return processedEvents;
}
