import { Modal } from "@mui/material";
import {
  deepCopy,
  getByFieldValue,
  isBlank,
  isEmptyList,
  isNullOrUndefined,
  sortByField,
} from "../../utils";
import { useEffect, useState } from "react";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { useTranslation } from "react-i18next";
import { ReactComponent as Cross } from "../../assets/cross.svg";
import {
  createFlight,
  getAircraftsForAirline,
  getAirlines,
  getAllAirports,
} from "../../flightsApi";
import RegistrationSelector from "../RegistrationSelector";
import SearchSelector from "../SearchSelector";
import CreateFlightTimeConfig from "./CreateFlightTimeConfig";

export default function CreateFlightModal(props) {
  const { onCloseHandler, onSaved, timezone, isOpen } = props;
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { airlines, aircraftsForAirline, allAirports } = mainContext;
  const [isProcessing, setIsProcessing] = useState(false);
  const [flightForCreate, setFlightForCreate] = useState({});

  // Load supporting data
  useEffect(() => {
    getAirlines(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (!isNullOrUndefined(flightForCreate.airlineUuid)) {
      getAircraftsForAirline(dispatch, flightForCreate.airlineUuid);
      getAllAirports(dispatch, flightForCreate.airlineUuid);
    }
  }, [dispatch, flightForCreate.airlineUuid]);

  function resetLookups() {
    dispatch({
      type: "setAircraftsForAirline",
      value: null,
    });
    dispatch({
      type: "setAirportsForAirline",
      value: null,
    });
  }

  const handleClose = () => {
    setFlightForCreate({});
    resetLookups();
    setIsProcessing(false);
    if (!isNullOrUndefined(onCloseHandler)) {
      onCloseHandler();
    }
  };

  async function handleSave() {
    setIsProcessing(true);
    const createFlightRequest = {
      airlineUuid: flightForCreate.airlineUuid,
      flightNumber: flightForCreate.flightNumber,
      originAirportUuid: flightForCreate.originAirportUuid,
      destinationAirportUuid: flightForCreate.destinationAirportUuid,
      registration: flightForCreate.registration,
      timestamps: {
        estimatedGateIn: flightForCreate.estimatedGateIn,
        estimatedGateOut: flightForCreate.estimatedGateOut,
        scheduledGateIn: flightForCreate.scheduledGateIn,
        scheduledGateOut: flightForCreate.scheduledGateOut,
      },
    };

    const result = await createFlight(dispatch, createFlightRequest);
    const originAirport = getByFieldValue(
      allAirports,
      "uuid",
      flightForCreate.originAirportUuid
    );
    const originIata = originAirport?.iata;
    const destinationAirport = getByFieldValue(
      allAirports,
      "uuid",
      flightForCreate.destinationAirportUuid
    );
    const destinationIata = destinationAirport?.iata;
    const flightName = `${flightForCreate.flightNumber}: ${originIata} - ${destinationIata}`;
    if (result) {
      dispatch({
        type: "setAlertMessage",
        alertMessage: (
          <div>
            <div>
              {t("saved_web", {
                name: flightName,
              })}
            </div>
            <div>{t("processing_request_message")}</div>
          </div>
        ),
      });
      if (!isNullOrUndefined(onSaved)) {
        onSaved();
      }
    } else {
      setIsProcessing(false);
    }
  }

  function checkIsFlightValid(flight) {
    const isValid =
      !isBlank(flight.airlineUuid) &&
      !isBlank(flight.flightNumber) &&
      !isBlank(flight.registration) &&
      !isNullOrUndefined(flight.originAirportUuid) &&
      !isNullOrUndefined(flight.destinationAirportUuid) &&
      !isNullOrUndefined(flight.scheduledGateIn) &&
      !isNullOrUndefined(flight.scheduledGateOut);
    return isValid;
  }
  // Check if the flight is valid to be saved
  const isFlightValid = checkIsFlightValid(flightForCreate);

  if (!isEmptyList(airlines)) {
    sortByField(airlines, "name");
  }
  if (!isEmptyList(aircraftsForAirline)) {
    sortByField(aircraftsForAirline, "registration");
  }

  const airportsForSelectionFiltered = [];
  if (!isEmptyList(allAirports)) {
    allAirports.forEach((i) => {
      if (!isBlank(i.iata)) {
        airportsForSelectionFiltered.push(i);
      }
    });
    sortByField(airportsForSelectionFiltered, "iata");
  }
  const hasAircrafts = !isEmptyList(aircraftsForAirline);
  const selectedAircraft = hasAircrafts
    ? getByFieldValue(
        aircraftsForAirline,
        "registration",
        flightForCreate?.registration
      )
    : null;

  const hasAirlines = !isEmptyList(airlines);
  const selectedAirline = hasAirlines
    ? getByFieldValue(airlines, "uuid", flightForCreate?.airlineUuid)
    : null;

  const hasAirports = !isEmptyList(allAirports);
  const originAirport =
    hasAirports && !isNullOrUndefined(flightForCreate?.originAirportUuid)
      ? getByFieldValue(allAirports, "uuid", flightForCreate.originAirportUuid)
      : null;
  const destinationAirport =
    hasAirports && !isNullOrUndefined(flightForCreate?.destinationAirportUuid)
      ? getByFieldValue(
          allAirports,
          "uuid",
          flightForCreate.destinationAirportUuid
        )
      : null;
  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <div className="modal flight-edit-modal create-flight">
          <div>
            <div className="modal-header">
              <div>
                <h2>{t("create_flight")}</h2>
              </div>

              <div className="button-icon" onClick={handleClose}>
                <Cross />
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-container">
              <div className="flight-edit-modal-rows">
                {/** Selected airline this flight is for */}
                {!isEmptyList(airlines) && (
                  <div className="flight-edit-modal-row">
                    <div className="airline-configuration">
                      <div>
                        <label>{t("airline")}</label>
                      </div>
                      <div>
                        <SearchSelector
                          onSelect={(value) => {
                            // Changing the airline resets everything
                            setFlightForCreate({
                              airlineUuid: value.uuid,
                            });
                            resetLookups();
                          }}
                          items={airlines}
                          searchableName="name"
                          selectedFieldName="uuid"
                          value={flightForCreate.airlineUuid}
                          allowClear={false}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {/** Flight number and Registration */}
                {!isEmptyList(aircraftsForAirline) && (
                  <>
                    <div className="flight-edit-modal-row">
                      <div className="flightnumber-configuration">
                        <div>
                          <label>{t("flight_number")}</label>
                        </div>
                        <div>
                          {!isNullOrUndefined(selectedAirline) && (
                            <>{selectedAirline.iata}</>
                          )}
                        </div>
                        <div>
                          <input
                            value={flightForCreate.flightNumber}
                            onChange={(e) => {
                              const flightToUpdate = deepCopy(flightForCreate);
                              flightToUpdate.flightNumber = e.target.value;
                              setFlightForCreate(flightToUpdate);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flight-edit-modal-row">
                      <div className="registration-configuration">
                        <div>
                          <label>{t("registration")}</label>
                        </div>
                        <div>
                          {!isNullOrUndefined(selectedAircraft) && (
                            <div className="registration-details">
                              <div>
                                {/* {selectedAircraft?.aircraftVariant?.displayName} */}
                              </div>
                            </div>
                          )}
                        </div>
                        <div>
                          <RegistrationSelector
                            onSelect={(value) => {
                              // update
                              const flightToUpdate = deepCopy(flightForCreate);
                              flightToUpdate.registration = value.registration;
                              setFlightForCreate(flightToUpdate);
                            }}
                            aircrafts={aircraftsForAirline}
                            value={flightForCreate.registration}
                            allowClear={false}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/** Origin/Destination Airports and flight times */}
                {!isEmptyList(airportsForSelectionFiltered) && (
                  <>
                    <div className="flight-edit-modal-row">
                      <div>
                        <hr />
                      </div>
                    </div>
                    <div className="flight-edit-modal-row">
                      <div className="airport-configuration">
                        <div>
                          <label>{t("origin_non_cap")}</label>
                        </div>
                        <div>
                          {!isNullOrUndefined(originAirport) && (
                            <div className="airport-details">
                              <div>
                                {!isBlank(originAirport.name)
                                  ? originAirport.name
                                  : originAirport.iata}
                              </div>
                              <div>({originAirport.iata})</div>
                            </div>
                          )}
                        </div>
                        <div>
                          <SearchSelector
                            onSelect={(value) => {
                              // update
                              const flightToUpdate = deepCopy(flightForCreate);
                              flightToUpdate.originAirportUuid = value.uuid;
                              setFlightForCreate(flightToUpdate);
                            }}
                            items={airportsForSelectionFiltered}
                            searchableName="iata"
                            selectedFieldName="uuid"
                            value={flightForCreate.originAirportUuid}
                            allowClear={false}
                          />
                        </div>
                      </div>
                    </div>

                    {!isNullOrUndefined(originAirport) && (
                      <>
                        <div className="flight-edit-modal-row">
                          <CreateFlightTimeConfig
                            label={t("scheduled_time_departure_abbr")}
                            flightForCreate={flightForCreate}
                            timeFieldName="scheduledGateOut"
                            timezone={timezone}
                            onChange={(updatedValue) => {
                              setFlightForCreate(updatedValue);
                            }}
                          />
                        </div>

                        <div className="flight-edit-modal-row">
                          <CreateFlightTimeConfig
                            label={`${t("estimated_time_departure_abbr")} (${t(
                              "optional"
                            ).toLowerCase()})`}
                            flightForCreate={flightForCreate}
                            timeFieldName="estimatedGateOut"
                            timezone={timezone}
                            onChange={(updatedValue) => {
                              setFlightForCreate(updatedValue);
                            }}
                          />
                        </div>
                      </>
                    )}

                    <div className="flight-edit-modal-row">
                      <div>
                        <hr />
                      </div>
                    </div>
                    <div className="flight-edit-modal-row">
                      <div className="airport-configuration">
                        <div>
                          <label>{t("destination_non_cap")}</label>
                        </div>
                        <div>
                          {!isNullOrUndefined(destinationAirport) && (
                            <div className="airport-details">
                              <div>
                                {!isBlank(destinationAirport.name)
                                  ? destinationAirport.name
                                  : destinationAirport.iata}
                              </div>
                              <div>({destinationAirport.iata})</div>
                            </div>
                          )}
                        </div>
                        <div>
                          <SearchSelector
                            onSelect={(value) => {
                              // update
                              const flightToUpdate = deepCopy(flightForCreate);
                              flightToUpdate.destinationAirportUuid =
                                value.uuid;
                              setFlightForCreate(flightToUpdate);
                            }}
                            items={airportsForSelectionFiltered}
                            searchableName="iata"
                            selectedFieldName="uuid"
                            value={flightForCreate.destinationAirportUuid}
                            allowClear={false}
                          />
                        </div>
                      </div>
                    </div>

                    {!isNullOrUndefined(destinationAirport) && (
                      <>
                        <div className="flight-edit-modal-row">
                          <CreateFlightTimeConfig
                            label={t("scheduled_time_arrival_abbr")}
                            flightForCreate={flightForCreate}
                            timeFieldName="scheduledGateIn"
                            timezone={timezone}
                            onChange={(updatedValue) => {
                              setFlightForCreate(updatedValue);
                            }}
                          />
                        </div>

                        <div className="flight-edit-modal-row">
                          <CreateFlightTimeConfig
                            label={`${t("estimated_time_arrival_abbr")} (${t(
                              "optional"
                            ).toLowerCase()})`}
                            flightForCreate={flightForCreate}
                            timeFieldName="estimatedGateIn"
                            timezone={timezone}
                            onChange={(updatedValue) => {
                              setFlightForCreate(updatedValue);
                            }}
                          />
                        </div>
                      </>
                    )}
                    <div className="flight-edit-modal-row">
                      <div>
                        <hr />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="modal-footer">
              <div>
                <button className="secondary" onClick={handleClose}>
                  {t("cancel")}
                </button>
              </div>
              <div className="action-buttons">
                <div>
                  <button
                    onClick={handleSave}
                    disabled={isProcessing || !isFlightValid}
                  >
                    {t("save")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
