import { useTranslation } from "react-i18next";
import { isBlank } from "../../utils";
import BatteryPower from "../BatteryPower";

function TurnaroundVehicle(props) {
  const { t } = useTranslation();

  const {
    vehicleInfo,
    vehiclePosition,
    selected,
    onClick,
    isSelectable,
    showBatteryPower,
    showStatusText,
  } = props;

  return (
    <div
      className={`turnaround-vehicle${selected ? " selected" : ""}${
        isSelectable ? " selectable" : ""
      }`}
      onClick={onClick}
      data-uuid={vehicleInfo?.groundVehicle?.uuid}
    >
      <div>
        <div className="status">
          <div className="status-ring">
            <div
              className={`status-indicator ${getStatusClassForVehicle(
                vehicleInfo
              )}`}
            ></div>
          </div>
        </div>
        <div className="turnaround-vehicle-info">
          <div>
            <div className="gse-name">
              <div>
                <label>{vehicleInfo.groundVehicle.name}</label>
              </div>
              {showBatteryPower && (
                <div>
                  <BatteryPower
                    battery={vehiclePosition?.battery}
                    hasPower={vehiclePosition?.hasPower}
                  />
                </div>
              )}
            </div>
            <div>
              <div className="gse-type-badge">
                <div>{vehicleInfo.groundVehicle.gseType?.name}</div>
              </div>
            </div>
            {showStatusText && (
              <span className="status">
                {getStatusDisplayTextForVehicle(vehicleInfo)}
              </span>
            )}
            <span>
              {t("tracker_id_lower_case")}:&nbsp;
              {vehicleInfo?.groundVehicle?.trackerCode}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

const getStatusDisplayTextForVehicle = (vehicle) => {
  if (!isBlank(vehicle.startTime) && !isBlank(vehicle.endTime)) {
    // complete
    return <span className="status-text">Complete</span>;
  } else if (!isBlank(vehicle.startTime)) {
    // in progress
    return <span className="status-text">In Progress</span>;
  } else {
    return <span className="status-text">No Status</span>;
  }
};

const getStatusClassForVehicle = (vehicle) => {
  if (!isBlank(vehicle.startTime) && !isBlank(vehicle.endTime)) {
    // complete
    return "";
  } else if (!isBlank(vehicle.startTime)) {
    // in progress
    return "status-3";
  } else {
    return "status-1";
  }
};

export default TurnaroundVehicle;
