import { getApiByResourceName } from "./api";

export async function getDepartmentRoster(dispatch, departmentUuid) {
  const resource = `departments/${departmentUuid}/roster`;
  return await getApiByResourceName(
    dispatch,
    resource,
    "DepartmentRoster",
    "get"
  );
}

export async function getDepartmentRosterByDepartmentId(
  dispatch,
  departmentUuid
) {
  const resource = `departments/${departmentUuid}/roster`;
  return await getApiByResourceName(
    dispatch,
    resource,
    "DepartmentRosterByDepartmentId",
    "get"
  );
}
