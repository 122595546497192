import { formatDate, formatTime, isNullOrUndefined } from "../../utils";
import { ReactComponent as Pencil } from "../../assets/pencil.svg";
import { useTranslation } from "react-i18next";

function TurnaroundModuleTimestamp(props) {
  const { t } = useTranslation();
  const { operation, mode, timezone, onClick, readonly, showMissingValue } =
    props;

  const startTime =
    mode === "expected" ? operation.expectedStartTime : operation.startedAt;
  const endTime =
    mode === "expected" ? operation.expectedEndTime : operation.completedAt;

  const clickable = !readonly && !isNullOrUndefined(onClick);

  const hasMissingValues =
    isNullOrUndefined(startTime) || isNullOrUndefined(endTime);

  return (
    <div
      className={`turnaround-module-timestamp${clickable ? " clickable" : ""}${
        hasMissingValues && showMissingValue ? " missing" : ""
      }`}
      onClick={(e) => {
        if (!readonly && !isNullOrUndefined(onClick)) {
          onClick();
        }
        e.stopPropagation();
      }}
    >
      <div>
        <div className="turnaround-module-timestamp-values">
          <div>
            <div className="datetime-range-values">
              <div>
                <div>{`${
                  !isNullOrUndefined(startTime)
                    ? formatTime(startTime, timezone)
                    : t("na")
                }`}</div>
                <div className="datetime-range-values-detail">{`${
                  !isNullOrUndefined(startTime)
                    ? formatDate(startTime, timezone)
                    : ""
                }`}</div>
              </div>
              <div className="datetime-range-values-hyphen">{`${
                !isNullOrUndefined(endTime) ? "-" : ""
              }`}</div>
              <div>
                <div>{`${
                  !isNullOrUndefined(endTime)
                    ? formatTime(endTime, timezone)
                    : ""
                }`}</div>
                <div className="datetime-range-values-detail">{`${
                  !isNullOrUndefined(endTime)
                    ? formatDate(endTime, timezone)
                    : ""
                }`}</div>
              </div>
            </div>
          </div>
        </div>
        {clickable && (
          <div className="turnaround-module-timestamp-edit">
            <Pencil />
          </div>
        )}
      </div>
    </div>
  );
}
export default TurnaroundModuleTimestamp;
