import { InfoType, resolveFlightTime } from "./turnaroundUtils";
import moment from "moment-timezone";
import { getByFieldValue, isNullOrUndefined } from "./utils";
import { getDistanceInMiles } from "./mapUtils";

export const FLIGHT_INFO_STATUS = {
  NOT_DEPARTED: "not_departed",
  IN_FLIGHT: "in_air",
  LANDED: "landed",
};

export function getPositionByRegistration(positions, registration) {
  if (
    isNullOrUndefined(positions?.aircrafts) ||
    isNullOrUndefined(registration)
  )
    return null;
  return getByFieldValue(positions.aircrafts, "registration", registration);
}

// Use only with a full TurnaroundDetails
export function getFlightInfo(
  flight,
  baseAirportIata,
  position,
  airportTimezone
) {
  const flightInfo = {
    _infoType: InfoType.FLIGHT,
    _source: flight,
    uuid: flight?.uuid,
  };
  flightInfo.flight = flight;
  flightInfo.destinationAirport = flight?.destinationAirport?.iata;
  flightInfo.originAirport = flight?.originAirport?.iata;

  flightInfo.isInbound = flight?.destinationAirport?.iata === baseAirportIata;
  flightInfo.isOutbound = flight?.originAirport?.iata === baseAirportIata;
  flightInfo.airlineIata = flight?.airline?.iata;

  flightInfo.flightNumber = flight?.flightNumber;
  flightInfo.flightName = `${flightInfo.airlineIata} ${flightInfo.flightNumber}`;
  flightInfo.registration = flight?.aircraft?.registration;

  // Resolved timestamps - in/outbound (replaces old concept of takeoff/landing times)
  flightInfo.resolvedInboundTimes = resolveFlightTime(flight, true);
  flightInfo.resolvedOutboundTimes = resolveFlightTime(flight, false);
  flightInfo.sortableTimeValue = flightInfo.isInbound
    ? flightInfo?.resolvedInboundTimes?.displayTime
    : flightInfo?.resolvedOutboundTimes?.displayTime;

  const mNow = moment().tz(airportTimezone);
  const flightTimeOut = flightInfo?.resolvedOutboundTimes?.displayTime;
  const flightTimeIn = flightInfo?.resolvedInboundTimes?.displayTime;
  const mDepartedTime = moment(flightTimeOut).tz(airportTimezone);
  const mArrivalTime = moment(flightTimeIn).tz(airportTimezone);
  const mFlightTimeOut = moment(flightTimeOut).tz(airportTimezone);
  const mFlightTimeIn = moment(flightTimeIn).tz(airportTimezone);
  flightInfo.minsTotal =
    !isNullOrUndefined(mArrivalTime) && !isNullOrUndefined(mDepartedTime)
      ? parseInt(
          moment.duration(mArrivalTime.diff(mDepartedTime)).as("minutes")
        )
      : 0;

  if (mNow.isBefore(mFlightTimeOut)) {
    flightInfo.flightInfoStatus = FLIGHT_INFO_STATUS.NOT_DEPARTED;
    flightInfo.minsRemaining = flightInfo.minsTotal;
    flightInfo.flightProgress = 0;
    flightInfo.minsToDeparture = parseInt(
      moment.duration(mDepartedTime.diff(mNow)).as("minutes")
    );
  } else if (mNow.isAfter(mFlightTimeOut) && mNow.isBefore(mFlightTimeIn)) {
    flightInfo.flightInfoStatus = FLIGHT_INFO_STATUS.IN_FLIGHT;
    flightInfo.minsRemaining = parseInt(
      moment.duration(mArrivalTime.diff(mNow)).as("minutes")
    );
    flightInfo.minsDeparted = parseInt(
      moment.duration(mNow.diff(mDepartedTime)).as("minutes")
    );
    flightInfo.flightProgress = Math.round(
      ((flightInfo.minsTotal - flightInfo.minsRemaining) /
        flightInfo.minsTotal) *
        100
    );
  } else if (mNow.isAfter(mFlightTimeIn)) {
    flightInfo.flightInfoStatus = FLIGHT_INFO_STATUS.LANDED;
    flightInfo.minsRemaining = 0;
    flightInfo.flightProgress = 100;
    flightInfo.minsLanded = parseInt(
      moment.duration(mNow.diff(mArrivalTime)).as("minutes")
    );
    flightInfo.minsDeparted = parseInt(
      moment.duration(mNow.diff(mDepartedTime)).as("minutes")
    );
  }
  // Basically replaces any need to calculate relative arrival/departure
  flightInfo.displayStatus = flight?.displayStatus;

  flightInfo.position = position;
  const aircraftCoordinates = !isNullOrUndefined(position)
    ? [position.longitude, position.latitude]
    : null;
  const arrivalAirportCoordinates = !isNullOrUndefined(
    flight?.destinationAirport
  )
    ? getAirportCoordinates(flight.destinationAirport)
    : null;

  // These distances are raw distances not based on aircraft flight path
  flightInfo.distanceToArrival =
    !isNullOrUndefined(aircraftCoordinates) &&
    !isNullOrUndefined(arrivalAirportCoordinates)
      ? getDistanceInMiles(aircraftCoordinates, arrivalAirportCoordinates)
      : null;
  return flightInfo;
}

// NOTE: flight is refactored to turnaroundsSummary
export function getFlightInfoForTurnaroundSummary(
  flight,
  baseAirportIata,
  position,
  airportTimezone
) {
  const flightInfo = {
    _infoType: InfoType.FLIGHT,
    _source: flight,
    uuid: flight?.uuid,
  };
  if (isNullOrUndefined(flight)) {
    return flightInfo;
  }
  flightInfo.flight = flight;
  flightInfo.registration = flight.registration;

  // NOTE: for each summary pair only one flight is used
  const primaryFlightSummary =
    flight?.inboundFlightSummary?.progressPercent < 100 ||
    isNullOrUndefined(flight.outboundFlightSummary)
      ? flight.inboundFlightSummary
      : flight.outboundFlightSummary;

  flightInfo.primaryFlightDisplayStatus = primaryFlightSummary?.displayStatus;

  flightInfo.isInbound =
    primaryFlightSummary?.destAirportIata === baseAirportIata;
  flightInfo.isOutbound =
    primaryFlightSummary?.originAirportIata === baseAirportIata;

  // Resolved timestamps - in/outbound
  flightInfo.resolvedInboundTimes = resolveFlightTime(
    primaryFlightSummary,
    true
  );
  flightInfo.resolvedOutboundTimes = resolveFlightTime(
    primaryFlightSummary,
    false
  );
  flightInfo.sortableTimeValue = flightInfo.isInbound
    ? flightInfo?.resolvedInboundTimes?.displayTime
    : flightInfo?.resolvedOutboundTimes?.displayTime;

  flightInfo.destinationAirport = primaryFlightSummary?.destAirportIata;
  flightInfo.originAirport = primaryFlightSummary?.originAirportIata;

  flightInfo.airlineIata = primaryFlightSummary?.airlineIata;

  flightInfo.flightNumber = primaryFlightSummary?.flightNumber;
  flightInfo.flightName = `${primaryFlightSummary?.airlineIata} ${primaryFlightSummary?.flightNumber}`;

  const flightTimeOut = flightInfo?.resolvedOutboundTimes?.displayTime;
  const flightTimeIn = flightInfo?.resolvedInboundTimes?.displayTime;
  flightInfo.airportTimezone = airportTimezone;

  const mNow = moment().tz(flightInfo.airportTimezone);
  const mDepartedTime = moment(flightTimeOut).tz(flightInfo.airportTimezone);
  const mArrivalTime = moment(flightTimeIn).tz(flightInfo.airportTimezone);
  const mFlightTimeOut = moment(flightTimeOut).tz(flightInfo.airportTimezone);
  const mFlightTimeIn = moment(flightTimeIn).tz(flightInfo.airportTimezone);
  flightInfo.minsTotal =
    !isNullOrUndefined(mArrivalTime) && !isNullOrUndefined(mDepartedTime)
      ? parseInt(
          moment.duration(mArrivalTime.diff(mDepartedTime)).as("minutes")
        )
      : 0;

  if (mNow.isBefore(mFlightTimeOut)) {
    flightInfo.flightInfoStatus = FLIGHT_INFO_STATUS.NOT_DEPARTED;
    flightInfo.minsRemaining = flightInfo.minsTotal;
    flightInfo.flightProgress = 0;
    flightInfo.minsToDeparture = parseInt(
      moment.duration(mDepartedTime.diff(mNow)).as("minutes")
    );
  } else if (mNow.isAfter(mFlightTimeOut) && mNow.isBefore(mFlightTimeIn)) {
    flightInfo.flightInfoStatus = FLIGHT_INFO_STATUS.IN_FLIGHT;
    flightInfo.minsRemaining = parseInt(
      moment.duration(mArrivalTime.diff(mNow)).as("minutes")
    );
    flightInfo.minsDeparted = parseInt(
      moment.duration(mNow.diff(mDepartedTime)).as("minutes")
    );
    flightInfo.flightProgress = Math.round(
      ((flightInfo.minsTotal - flightInfo.minsRemaining) /
        flightInfo.minsTotal) *
        100
    );
  } else if (mNow.isAfter(mFlightTimeIn)) {
    flightInfo.flightInfoStatus = FLIGHT_INFO_STATUS.LANDED;
    flightInfo.minsRemaining = 0;
    flightInfo.flightProgress = 100;
    flightInfo.minsLanded = parseInt(
      moment.duration(mNow.diff(mArrivalTime)).as("minutes")
    );
    flightInfo.minsDeparted = parseInt(
      moment.duration(mNow.diff(mDepartedTime)).as("minutes")
    );
  }

  flightInfo.snappedStand = flight.actualStandName;
  flightInfo.assignedStandName = primaryFlightSummary?.assignedStandName;
  flightInfo.position = position;

  return flightInfo;
}

function getAirportCoordinates(airport) {
  if (isNullOrUndefined(airport)) return null;
  if (
    isNullOrUndefined(airport.westLongitude) ||
    isNullOrUndefined(airport.eastLongitude) ||
    isNullOrUndefined(airport.southLatitude) ||
    isNullOrUndefined(airport.northLatitude)
  )
    return null;
  return [
    (airport.westLongitude + airport.eastLongitude) / 2,
    (airport.northLatitude + airport.southLatitude) / 2,
  ];
}
