import { useState } from "react";
import {
  deepCopy,
  formatDate,
  formatTime,
  isEmptyList,
  isNullOrUndefined,
  logAnalyticsEvent,
  makeKeyFriendlyString,
  roundToNearestFiveMinute,
} from "../../utils";
import { useTranslation } from "react-i18next";
import { useMainDispatchContext } from "../../MainContext";
import TimeRangeInput from "../TimeRangeInput";
import { selectInputFix } from "../../styleUtils";
import CrewInfo from "../CrewInfo";
import { ANALYTICS_EVENTS } from "../../constants";

function CrewShiftsRow(props) {
  const { t } = useTranslation();
  const {
    crewShift,
    airportTimezone,
    isEditable,
    onSelectCrewShift,
    onSaveCrewShift,
    onRemoveCrewShift,
    departments,
  } = props;
  const [crewShiftToEdit, setCrewShiftToEdit] = useState(null);
  const dispatch = useMainDispatchContext();

  if (isNullOrUndefined(crewShift)) return null;

  function handleRemove(crewShift) {
    dispatch({
      type: "setConfirmation",
      confirmation: {
        message: <CrewShiftRemoveConfirmation crewShift={crewShift} />,
        actionLabel: t("remove"),
        actionOK: () => {
          onRemoveCrewShift(deepCopy(crewShift));
          setCrewShiftToEdit(null);
          logAnalyticsEvent(
            dispatch,
            ANALYTICS_EVENTS.CREW_SHIFTS_REMOVE_CREW_SHIFT
          );
        },

        actionCancel: () => {
          dispatch({
            type: "setConfirmation",
            confirmation: null,
          });
        },
      },
    });
  }

  return (
    <>
      {
        /** Read mode **/
        isNullOrUndefined(crewShiftToEdit) && (
          <div
            className={`crew-shifts-module-row${
              !isEditable ? " read-only" : ""
            }`}
          >
            <div>
              <CrewInfo
                user={crewShift.userInfo.user}
                details={t(crewShift.userInfo.dispatchStatus)}
                mapStatus={crewShift.userInfo.mapStatus}
              />
            </div>
            <div>
              <div className="crew-shifts-date">
                <div>{`${formatTime(
                  crewShift.startTime,
                  airportTimezone
                )} - ${formatTime(
                  crewShift.endTime,
                  airportTimezone,
                  true,
                  crewShift.startTime
                )}`}</div>
                <div>{formatDate(crewShift.startTime, airportTimezone)}</div>
              </div>
            </div>
            <div>
              {!isNullOrUndefined(crewShift.departmentName) &&
                crewShift.departmentName}
            </div>
            <div className="actions">
              <div>
                <button
                  className="danger-outline"
                  onClick={() => {
                    handleRemove(crewShift);
                  }}
                  disabled={!isEditable}
                >
                  {t("remove")}
                </button>
              </div>
              <div>
                <button
                  className="outlined"
                  onClick={() => {
                    const crewShiftEditable = deepCopy(crewShift);
                    if (crewShiftEditable) {
                      crewShiftEditable.startTime = roundToNearestFiveMinute(
                        crewShiftEditable.startTime
                      );
                      crewShiftEditable.endTime = roundToNearestFiveMinute(
                        crewShiftEditable.endTime
                      );
                    }
                    setCrewShiftToEdit(crewShiftEditable);
                    onSelectCrewShift(crewShift.uuid);
                    logAnalyticsEvent(
                      dispatch,
                      ANALYTICS_EVENTS.CREW_SHIFTS_EDIT_CREW_SHIFT
                    );
                  }}
                  disabled={!isEditable}
                >
                  {t("edit")}
                </button>
              </div>
            </div>
          </div>
        )
      }
      {
        /** Edit mode **/
        !isNullOrUndefined(crewShiftToEdit) && (
          <div className="crew-shifts-module-row selected">
            <div>
              <CrewInfo
                user={crewShift.userInfo.user}
                details={t(crewShift.userInfo.dispatchStatus)}
                mapStatus={crewShift.userInfo.mapStatus}
              />
            </div>
            <div>
              <div>
                <TimeRangeInput
                  onTimeRangeChange={(timerangeValues) => {
                    const updatedCrewShift = deepCopy(crewShiftToEdit);
                    updatedCrewShift.startTime = timerangeValues.startTime;
                    updatedCrewShift.endTime = timerangeValues.endTime;
                    setCrewShiftToEdit(updatedCrewShift);
                  }}
                  timeRangeValues={{
                    startTime: crewShiftToEdit.startTime,
                    endTime: crewShiftToEdit.endTime,
                  }}
                  timezone={airportTimezone}
                />
              </div>
            </div>
            <div>
              <div>
                {!isEmptyList(departments) && (
                  <select
                    className="departments-input"
                    style={selectInputFix}
                    value={
                      !isNullOrUndefined(crewShiftToEdit.departmentUuid)
                        ? crewShiftToEdit.departmentUuid
                        : ""
                    }
                    onChange={(e) => {
                      const crewShiftUpdated = deepCopy(crewShiftToEdit);
                      crewShiftUpdated.departmentUuid = e.target.value;
                      setCrewShiftToEdit(crewShiftUpdated);
                    }}
                  >
                    {departments &&
                      departments.map((department) => (
                        <option
                          value={department.uuid}
                          key={makeKeyFriendlyString(department.uuid)}
                        >
                          {department.name}
                        </option>
                      ))}
                  </select>
                )}
              </div>
            </div>
            <div className="actions">
              <div>
                <button
                  className="outlined"
                  onClick={() => {
                    setCrewShiftToEdit(null);
                    onSelectCrewShift(null);
                  }}
                >
                  {t("cancel")}
                </button>
              </div>
              <div>
                <button
                  className="primary"
                  onClick={() => {
                    onSaveCrewShift(crewShiftToEdit);
                    setCrewShiftToEdit(null);
                    logAnalyticsEvent(
                      dispatch,
                      ANALYTICS_EVENTS.CREW_SHIFTS_SAVE_CREW_SHIFT
                    );
                  }}
                >
                  {t("save")}
                </button>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
}

function CrewShiftRemoveConfirmation(props) {
  const { crewShift } = props;
  const { t } = useTranslation();
  return (
    <div>
      <div className="confirmation-detail">
        <div>{t("remove_crew_shift")}:</div>
      </div>
      <div>
        <span>{crewShift.userInfo.fullName}</span>
      </div>
    </div>
  );
}
export default CrewShiftsRow;
