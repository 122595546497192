import { useMemo, useState } from "react";
import moment from "moment-timezone";
import {
  deepCopy,
  formatHoursMins,
  getByFieldValue,
  isEmptyList,
  isNullOrUndefined,
  sortByDateField,
} from "../../utils";
import { useTranslation } from "react-i18next";
import TurnaroundModuleTimestamp from "./TurnaroundModuleTimestamp";
import { getNameKeyForOperationOffsetType } from "../../turnaroundUtils";
import TurnaroundModuleTimestampModal from "./TurnaroundModalTimestampModal";
import {
  ResourceActions,
  userHasAnyResourceAction,
} from "../../resourceActionsUtils";
import { useMainContext } from "../../MainContext";
import { getUserInfo } from "../../userUtils";

function TurnaroundModuleSummary(props) {
  const { t } = useTranslation();
  const mainContext = useMainContext();
  const { selectedTurnaroundInfo, timezone, onSelectOperation } = props;
  const [selectedOperationToEdit, setSelectedOperationToEdit] = useState(null);
  const { currentUser, users } = mainContext;

  const operationsToRender = useMemo(() => {
    const operationsList = [];
    const turnaroundProfile = !isNullOrUndefined(
      selectedTurnaroundInfo?.originalMonitor
    )
      ? selectedTurnaroundInfo?.originalMonitor
      : selectedTurnaroundInfo?.originalProfile;
    if (!isEmptyList(turnaroundProfile?.turnaroundOperations)) {
      const operations = turnaroundProfile.turnaroundOperations;
      sortByDateField(operations, "expectedStartTime", false, ["type.name"]);

      if (!isEmptyList(operations)) {
        for (let i = 0; i < operations.length; i++) {
          const operation = operations[i];
          const requirementSummaryList = [];
          const requirements = operation.turnaroundRequirements;

          for (let j = 0; j < requirements.length; j++) {
            const requirement = requirements[j];
            const groundVehicleTypeUuid =
              requirement.certification.groundVehicleTypeUuid;

            const isGseRequirement = !isNullOrUndefined(groundVehicleTypeUuid);

            const summaryRow = {
              uuid: requirement.uuid,
              name: requirement.certification.name,
              gseQuantity: isGseRequirement ? requirement.quantity : 0,
              crewQuantity: requirement.quantity,
              crewAssignments: requirement.crewAssignments,
            };

            requirementSummaryList.push(summaryRow);
          }

          if (operation.enabled) {
            operationsList.push({
              uuid: operation.uuid,
              name: operation.type.name,
              offsetTypeId: operation.offsetTypeId,
              requirements: requirementSummaryList,
              expectedStartTime: operation.expectedStartTime,
              expectedEndTime: operation.expectedEndTime,
              startedAt: operation.startedAt,
              completedAt: operation.completedAt,
              isLeadOperation: operation.type.isLeadOperation,
              _source: operation,
            });
          }
        }
      }
    }
    return operationsList;
  }, [selectedTurnaroundInfo]);

  const hasEditTurnProfile = userHasAnyResourceAction(
    currentUser,
    ResourceActions.EditTurnProfile
  );

  const isTimestampsReadonly = !hasEditTurnProfile;
  return (
    <div>
      <div className="turnarounds-module-summary">
        <div className="turnarounds-module-summary-header">
          <div>
            <div>{t("operation")}</div>
          </div>
          <div>
            <div>{t("expected")}</div>
          </div>
          <div>
            <div>{t("actual")}</div>
          </div>
          <div>
            <div>{t("duration")}</div>
          </div>
          <div>
            <div>{t("resources")}</div>
          </div>
        </div>
        {operationsToRender &&
          !isEmptyList(operationsToRender) &&
          operationsToRender.map((operation) => (
            <div
              className="turnarounds-module-summary-grouping"
              data-operation-uuid={operation.uuid}
              onClick={() => onSelectOperation(operation.uuid)}
              key={operation.uuid}
            >
              <OperationRequirementRow
                operation={operation}
                timezone={timezone}
                isPrimaryRow={true}
                isReadonly={isTimestampsReadonly}
                onEditActualTimestamp={() => {
                  const operationForEdit = deepCopy(operation._source);
                  setSelectedOperationToEdit(operationForEdit);
                }}
                users={users}
              />
            </div>
          ))}
      </div>
      {!isNullOrUndefined(selectedOperationToEdit) && (
        <div>
          <TurnaroundModuleTimestampModal
            selectedTurnaroundUuid={selectedTurnaroundInfo?.uuid}
            operation={selectedOperationToEdit}
            onClose={() => {
              setSelectedOperationToEdit(null);
            }}
            onSave={() => {
              setSelectedOperationToEdit(null);
            }}
            timezone={timezone}
          />
        </div>
      )}
    </div>
  );
}

function OperationName(props) {
  const { t } = useTranslation();
  const { operation } = props;
  const operationStatusClass =
    !isNullOrUndefined(operation?.startedAt) &&
    !isNullOrUndefined(operation?.completedAt)
      ? " complete"
      : " incomplete";
  const operationOffsetType = t(
    getNameKeyForOperationOffsetType(operation.offsetTypeId)
  );

  return (
    <div className="operation-name">
      <div className="operation-name-status">
        <div
          className={`turnaround-page-indicator${operationStatusClass}`}
        ></div>
      </div>
      <div className="operation-name-content">
        <div>{operation.name}</div>
        <div className="operation-name-details">
          <div>{operationOffsetType}</div>
        </div>
      </div>
    </div>
  );
}

function OperationRequirementRow(props) {
  const { t } = useTranslation();
  const {
    operation,
    timezone,
    isPrimaryRow,
    isReadonly,
    onEditActualTimestamp,
    users,
  } = props;

  const mStartTime = !isNullOrUndefined(operation.startedAt)
    ? moment(operation.startedAt).startOf("minute")
    : null;
  const mEndTime = !isNullOrUndefined(operation.completedAt)
    ? moment(operation.completedAt).startOf("minute")
    : null;
  const totalDuration =
    !isNullOrUndefined(mStartTime) && !isNullOrUndefined(mEndTime)
      ? mEndTime.diff(mStartTime, "minutes")
      : null;

  return (
    <div className="turnarounds-module-summary-row">
      <div>{isPrimaryRow && <OperationName operation={operation} />}</div>
      <div>
        <TurnaroundModuleTimestamp
          operation={operation}
          timezone={timezone}
          mode={"expected"}
          readonly={true}
        />
      </div>
      <div>
        <TurnaroundModuleTimestamp
          operation={operation}
          timezone={timezone}
          mode={"actual"}
          onClick={() => {
            onEditActualTimestamp();
          }}
          readonly={isReadonly}
          showMissingValue={true}
        />
      </div>
      <div>
        {!isNullOrUndefined(totalDuration)
          ? formatHoursMins(totalDuration)
          : ""}
      </div>
      <div>
        <div className="turnarounds-module-summary-listing">
          {isEmptyList(operation.requirements) && <div>{t("na")}</div>}
          {!isEmptyList(operation.requirements) &&
            operation.requirements.map((requirement) => (
              <div key={requirement.uuid}>
                <div className="turnarounds-module-summary-label">
                  {requirement.name}
                </div>
                {operation.isLeadOperation &&
                  requirement.crewAssignments.map((crewAssignment) => {
                    const assignedUser = getByFieldValue(
                      users,
                      "uuid",
                      crewAssignment.userUuid
                    );
                    const userInfo = !isNullOrUndefined(assignedUser)
                      ? getUserInfo(assignedUser)
                      : null;
                    return (
                      !isNullOrUndefined(userInfo) && (
                        <div
                          key={userInfo.uuid}
                          className="turnarounds-module-summary-user"
                        >
                          {userInfo.fullName}
                        </div>
                      )
                    );
                  })}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default TurnaroundModuleSummary;
