import moment from "moment-timezone";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAirportDetailByUuid,
  getGseTypes,
  getTurnaroundDetails,
  getTurnaroundsSummaryExport,
  getTurnaroundsSummarySearch,
  getUsers,
  getVehicles,
} from "../../api";
import { ReactComponent as Download } from "../../assets/download.svg";
import {
  ANALYTICS_EVENTS,
  MIN_DATE,
  POLLING_INTERVALS,
  SEARCH_QUERY_PLACEHOLDERS,
  TurnaroundsPageMode,
} from "../../constants";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { MAPINFO_MARKER_TYPE } from "../../mapUtils";
import {
  ResourceActions,
  userHasAnyResourceAction,
} from "../../resourceActionsUtils";
import { SettingNames, SettingsManager } from "../../SettingsManager";
import {
  getTaskInfoForTurnaround,
  getTurnaroundInfo,
  getTurnaroundInfoForTurnaroundSummary,
} from "../../turnaroundUtils";
import { getUserInfo } from "../../userUtils";
import {
  deepCopy,
  formatLongDate,
  formatLongDatetime,
  getByFieldValue,
  getTimezoneFromUser,
  isBlank,
  isEmptyList,
  isNullOrUndefined,
  logAnalyticsEvent,
  sortByField,
} from "../../utils";
import ButtonDatePicker from "../ButtonDatePicker";
import ButtonSwitch from "../ButtonSwitch";
import DataTable from "../DataTable";
import ChatButton from "../DataTable/ChatButton";
import { TurnaroundsModuleColumns } from "../DataTable/TurnaroundsModuleColumns";
import LoadingIndicator from "../LoadingIndicator";
import TitleBadge from "../TitleBadge";
import AssignLeadsModal from "./AssignLeadsModal";
import "./styles.css";
import TaskInfoFilters from "./TaskInfoFilters";
import TurnaroundEditModal from "./TurnaroundEditModal";
import TurnaroundModuleServiceTimeline from "./TurnaroundModuleServiceTimeline";
import TurnaroundsModuleConfig from "./TurnaroundsModuleConfig";

function TurnaroundsModule(props) {
  const { subPath } = props;
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const navigate = useNavigate();
  const { turnaroundUuid } = useParams();

  const {
    currentUser,
    turnaroundsSummarySearch,
    turnaroundsSummarySearchSaving,
    turnaroundDetails,
    turnaroundDetailsLoading,
    searchQuery,
    vehicles,
    gseTypes,
    users,
    localSettings,
    turnaroundForAssignment,
    turnaroundsSummaryExport,
    turnaroundsSummaryExportSaving,
    isRefreshPaused,
  } = mainContext;
  const airportTimezone = getTimezoneFromUser(currentUser);
  const mToday = moment().tz(airportTimezone).startOf("day");
  const mNow = moment().tz(airportTimezone);
  const turnaroundsPageMode = !isNullOrUndefined(localSettings)
    ? localSettings[SettingNames.TURNAROUNDS_PAGE_MODE.name]
    : SettingsManager.getSetting(SettingNames.TURNAROUNDS_PAGE_MODE.name);
  const isActiveMode = TurnaroundsPageMode.ACTIVE === turnaroundsPageMode;
  const [selectedDate, setSelectedDate] = useState(mToday);
  const [selectedTurnaroundInfo, setSelectedTurnaroundInfo] = useState(null);
  const [selectedTurnaroundUuidForEdit, setSelectedTurnaroundUuidForEdit] =
    useState(null); // For popup editing
  const [taskInfoList, setTaskInfoList] = useState(null);
  const [taskInfoFilters, setTaskInfoFilters] = useState({
    showOperations: true,
    showUsers: false,
    showGse: false,
  });
  const [refreshSelectedContextRequested, setRefreshSelectedContextRequested] =
    useState(false);
  const [
    refreshTurnaroundsSummaryRequested,
    setRefreshTurnaroundsSummaryRequested,
  ] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const hasEditTurnProfile = userHasAnyResourceAction(
    currentUser,
    ResourceActions.EditTurnProfile
  );
  const hasEditTurnarounds = userHasAnyResourceAction(
    currentUser,
    ResourceActions.EditTurnarounds
  );

  // Pemission to use the edit Turnaround modal must have all of these
  const hasTurnaroundEditModal = hasEditTurnProfile || hasEditTurnarounds;
  function handleRowClick(item) {
    setSelectedTurnaroundUuidForEdit(item.uuid);
    logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.TURNAROUNDS_EDIT_MODAL);
  }

  let rowActions = {
    chatAction: (item) => {
      return (
        <div
          onClick={(e) => {
            navigate(`/turnarounds/${item.uuid}/chat`);
            logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.TURNAROUNDS_VIEW_CHAT);
            e.stopPropagation();
          }}
        >
          <ChatButton turnaroundUuid={item.uuid} />
        </div>
      );
    },
  };

  rowActions.viewAction = (item) => {
    if (item.isCompleted) {
      return (
        <button
          className="primary"
          onClick={(e) => {
            navigate(`/turnarounds/${item.uuid}`);
            logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.TURNAROUNDS_CONFIGURE);
            e.stopPropagation();
          }}
        >
          {t("view_details")}
        </button>
      );
    } else {
      return (
        <button
          className="alternate"
          onClick={(e) => {
            navigate(`/turnarounds/${item.uuid}`);
            logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.TURNAROUNDS_CONFIGURE);
            e.stopPropagation();
          }}
        >
          {t("configure")}
        </button>
      );
    }
  };

  // Load supporting data
  useEffect(() => {
    if (!isNullOrUndefined(currentUser)) {
      getAirportDetailByUuid(dispatch, { uuid: currentUser?.airport.uuid });
    }
  }, [dispatch, currentUser]);

  useEffect(() => {
    // Load supporting data
    getGseTypes(dispatch);
    getVehicles(dispatch);
    getUsers(dispatch);
  }, [dispatch]);

  useEffect(() => {
    // Load required data
    setIsLoadingData(true);
    dispatch({
      type: "setTurnaroundsSummarySearch",
      value: null,
    });

    if (isActiveMode) {
      const mNow = moment().tz(airportTimezone);
      async function loadData() {
        await getTurnaroundsSummarySearch(dispatch, {
          endTime: mNow.add(12, "hours").toDate().toISOString(),
          includeOpen: true,
          includeClosed: false,
        });
        setIsLoadingData(false);
      }
      loadData();
    } else {
      const start = moment(selectedDate).toDate().toISOString();
      const end = moment(selectedDate).endOf("day").toDate().toISOString();
      async function loadData() {
        await getTurnaroundsSummarySearch(dispatch, {
          startTime: start,
          endTime: end,
          includeOpen: false,
          includeClosed: true,
        });
        setIsLoadingData(false);
      }
      loadData();
    }
  }, [dispatch, selectedDate, isActiveMode, airportTimezone]);

  function handleReloadTurnarounds() {
    if (isActiveMode) {
      const mNow = moment().tz(airportTimezone);
      getTurnaroundsSummarySearch(dispatch, {
        endTime: mNow.add(12, "hours").toDate().toISOString(),
        includeOpen: true,
        includeClosed: false,
      });
    } else {
      const start = moment(selectedDate).toDate().toISOString();
      const end = moment(selectedDate).endOf("day").toDate().toISOString();
      getTurnaroundsSummarySearch(dispatch, {
        startTime: start,
        endTime: end,
        includeOpen: false,
        includeClosed: true,
      });
    }
  }

  useEffect(() => {
    // Load turnaround details
    if (!isNullOrUndefined(turnaroundUuid)) {
      getTurnaroundDetails(dispatch, { uuid: turnaroundUuid });
    } else {
      dispatch({
        type: "setTurnaroundDetails",
        value: null,
      });
    }
  }, [dispatch, turnaroundUuid]);

  // Refresh TurnarnoundsSummary (list)
  useEffect(() => {
    if (isRefreshPaused) return () => {};
    if (turnaroundsSummarySearchSaving) return () => {};
    if (!refreshTurnaroundsSummaryRequested) return () => {};
    if (isActiveMode) {
      const mNow = moment().tz(airportTimezone);
      const end = moment(mNow).add(12, "hours").toDate().toISOString();
      getTurnaroundsSummarySearch(dispatch, {
        endTime: end,
        includeOpen: true,
        includeClosed: false,
      });
    }
    setRefreshTurnaroundsSummaryRequested(false);
  }, [
    dispatch,
    turnaroundsSummarySearchSaving,
    refreshTurnaroundsSummaryRequested,
    isRefreshPaused,
    isActiveMode,
    airportTimezone,
    selectedDate,
  ]);

  // Refresh turnaround details
  useEffect(() => {
    if (!refreshSelectedContextRequested) return () => {};
    if (
      isNullOrUndefined(selectedTurnaroundInfo) ||
      selectedTurnaroundInfo.isCompleted
    )
      return () => {};

    // Load turnaround details
    getTurnaroundDetails(dispatch, { uuid: selectedTurnaroundInfo.uuid });
    setRefreshSelectedContextRequested(false);

    // This fixes the pulsing animation syncronization issue
    const animations = document.getAnimations();
    if (!isEmptyList(animations)) {
      animations.forEach((i) => (i.currentTime = 0));
    }
  }, [dispatch, refreshSelectedContextRequested, selectedTurnaroundInfo]);

  // Refresh intervals
  useEffect(() => {
    const intervals = [];
    // Interval for refreshing the selected turnaround
    if (POLLING_INTERVALS.TURNAROUNDS > 0) {
      const interval = setInterval(() => {
        setRefreshSelectedContextRequested(true);
      }, POLLING_INTERVALS.TURNAROUNDS);
      intervals.push(interval);
    }
    // Interval for refreshing the list of turnarounds
    if (POLLING_INTERVALS.TURNAROUNDS_SUMMARY > 0) {
      const interval = setInterval(() => {
        setRefreshTurnaroundsSummaryRequested(true);
      }, POLLING_INTERVALS.TURNAROUNDS_SUMMARY);
      intervals.push(interval);
    }
    return () => {
      for (let i = 0; i < intervals.length; i++) {
        clearInterval(intervals[i]);
      }
    };
  }, []);

  useEffect(() => {
    dispatch({
      type: "setSearchQuery",
      value: {
        searchTerm: "",
        searchPlaceholder: SEARCH_QUERY_PLACEHOLDERS.SEARCH_TURNAROUNDS,
      },
    });
  }, [dispatch]);

  const [dataList] = useMemo(() => {
    const resultsToList = [];
    if (!isEmptyList(turnaroundsSummarySearch?.turnarounds)) {
      for (let i = 0; i < turnaroundsSummarySearch.turnarounds.length; i++) {
        const turnaround = turnaroundsSummarySearch.turnarounds[i];

        const turnaroundInfo =
          getTurnaroundInfoForTurnaroundSummary(turnaround);

        // Include the turnaround only if it matches the current page mode
        if (
          (isActiveMode && isNullOrUndefined(turnaroundInfo.closedAt)) ||
          (!isActiveMode && !isNullOrUndefined(turnaroundInfo.closedAt))
        ) {
          // Lookup for each lead user
          const leadsUserInfo = [];
          if (!isEmptyList(turnaround?.leadsUuids)) {
            turnaround?.leadsUuids.forEach((i) => {
              const user = getByFieldValue(users, "uuid", i);
              const userInfo = !isNullOrUndefined(user)
                ? getUserInfo(user)
                : null;
              if (!isNullOrUndefined(userInfo)) {
                leadsUserInfo.push(userInfo);
              }
            });
          }
          sortByField(leadsUserInfo, "fullName");
          resultsToList.push({
            ...turnaroundInfo,
            timezone: airportTimezone,
            showPlusDays: isActiveMode,
            showDate: !isActiveMode,
            leads: leadsUserInfo,
          });
        }
      }
    }
    return [resultsToList];
  }, [turnaroundsSummarySearch, airportTimezone, users, isActiveMode]);

  useEffect(() => {
    if (isNullOrUndefined(turnaroundUuid)) {
      setSelectedTurnaroundInfo(null);
    } else {
      if (isNullOrUndefined(turnaroundDetails) || isNullOrUndefined(vehicles))
        return () => {};
      const turnaroundInfoToRender = !isNullOrUndefined(turnaroundDetails)
        ? getTurnaroundInfo(turnaroundDetails)
        : null;
      const taskInfoForTurnaround = !isNullOrUndefined(turnaroundInfoToRender)
        ? getTaskInfoForTurnaround(
            turnaroundInfoToRender,
            vehicles,
            taskInfoFilters
          )
        : null;

      setSelectedTurnaroundInfo(turnaroundInfoToRender);
      setTaskInfoList(taskInfoForTurnaround);
    }
  }, [turnaroundUuid, turnaroundDetails, vehicles, taskInfoFilters]);

  useEffect(() => {
    if (!isNullOrUndefined(turnaroundsSummaryExport)) {
      if (!isNullOrUndefined(turnaroundsSummaryExport.downloadUrl)) {
        window.open(turnaroundsSummaryExport.downloadUrl, "_blank");
      }
      dispatch({
        type: "setTurnaroundsSummaryExport",
        value: null,
      });
      setIsProcessing(false);
      logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.TURNAROUNDS_EXPORT_CSV);
    }
  }, [dispatch, turnaroundsSummaryExport]);

  function handleExportClick() {
    if (!turnaroundsSummaryExportSaving && !isProcessing) {
      setIsProcessing(true);
      if (isActiveMode) {
        const mNow = moment().tz(airportTimezone);
        const start = moment(mNow).add(-6, "hours").toDate().toISOString();
        const end = moment(mNow).add(24, "hours").toDate().toISOString();
        getTurnaroundsSummaryExport(dispatch, start, end);
      } else {
        const start = moment(selectedDate).toDate().toISOString();
        const end = moment(selectedDate).endOf("day").toDate().toISOString();
        getTurnaroundsSummaryExport(dispatch, start, end);
      }
    }
  }

  const showDetails =
    isBlank(subPath) && !isNullOrUndefined(selectedTurnaroundInfo);
  const showTimeline =
    subPath === "timeline" && !isNullOrUndefined(selectedTurnaroundInfo);
  const showChat =
    subPath === "chat" && !isNullOrUndefined(selectedTurnaroundInfo);
  const showTurnaroundsList =
    isNullOrUndefined(turnaroundUuid) &&
    isNullOrUndefined(selectedTurnaroundInfo);

  const turnaroundPath = showDetails || showTimeline || showChat;
  const isExportDisabled = isProcessing || turnaroundsSummaryExportSaving;

  return (
    <div className="turnarounds-module">
      <div
        className={`turnarounds-module-header${
          turnaroundPath ? " sub-path" : ""
        }`}
      >
        <div className="turnarounds-module-header-sections">
          <div className="titles">
            {showTurnaroundsList && (
              <>
                <h3 className="badged">
                  {t("turnarounds")}
                  {!isLoadingData && <TitleBadge value={dataList?.length} />}
                </h3>
                <div>
                  {!isActiveMode && (
                    <span className="capitalized">
                      {formatLongDate(selectedDate, airportTimezone)}
                      {selectedDate.isSame(mToday) && (
                        <>&nbsp;&middot;&nbsp;{t("today")}</>
                      )}
                    </span>
                  )}
                  {isActiveMode && (
                    <span className="capitalized">
                      {formatLongDatetime(mNow, airportTimezone)}
                    </span>
                  )}
                </div>
              </>
            )}
            {turnaroundPath && (
              <>
                <h3>
                  {t("turnaround")}: {selectedTurnaroundInfo.combinedFlightName}
                </h3>
                <div>
                  <span className="capitalized">
                    {formatLongDate(
                      selectedTurnaroundInfo.resolvedInboundTimes.displayTime,
                      airportTimezone
                    )}
                  </span>
                </div>
              </>
            )}
          </div>
          <div>
            {!isNullOrUndefined(selectedTurnaroundInfo) && showTimeline && (
              <TaskInfoFilters
                taskInfoFilters={taskInfoFilters}
                taskInfoList={taskInfoList}
                onClick={(filterVal) => {
                  const updatedVal = deepCopy(taskInfoFilters);
                  if (filterVal === "operations") {
                    updatedVal.showOperations = !updatedVal.showOperations;
                  } else if (filterVal === "gse") {
                    updatedVal.showGse = !updatedVal.showGse;
                  } else if (filterVal === "users") {
                    updatedVal.showUsers = !updatedVal.showUsers;
                  }
                  setTaskInfoFilters(updatedVal);
                }}
              />
            )}
          </div>
          <div className="actions">
            {!isNullOrUndefined(selectedTurnaroundInfo) && (
              <div>
                {selectedTurnaroundInfo.isCompleted && (
                  <ButtonSwitch
                    selectedOption={showTimeline ? "timeline" : "details"}
                    labelValuePairs={[
                      {
                        value: "details",
                      },
                      {
                        value: "timeline",
                      },
                    ]}
                    onChange={(mode) => {
                      if (mode === "details") {
                        logAnalyticsEvent(
                          dispatch,
                          ANALYTICS_EVENTS.TURNAROUNDS_VIEW_DETAILS
                        );
                        navigate(`/turnarounds/${selectedTurnaroundInfo.uuid}`);
                      } else if (mode === "timeline") {
                        logAnalyticsEvent(
                          dispatch,
                          ANALYTICS_EVENTS.TURNAROUNDS_VIEW_TIMELINE
                        );
                        navigate(
                          `/turnarounds/${selectedTurnaroundInfo.uuid}/timeline`
                        );
                      }
                    }}
                  />
                )}
                {!selectedTurnaroundInfo.isCompleted && (
                  <button
                    className="secondary"
                    onClick={() => {
                      setTimeout(() => {
                        dispatch({
                          type: "setShowOnMap",
                          value: {
                            markerType: MAPINFO_MARKER_TYPE.AIRCRAFT,
                            item: selectedTurnaroundInfo,
                          },
                        });
                      }, 250);
                      navigate("/map");
                      logAnalyticsEvent(
                        dispatch,
                        ANALYTICS_EVENTS.TURNAROUNDS_DETAILS_SHOW_ON_MAP
                      );
                    }}
                  >
                    {t("show_in_map")}
                  </button>
                )}
              </div>
            )}

            {showTurnaroundsList && (
              <div className="actions">
                <div>
                  <button
                    className="secondary"
                    onClick={handleExportClick}
                    disabled={isExportDisabled}
                  >
                    <Download />
                    {t("download_csv")}
                  </button>
                </div>
                <div>
                  <ButtonSwitch
                    selectedOption={isActiveMode ? "open" : "closed"}
                    labelValuePairs={[
                      {
                        value: "open",
                      },
                      {
                        value: "closed",
                      },
                    ]}
                    onChange={(mode) => {
                      if (mode === "open") {
                        SettingsManager.setSetting(
                          SettingNames.TURNAROUNDS_PAGE_MODE.name,
                          TurnaroundsPageMode.ACTIVE
                        );
                      } else if (mode === "closed") {
                        SettingsManager.setSetting(
                          SettingNames.TURNAROUNDS_PAGE_MODE.name,
                          TurnaroundsPageMode.COMPLETED
                        );
                      }
                      SettingsManager.updateState(dispatch);
                    }}
                  />
                </div>
                {!isActiveMode && (
                  <div>
                    <ButtonDatePicker
                      label={
                        selectedDate == null
                          ? null
                          : selectedDate.format("MM/DD/YYYY")
                      }
                      value={selectedDate}
                      onChange={(newValue) => {
                        setSelectedDate(newValue);
                      }}
                      minDate={moment(MIN_DATE)}
                      maxDate={moment(mToday).add(2, "month")}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {showTimeline && (
        <div className="turnarounds-module-container tasks">
          {turnaroundDetailsLoading && (
            <div>
              <LoadingIndicator />
            </div>
          )}
          {!turnaroundDetailsLoading && (
            <TurnaroundModuleServiceTimeline
              taskInfoList={taskInfoList}
              taskInfoFilters={taskInfoFilters}
              turnaroundInfo={selectedTurnaroundInfo}
              timezone={airportTimezone}
            />
          )}
        </div>
      )}
      {(showDetails || showChat) && (
        <div className="turnarounds-module-container config">
          {isNullOrUndefined(selectedTurnaroundInfo) &&
            turnaroundDetailsLoading && (
              <div>
                <LoadingIndicator />
              </div>
            )}
          {!isNullOrUndefined(selectedTurnaroundInfo) && (
            <TurnaroundsModuleConfig
              turnaroundInfo={selectedTurnaroundInfo}
              timezone={airportTimezone}
              gseTypes={gseTypes}
              showChat={showChat}
            />
          )}
        </div>
      )}
      {showTurnaroundsList && (
        <div className="turnarounds-module-container">
          {isLoadingData && (
            <div className="empty-state">
              <LoadingIndicator />
            </div>
          )}
          {!isLoadingData && (
            <DataTable
              initialSort={TurnaroundsModuleColumns.ScheduledIn.id}
              dataList={dataList}
              columnNames={[
                TurnaroundsModuleColumns.TurnaroundName,
                TurnaroundsModuleColumns.TurnaroundOperationProgress,
                TurnaroundsModuleColumns.Registration,
                TurnaroundsModuleColumns.ScheduledIn,
                TurnaroundsModuleColumns.EstimatedIn,
                TurnaroundsModuleColumns.ActualIn,
                TurnaroundsModuleColumns.ScheduledOut,
                TurnaroundsModuleColumns.EstimatedOut,
                TurnaroundsModuleColumns.ActualOut,
                TurnaroundsModuleColumns.Stand,
                TurnaroundsModuleColumns.Leads,
                TurnaroundsModuleColumns.Remarks,
              ]}
              rowClass={(item) => {
                if (!item.hasValidTurnaroundTime) {
                  return " status-invalid";
                }
                return "";
              }}
              rowActions={rowActions}
              rowClick={hasTurnaroundEditModal ? handleRowClick : null}
              gridColumns={
                "minmax(140px, 240px) 80px 96px repeat(6, minmax(0,96px)) 80px minmax(96px, 180px) minmax(120px, 1fr) 140px"
              }
              gridColumnsLarge={
                "minmax(140px, 240px) 96px 96px repeat(6, minmax(0,96px)) minmax(0,120px) minmax(180px,1fr) minmax(180px, 1fr) 140px"
              }
              searchQuery={searchQuery}
            />
          )}
          <TurnaroundEditModal
            selectedTurnaroundUuid={selectedTurnaroundUuidForEdit}
            onCloseHandler={() => {
              setSelectedTurnaroundUuidForEdit(null);
            }}
            onSaved={() => {
              handleReloadTurnarounds();
            }}
            timezone={airportTimezone}
          />
          {!isNullOrUndefined(turnaroundForAssignment?.uuid) && (
            <AssignLeadsModal
              selectedTurnaroundUuid={turnaroundForAssignment?.uuid}
              onCloseHandler={() => {
                dispatch({
                  type: "setTurnaroundForAssignment",
                  value: null,
                });
              }}
              onSaved={() => {
                handleReloadTurnarounds();
              }}
              timezone={airportTimezone}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default TurnaroundsModule;
