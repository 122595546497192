import { Modal } from "@mui/material";

import { ReactComponent as Cross } from "../../assets/cross.svg";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import DateTimeInput from "../DateTimeInput";

function PlaybackTimeSelectorModal(props) {
  const { isOpen, onClose, startTime, onChange, timezone, allowClose } = props;
  const { t } = useTranslation();
  const [startTimeToEdit, setStartTimeToEdit] = useState(startTime);

  function handleClose() {
    if (allowClose) {
      onClose();
    }
  }
  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <div className="modal playback-time-selector-modal modal-small">
          <div>
            <div className="modal-header">
              <div>
                <h2>{t("Select time")}</h2>
              </div>
              {allowClose && (
                <div className="button-icon" onClick={handleClose}>
                  <Cross />
                </div>
              )}
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-container">
              <div className="playback-time-selector">
                <div className="playback-time-selector-row">
                  <div>
                    <label>Time:</label>
                  </div>
                  <div>
                    <DateTimeInput
                      timeValue={startTimeToEdit}
                      onChange={(updatedStartTime) => {
                        setStartTimeToEdit(updatedStartTime);
                      }}
                      timezone={timezone}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="modal-footer">
              <div>
                {allowClose && (
                  <button className="secondary" onClick={handleClose}>
                    {t("cancel")}
                  </button>
                )}
              </div>
              <div>
                <button
                  onClick={() => {
                    onChange(startTimeToEdit);
                  }}
                >
                  {t("Confirm")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default PlaybackTimeSelectorModal;
