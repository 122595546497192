import { Modal } from "@mui/material";
import { isBlank, isNullOrUndefined } from "../../utils";
import { ReactComponent as Close } from "../../assets/close-white-circle.svg";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useChatDispatchContext } from "../../ChatContext";

function ChatAttachmentModal(props) {
  const { t } = useTranslation();
  const { attachment, mediaSignatures } = props;
  const chatDispatch = useChatDispatchContext();
  const downloadLinkRef = useRef(null);

  function handleClose() {
    chatDispatch({
      type: "setSelectedAttachment",
      value: null,
    });
  }
  const dimensions = attachment?.dimensions;
  const width = dimensions?.length > 0 ? dimensions[0] : 0;
  const height = dimensions?.length > 1 ? dimensions[1] : 0;
  // Scale image to a specific width
  const fullSizeMediaSignature =
    mediaSignatures?.length > 0 ? mediaSignatures[0].signature : null;
  const attachmentUrl = !isNullOrUndefined(attachment?.fullSizeUrl)
    ? `${attachment?.fullSizeUrl}${fullSizeMediaSignature}`
    : "";

  const modalStyle = {
    backgroundImage: !isBlank(attachmentUrl) ? `url(${attachmentUrl})` : "",
    minWidth: "unset",
  };
  let ratio = 1;
  if (width > height) {
    ratio = (window.innerWidth * 0.8) / width;
    modalStyle.width = `${width * ratio}px`;
    modalStyle.height = `${height * ratio}px`;
    if (height * ratio > window.innerHeight * 0.8) {
      // additional adjustment needed
      ratio = (window.innerHeight * 0.8) / height;
      modalStyle.height = `${height * ratio}px`;
      modalStyle.width = `${width * ratio}px`;
    }
  } else {
    ratio = (window.innerHeight * 0.8) / height;
    modalStyle.height = `${height * ratio}px`;
    modalStyle.width = `${width * ratio}px`;
  }
  return (
    <div>
      <Modal
        open={!isNullOrUndefined(attachment)}
        onClose={handleClose}
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(2px)",
          },
        }}
      >
        <div className="modal chat-attachment" style={modalStyle}>
          <div className="chat-attachment-header">
            <div>
              <div></div>
              <div>
                <button
                  className="white"
                  onClick={() => {
                    // Note: file must be same origin to automatically download (this opens new tab)
                    downloadLinkRef.current.click();
                  }}
                >
                  {t("download")}
                </button>
                <a
                  href={attachmentUrl}
                  className="not-visible"
                  ref={downloadLinkRef}
                  target="_blank"
                  rel="noreferrer"
                  download={`${attachment.uuid}.jpg`}
                >
                  {attachmentUrl}
                </a>
              </div>
              <div className="close-button" onClick={handleClose}>
                <Close />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ChatAttachmentModal;
