import { Fade, Tooltip } from "@mui/material";
import { tooltipLightStyles } from "../../styleUtils";
import ProgressRing from "../ProgressRing";
import { isNullOrUndefined } from "../../utils";
import { useTranslation } from "react-i18next";

function TurnaroundOperationProgress(props) {
  const { item } = props;
  const { t } = useTranslation();
  const completedCount = !isNullOrUndefined(
    item?.operationSummary?.completedCount
  )
    ? item.operationSummary.completedCount
    : 0;
  const startedCount = !isNullOrUndefined(item?.operationSummary?.startedCount)
    ? item.operationSummary.startedCount - completedCount
    : 0;
  const enabledCount = !isNullOrUndefined(item?.operationSummary?.enabledCount)
    ? item.operationSummary.enabledCount
    : 0;

  const notCompletedCount = enabledCount - startedCount - completedCount;

  return (
    <div className="turnaround-operation-progress">
      <Tooltip
        title={
          <>
            {enabledCount > 0 && (
              <div className="tooltip-content turnaround-operation-progress-tooltip">
                {notCompletedCount > 0 && (
                  <div className="tooltip-row ">
                    <div>{t("incomplete")}</div>
                    <div>{notCompletedCount}</div>
                  </div>
                )}
                {startedCount > 0 && (
                  <div className="tooltip-row ">
                    <div>{t("started_non_cap")}</div>
                    <div>{startedCount}</div>
                  </div>
                )}
                {completedCount > 0 && (
                  <div className="tooltip-row ">
                    <div>{t("completed_non_cap")}</div>
                    <div>{completedCount}</div>
                  </div>
                )}
                {
                  <div className="tooltip-row total">
                    <div>{t("total")}</div>
                    <div>{enabledCount}</div>
                  </div>
                }
              </div>
            )}
            {enabledCount === 0 && (
              <div className="tooltip-content">{t("no_operations")}</div>
            )}
          </>
        }
        placement="top"
        componentsProps={tooltipLightStyles}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 500 }}
        enterDelay={1000}
        arrow
      >
        <div>
          <ProgressRing operationSummary={item.operationSummary} />
        </div>
      </Tooltip>
    </div>
  );
}

export default TurnaroundOperationProgress;
