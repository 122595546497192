import { useRef } from "react";
import moment from "moment-timezone";
import { formatTime, isEmptyList, isNullOrUndefined } from "../../utils";
import { ReactComponent as CaretDown } from "../../assets/playback/caret-down-fill.svg";

function PlaybackTimeline(props) {
  const timelineRef = useRef(null);
  const { startTime, endTime, currentTime, onSlide, timezone } = props;

  const tlStart = moment(startTime).tz(timezone).add(-1, "minute");
  const tlEnd = moment(endTime).tz(timezone).add(1, "minute");
  const tlDuration = moment.duration(tlEnd.diff(tlStart)).as("minutes");
  const tlBuckets = [];
  for (let i = 0; i < tlDuration; i++) {
    const timeVal = moment(tlStart).tz(timezone).add(i, "minute");
    tlBuckets.push(timeVal);
  }

  // Make the width of a minute equal to visible width
  const minuteInPixels =
    timelineRef?.current?.getBoundingClientRect().width / 2;
  const pixelInMinutes = 1 / minuteInPixels;

  const tlPositionInMinutes = !isNullOrUndefined(currentTime)
    ? moment.duration(moment(currentTime).diff(startTime)).as("minutes")
    : 0;
  const positionInPixels = tlPositionInMinutes * minuteInPixels;

  const sliderStyle = {
    width: `${tlDuration * minuteInPixels}px`,
    gridTemplateColumns: `repeat(${tlDuration}, 1fr)`,
    left: `-${positionInPixels}px`,
  };

  return (
    <div
      className="playback-timeline"
      ref={timelineRef}
      onMouseDown={(e) => {
        if (!isNullOrUndefined(timelineRef.current)) {
          timelineRef.current.classList.add("dragging");
          e.target.classList.add("dragging");
        }
      }}
      onMouseUp={(e) => {
        if (!isNullOrUndefined(timelineRef.current)) {
          timelineRef.current.classList.remove("dragging");
          e.target.classList.remove("dragging");
        }
      }}
      onMouseOut={(e) => {
        if (!isNullOrUndefined(timelineRef.current)) {
          timelineRef.current.classList.remove("dragging");
          e.target.classList.remove("dragging");
        }
      }}
      onMouseMove={(e) => {
        if (!isNullOrUndefined(timelineRef.current)) {
          if (timelineRef.current.classList.contains("dragging")) {
            const minuteDelta = -1 * e.movementX * pixelInMinutes;
            onSlide(minuteDelta);
          }
        }
      }}
    >
      <div className="playback-timeline-container">
        <div className="playback-timeline-slider" style={sliderStyle}>
          {!isEmptyList(tlBuckets) &&
            tlBuckets.map((tlBucket, i) => (
              <div className="playback-timeline-slider-bucket" key={i}>
                <div className="playback-timeline-slider-label">
                  <div>
                    {formatTime(tlBucket.toDate().toISOString(), timezone)}
                  </div>
                  &nbsp;
                </div>
                <div className="playback-timeline-slider-tick">
                  <div className="playback-timeline-slider-subtick"></div>
                  <div className="playback-timeline-slider-subtick"></div>
                  <div className="playback-timeline-slider-subtick"></div>
                  <div className="playback-timeline-slider-subtick"></div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div
        className="playback-timeline-playhead"
        onMouseOver={(e) => {
          e.preventDefault();
        }}
        onMouseEnter={(e) => {
          e.preventDefault();
        }}
      >
        <div>
          <CaretDown />
        </div>
      </div>
    </div>
  );
}

export default PlaybackTimeline;
