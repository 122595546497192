import { MapStyles, MenuMode, TurnaroundsPageMode } from "./constants";
import { isBlank } from "./utils";

export const SettingNames = {
  DEBUG_LOGGING: {
    name: "DEBUG_LOGGING",
    defaultValue: null,
  },
  MAP_STYLE: {
    name: "MAP_STYLE",
    defaultValue: MapStyles.DYNAMIC,
  },
  MENU_MODE: {
    name: "MENU_MODE",
    defaultValue: MenuMode.DEFAULT,
  },
  TURNAROUNDS_PAGE_MODE: {
    name: "TURNAROUNDS_PAGE_MODE",
    defaultValue: TurnaroundsPageMode.ACTIVE,
  },
  CURRENT_VERSION: {
    name: "CURRENT_VERSION",
    defaultValue: null,
  },
};

export const SettingsManager = {
  getSetting: (name) => {
    const value = localStorage.getItem(name);
    return isBlank(value) ? SettingNames[name]?.defaultValue : value;
  },
  setSetting: (name, value) => {
    return localStorage.setItem(name, value);
  },
  removeSetting: (name) => {
    return localStorage.removeItem(name);
  },
  updateState: (dispatch) => {
    let settingsVal = {};
    const keys = Object.keys(SettingNames);
    keys.forEach((i) => {
      settingsVal[i] = SettingsManager.getSetting(i);
    });
    dispatch({
      type: "setLocalSettings",
      value: settingsVal,
    });
  },
};
