import { useTranslation } from "react-i18next";
import { useMainDispatchContext } from "../../MainContext";
import { ReactComponent as Arrival } from "../../assets/turnarounds/arrival.svg";
import { ReactComponent as Departure } from "../../assets/turnarounds/departure.svg";
import { ANALYTICS_EVENTS } from "../../constants";
import {
  getAircraftInfoForMap,
  handleHover,
  MAPINFO_MARKER_TYPE,
} from "../../mapUtils";
import {
  getByFieldValue,
  isEmptyList,
  isNullOrUndefined,
  logAnalyticsEvent,
} from "../../utils";
import FlightProgress from "../FlightProgress";
import FlightTimeValue from "../FlightValues/FlightTimeValue";
import FlightStandValue from "../FlightValues/FlightStandValue";

function FlightInfoModule(props) {
  const { t } = useTranslation();
  const { flightInfo, isSelected, positions } = props;
  const dispatch = useMainDispatchContext();

  const airportTimezone = flightInfo.airportTimezone;

  const timeValueToDisplay = flightInfo.isInbound
    ? flightInfo.resolvedInboundTimes
    : flightInfo.resolvedOutboundTimes;

  const displayTimeLabel = flightInfo.isInbound
    ? timeValueToDisplay?.displayTimeLabel?.inbound
    : timeValueToDisplay?.displayTimeLabel?.outbound;

  const aircraft = flightInfo?.aircraft;
  const aircraftInfo = !isNullOrUndefined(flightInfo)
    ? getAircraftInfoForMap(flightInfo, aircraft)
    : null;

  const aircraftPosition = !isEmptyList(positions?.aircrafts)
    ? getByFieldValue(
        positions?.aircrafts,
        "registration",
        flightInfo?.registration
      )
    : null;
  return (
    <div
      className={`overlay-module${isSelected ? " selected" : ""}`}
      onClick={() => {
        dispatch({
          type: "setShowOnMap",
          value: {
            markerType: MAPINFO_MARKER_TYPE.AIRCRAFT,
            item: {
              registration: flightInfo.registration,
            },
          },
        });
        logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.MAP_VIEW_FLIGHTS_TILE);
      }}
      onMouseOver={() => {
        handleHover(true, aircraftInfo);
      }}
      onMouseOut={(e) => {
        handleHover(false, aircraftInfo);
      }}
      data-id={flightInfo.uuid}
      data-registration={flightInfo.registration}
    >
      <div className="overlay-module-box">
        <div className="flight-info">
          <div>
            {flightInfo.isInbound && (
              <>
                <Arrival />
              </>
            )}
            {flightInfo.isOutbound && (
              <>
                <Departure />
              </>
            )}
          </div>
          <div className="value-pair large-value">
            <label>{t("flight_non_cap")}</label>
            <div className="value">
              <div className="flight-name">
                <div>{flightInfo.flightName}</div>
                <div className="flight-name-details">
                  {flightInfo?.registration}
                </div>
              </div>
            </div>
          </div>
          <div className="value-pair large-value">
            <label>{t(displayTimeLabel)}</label>
            <div className="value">
              <FlightTimeValue
                resolvedFlightTimes={timeValueToDisplay}
                timezone={airportTimezone}
                showPlusDays={true}
              />
            </div>
          </div>
          <div className="value-pair large-value">
            <label>
              {flightInfo.isInbound && <>{t("origin_non_cap")}</>}
              {flightInfo.isOutbound && <>{t("destination_non_cap")}</>}
            </label>
            <div className="value">
              {flightInfo.isInbound && <>{flightInfo?.originAirport}</>}
              {flightInfo.isOutbound && <>{flightInfo?.destinationAirport}</>}
            </div>
          </div>
          <div className="value-pair large-value">
            <label>{t("stand_non_cap")}</label>
            <div className="value">
              <FlightStandValue
                item={flightInfo?.flight}
                aircraftPosition={aircraftPosition}
              />
            </div>
          </div>
        </div>
        <FlightProgress flightInfo={flightInfo} />
      </div>
    </div>
  );
}

export default FlightInfoModule;
