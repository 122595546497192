export const TemplateColumns = {
  Airline: {
    id: "template_airline",
    displayName: "airline",
    formatter: (item) => {
      return item.airline.name;
    },
    sortValue: (item) => {
      return item.airline.name;
    },
  },
  AircraftType: {
    id: "template_aircraft_type",
    displayName: "aircraft",
    formatter: (item) => {
      return item.aircraftType.name;
    },
    sortValue: (item) => {
      return item.aircraftType.name;
    },
  },
  Airport: {
    id: "template_airport",
    displayName: "airport",
    formatter: (item) => {
      return item.airport.iata;
    },
    sortValue: (item) => {
      return item.airport.iata;
    },
  },
};
