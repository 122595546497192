import { useReducer } from "react";
import { PlaybackContext, PlaybackDispatchContext } from "./PlaybackContext";

export const initialState = {
  globalError: null,
  globalErrorList: null,
  playbackData: null,
  playbackDataLoading: false,
  currentPlaybackTime: null,
};

export function PlaybackReducer(state, action) {
  switch (action.type) {
    case "setCurrentPlaybackTime":
      return {
        ...state,
        currentPlaybackTime: action.value,
      };
    case "setPlaybackData":
      return {
        ...state,
        playbackData: action.value,
      };
    case "setPlaybackDataLoading":
      return {
        ...state,
        playbackDataLoading: action.value,
      };
    case "setGlobalError":
      return {
        ...state,
        globalError: action.globalError,
        globalErrorList: action.globalErrorList,
      };
    default:
      console.log(`Unknown action: ${action.type}`);
      return {
        ...state,
      };
  }
}

export default function PlaybackProvider({ children }) {
  const [main, dispatch] = useReducer(PlaybackReducer, initialState);
  return (
    <PlaybackContext.Provider value={main}>
      <PlaybackDispatchContext.Provider value={dispatch}>
        {children}
      </PlaybackDispatchContext.Provider>
    </PlaybackContext.Provider>
  );
}
