import { useEffect, useState } from "react";
import {
  deepCopy,
  getByFieldValue,
  getMapFromList,
  isBlank,
  isEmptyList,
  isNullOrUndefined,
  logAnalyticsEvent,
  sortByField,
} from "../../utils";
import Checkbox from "../Checkbox";
import TemplateOperation from "./TemplateOperation";
import TemplateTimeline from "./TemplateTimeline";
import { ReactComponent as Plus } from "../../assets/plus.svg";
import { ReactComponent as LeftArrow } from "../../assets/arrow-left-sm.svg";
import { ReactComponent as RightArrow } from "../../assets/arrow-right-sm.svg";
import { ReactComponent as Check } from "../../assets/check-white.svg";
import { ReactComponent as Turnaround } from "../../assets/turnaround-large.svg";
import TemplateReview from "./TemplateReview";
import { useTranslation } from "react-i18next";
import { getEnabledTurnaroundProfileRequirements } from "../../turnaroundProfileUtils";
import { getGseTypeByCertificationName } from "../../gseUtils";
import { ANALYTICS_EVENTS } from "../../constants";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import {
  getCertifications,
  getDepartments,
  getOperationTypes,
} from "../../api";
import TemplateOperationModal from "./TemplateOperationModal";
import { OperationOffsetType } from "../../turnaroundUtils";
import { getCurrentDeployment } from "../../userUtils";

function TemplateEditor(props) {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { template, onBack, onSave, isTemplateSaving, isReadOnly } = props;
  const {
    operationTypes,
    departments,
    certifications,
    currentUser,
    deployments,
  } = mainContext;
  const [isTemplateOperationOpen, setIsTemplateOperationOpen] = useState(false);
  const [templateToEdit, setTemplateToEdit] = useState(deepCopy(template));
  const [editorMode, setEditorMode] = useState("configure"); // "review"
  const [hasChanges, setHasChanges] = useState(false);
  const [operationsList, setOperationsList] = useState([]);
  const [targetBlockTime, setTargetBlockTime] = useState(120);
  const [selectedOperation, setSelectedOperation] = useState(null);

  // Load additional data
  useEffect(() => {
    getOperationTypes(dispatch);
    getDepartments(dispatch);
    if (!isNullOrUndefined(currentUser) && !isNullOrUndefined(deployments)) {
      const currentDeployment = getCurrentDeployment(currentUser, deployments);
      getCertifications(dispatch, currentDeployment.uuid);
    }
  }, [dispatch, currentUser, deployments]);

  useEffect(() => {
    const opsList = templateToEdit?.turnaroundOperations;
    sortByField(opsList, "type.name");
    setOperationsList(opsList);
    if (!isEmptyList(opsList) && isNullOrUndefined(selectedOperation)) {
      setSelectedOperation(opsList[0]);
    }
  }, [templateToEdit, selectedOperation]);

  if (isNullOrUndefined(templateToEdit)) return null;

  // Templates don't have names (yet)
  const airline = template?.airline ? template?.airline.name : "-";
  const airport = template?.airport ? template?.airport.iata : "-";
  const aircraft = template?.aircraftType ? template?.aircraftType.name : "-";
  const templateName = `${aircraft} - ${airline} ${airport}`;

  const enabledTurnaroundProfileRequirements =
    getEnabledTurnaroundProfileRequirements(templateToEdit);
  const enabledGSETypes = {};
  if (enabledTurnaroundProfileRequirements?.length > 0) {
    for (let i = 0; i < enabledTurnaroundProfileRequirements.length; i++) {
      const turnaroundRequirement = enabledTurnaroundProfileRequirements[i];
      const gseTypeUuid =
        turnaroundRequirement?.certification?.groundVehicleTypeUuid;
      if (!isNullOrUndefined(gseTypeUuid)) {
        const quantity = turnaroundRequirement.quantity;
        const parentOperation = turnaroundRequirement.parentOperation;
        const operationInfo = {
          operation: parentOperation,
          requirement: turnaroundRequirement,
          quantity: quantity,
        };

        if (!isNullOrUndefined(enabledGSETypes[gseTypeUuid])) {
          enabledGSETypes[gseTypeUuid].quantity =
            enabledGSETypes[gseTypeUuid].quantity + quantity;
          enabledGSETypes[gseTypeUuid].operationsInfo[parentOperation.uuid] =
            operationInfo;
        } else {
          const operationsInfo = {};
          operationsInfo[parentOperation.uuid] = operationInfo;
          const gseType = getGseTypeByCertificationName(
            turnaroundRequirement?.certification?.name
          );
          enabledGSETypes[gseTypeUuid] = {
            gseType: gseType,
            certification: turnaroundRequirement.certification,
            quantity: quantity,
            operationsInfo: operationsInfo,
          };
        }
      }
    }
  }
  function handleToggleOperation(operationUuid) {
    const templateToUpdate = deepCopy(templateToEdit);
    const operationsByUuid = getMapFromList(
      templateToUpdate.turnaroundOperations,
      "uuid"
    );
    const operationToEdit = operationsByUuid[operationUuid];
    if (!isNullOrUndefined(operationToEdit)) {
      operationToEdit.enabled = !operationToEdit.enabled;
    }
    setTemplateToEdit(templateToUpdate);
    setHasChanges(true);
    logAnalyticsEvent(
      dispatch,
      ANALYTICS_EVENTS.TURNAROUND_TEMPLATE_TOGGLE_OPERATION
    );
  }

  function handleOperationUpdate(value) {
    const operationToUpdate = deepCopy(value);
    const templateToUpdate = deepCopy(templateToEdit);
    const turnaroundOperations = [];
    for (let i = 0; i < templateToUpdate.turnaroundOperations.length; i++) {
      const turnaroundOperation = templateToUpdate.turnaroundOperations[i];
      if (turnaroundOperation.uuid === value.uuid) {
        turnaroundOperations.push(operationToUpdate);
      } else {
        turnaroundOperations.push(turnaroundOperation);
      }
    }
    templateToUpdate.turnaroundOperations = turnaroundOperations;

    // Make sure to also update the selected operation
    if (!isNullOrUndefined(selectedOperation)) {
      setSelectedOperation(operationToUpdate);
    }
    setTemplateToEdit(templateToUpdate);
    setHasChanges(true);
  }

  function handleAddOperation(operationTypeToAdd, departmentToAdd) {
    const templateToUpdate = deepCopy(templateToEdit);
    const operationToAdd = {
      department: departmentToAdd,
      duration: 10,
      enabled: true,
      offsetTypeId: OperationOffsetType.INBOUND,
      startOffsetMinutes: 0,
      type: operationTypeToAdd,
      turnaroundRequirements: [],
      uuid: `_NEW_${operationTypeToAdd.uuid}`,
    };
    templateToUpdate.turnaroundOperations.push(operationToAdd);
    setTemplateToEdit(templateToUpdate);
    setHasChanges(true);
    setSelectedOperation(operationToAdd);
  }

  function handleRemoveOperation(operationUuidToRemove) {
    const templateToUpdate = deepCopy(templateToEdit);
    const idxToRemove = templateToUpdate.turnaroundOperations.findIndex(
      (i) => i.uuid === operationUuidToRemove
    );
    if (idxToRemove > -1) {
      templateToUpdate.turnaroundOperations.splice(idxToRemove, 1);
    }
    setTemplateToEdit(templateToUpdate);
    setHasChanges(true);

    if (!isEmptyList(operationsList)) {
      setSelectedOperation(operationsList[0]);
    } else {
      setSelectedOperation(null);
    }

    logAnalyticsEvent(
      dispatch,
      ANALYTICS_EVENTS.TURNAROUND_TEMPLATE_DELETE_OPERATION
    );
  }

  const isConfigureMode = editorMode === "configure";
  const isReviewMode = editorMode === "review";
  const isNextButtonDisabled = !isReadOnly && (isTemplateSaving || !hasChanges);

  // Operations available for adding
  const availableOperationTypes = [];
  if (!isEmptyList(operationTypes)) {
    for (let i = 0; i < operationTypes.length; i++) {
      const operationType = operationTypes[i];
      const existingOperation = getByFieldValue(
        operationsList,
        "type.uuid",
        operationType.uuid
      );
      if (isNullOrUndefined(existingOperation)) {
        availableOperationTypes.push(operationType);
      }
    }
    sortByField(availableOperationTypes, "name");
  }
  return (
    <div className="template-editor">
      <div className="template-editor-toolbar">
        <div>
          <div>
            <button
              className="secondary"
              onClick={() => {
                if (isConfigureMode) {
                  onBack();
                }
                if (isReviewMode) {
                  setEditorMode("configure");
                }
              }}
              disabled={isTemplateSaving}
            >
              <LeftArrow />
              {t("back")}
            </button>
          </div>
          <div>
            {isConfigureMode && !isReadOnly && (
              <h2>{`${t("edit")}: ${templateName}`}</h2>
            )}
            {isConfigureMode && isReadOnly && (
              <h2>{`${t("view")}: ${templateName}`}</h2>
            )}
            {isReviewMode && <h2>{t("review_turnaround_template")}</h2>}
          </div>
          <div>
            {isConfigureMode && (
              <button
                className="primary"
                onClick={() => {
                  setEditorMode("review");
                  logAnalyticsEvent(
                    dispatch,
                    ANALYTICS_EVENTS.TURNAROUND_TEMPLATE_NEXT
                  );
                }}
                disabled={isNextButtonDisabled}
              >
                <RightArrow /> {t("next")}
              </button>
            )}
            {isReviewMode && (
              <div>
                <button
                  className="primary"
                  onClick={() => {
                    onSave(templateToEdit);
                  }}
                  disabled={isReadOnly || isTemplateSaving}
                >
                  <Check /> {t("save")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="template-editor-container">
        {isConfigureMode && (
          <div className="template-editor-content">
            <div className="template-editor-side-panel">
              <div className="module">
                <div>
                  <div className="side-panel-header">
                    <div>
                      <h3>{t("operations")}</h3>
                    </div>
                    <div>
                      {!isReadOnly &&
                        !isEmptyList(availableOperationTypes) &&
                        !isEmptyList(departments) && (
                          <button
                            className="alternate"
                            onClick={() => {
                              setIsTemplateOperationOpen(true);
                            }}
                          >
                            <Plus /> {t("add_non_cap")}
                          </button>
                        )}
                    </div>
                  </div>
                  {operationsList &&
                    operationsList.map((operation) => (
                      <div
                        className={`side-panel-item${
                          operation.uuid === selectedOperation?.uuid
                            ? " selected"
                            : ""
                        }`}
                        key={operation.uuid}
                        onClick={() => {
                          setSelectedOperation(operation);
                        }}
                      >
                        <div>
                          <div>
                            <label>{operation?.type.name}</label>
                          </div>
                          <div>
                            <Checkbox
                              isChecked={operation.enabled}
                              changeHandler={() => {
                                handleToggleOperation(operation.uuid);
                              }}
                              isDisabled={isReadOnly}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              {isTemplateOperationOpen && (
                <TemplateOperationModal
                  operationTypes={availableOperationTypes}
                  departments={departments}
                  isOpen={isTemplateOperationOpen}
                  onClose={() => {
                    setIsTemplateOperationOpen(false);
                  }}
                  onAdd={(operationTypeToAdd, departmentToAdd) => {
                    setIsTemplateOperationOpen(false);
                    handleAddOperation(operationTypeToAdd, departmentToAdd);
                    logAnalyticsEvent(
                      dispatch,
                      ANALYTICS_EVENTS.TURNAROUND_TEMPLATE_ADD_OPERATION
                    );
                  }}
                />
              )}
            </div>
            <div className="template-editor-main-panel">
              {isEmptyList(operationsList) && (
                <div className="module">
                  <div className="empty-state">{t("no_operations")}</div>
                </div>
              )}
              {!isEmptyList(operationsList) &&
                !isNullOrUndefined(selectedOperation) && (
                  <>
                    <TemplateOperation
                      selectedOperation={selectedOperation}
                      onChange={(value) => {
                        handleOperationUpdate(value);
                      }}
                      onDelete={(value) => {
                        handleRemoveOperation(value);
                      }}
                      isReadOnly={isReadOnly}
                      departments={departments}
                      certifications={certifications}
                    />
                    <div className="module">
                      <div className="timeline-module">
                        <div>
                          <div className="timeline-header">
                            <div>
                              <h3>{t("timeline")}</h3>
                            </div>
                            <div>
                              <div className="timeline-header-fields">
                                <div>
                                  <label>{t("target_block_time")}:</label>
                                </div>
                                <div>
                                  <input
                                    type="text"
                                    placeholder={t("enter_duration")}
                                    value={targetBlockTime}
                                    onChange={(e) => {
                                      setTargetBlockTime(e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                      const currVal = !isNaN(
                                        parseInt(e.target.value)
                                      )
                                        ? parseInt(e.target.value)
                                        : 0;
                                      if (e.code === "ArrowUp") {
                                        setTargetBlockTime(currVal + 5);
                                      } else if (e.code === "ArrowDown") {
                                        if (currVal - 5 < 0) {
                                          setTargetBlockTime(0);
                                        } else {
                                          setTargetBlockTime(currVal - 5);
                                        }
                                      }
                                      if (e.key.length === 1) {
                                        const keyVal = parseInt(e.key);
                                        if (isNaN(keyVal)) {
                                          e.preventDefault();
                                        }
                                      }
                                    }}
                                  />
                                </div>
                                <div>
                                  <label>{t("minutes").toLowerCase()}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="timeline-body">
                            <TemplateTimeline
                              template={templateToEdit}
                              targetBlockTime={targetBlockTime}
                              enableSelect={true}
                              onSelect={(operation) => {
                                setSelectedOperation(operation);
                              }}
                              selectedOperationUuid={selectedOperation?.uuid}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
            </div>
          </div>
        )}
        {isReviewMode && (
          <div className="turnaround-review">
            <div className="module">
              <div className="turnaround-review-module">
                <div>
                  <Turnaround />
                </div>
                <div>
                  <h3>{templateName}</h3>
                </div>
                <div className="label-value-pair">
                  <div>
                    <label>{t("airline")}</label>
                  </div>
                  <div>{airline}</div>
                </div>
                <div className="label-value-pair">
                  <div>
                    <label>{t("airport")}</label>
                  </div>
                  <div>{airport}</div>
                </div>
                <div className="label-value-pair">
                  <div>
                    <label>{t("aircraft")}</label>
                  </div>
                  <div>{aircraft}</div>
                </div>
              </div>
            </div>

            <div>
              <TemplateReview template={templateToEdit} />
            </div>

            <div className="module">
              <div className="timeline-module">
                <div>
                  <div className="timeline-header">
                    <div>
                      <h3>{t("timeline")}</h3>
                    </div>
                    <div className="timeline-header-info">
                      {!isBlank(targetBlockTime) && (
                        <>{`${t("target_block_time")}: ${targetBlockTime} ${t(
                          "minutes"
                        ).toLowerCase()}`}</>
                      )}
                    </div>
                  </div>
                  <div className="timeline-body">
                    <TemplateTimeline
                      template={templateToEdit}
                      onSelect={(operation) => {
                        setSelectedOperation(operation);
                      }}
                      enableSelect={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TemplateEditor;
