import { useEffect, useState } from "react";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { getDynamicEnv, getPositions, getTurnaroundsSummary } from "../../api";
import { POLLING_INTERVALS } from "../../constants";
import { isEmptyList, isNullOrUndefined } from "../../utils";
import { useChatContext, useChatDispatchContext } from "../../ChatContext";
import { getChatSummary } from "../../chatApi";
import { useLocation } from "react-router-dom";
import { TurnaroundStatus } from "../../turnaroundUtils";
import { debugLog } from "../../logging";
import { useTranslation } from "react-i18next";
import { SettingNames, SettingsManager } from "../../SettingsManager";

/* Component that just polls/fetches data that can be used globally by components */
function ConsoleDataHelper() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const {
    turnaroundsSummary,
    turnaroundsSummaryLoading,
    positionsLoading,
    isRefreshPaused,
    vehicles,
    flightsToRender,
    showMapInfo,
    showMapRoutes,
    dynamicEnv,
    dynamicEnvLoading,
  } = mainContext;
  const chatDispatch = useChatDispatchContext();
  const chatContext = useChatContext();
  const { chatSummary, chatSummaryLoading, chatChannelMessageSaving } =
    chatContext;

  const [refreshPositionsRequested, setRefreshPositionsRequested] =
    useState(false);
  const [chatSummaryRequested, setChatSummaryRequested] = useState(false);
  const [
    refreshTurnaroundsSummaryRequested,
    setRefreshTurnaroundsSummaryRequested,
  ] = useState(false);
  const [refreshDynEnvRequested, setRefreshDynEnvRequested] = useState(false);

  const location = useLocation();
  const isOnMapPage = location?.pathname === "/map";

  // Update unread chat counts
  useEffect(() => {
    let unreadChatChannels = 0;
    if (!isEmptyList(chatSummary?.channels)) {
      chatSummary.channels.forEach((item) => {
        if (item.numUnreadMessages > 0) {
          unreadChatChannels++;
        }
      });
    }
    dispatch({
      type: "setUnreadChatChannels",
      value: unreadChatChannels,
    });
  }, [dispatch, chatSummary]);

  // Update unread turnaround chat counts
  useEffect(() => {
    let unreadTurnaroundChatChannels = 0;
    if (!isEmptyList(turnaroundsSummary)) {
      turnaroundsSummary.forEach((item) => {
        // Skip completed turnarounds
        const isCompleted =
          item?.status?.statusCode === TurnaroundStatus.TurnaroundComplete;

        if (!isCompleted && item.numUnreadMessages > 0) {
          unreadTurnaroundChatChannels++;
        }
      });
    }
    dispatch({
      type: "setUnreadTurnaroundChatChannels",
      value: unreadTurnaroundChatChannels,
    });
  }, [dispatch, turnaroundsSummary]);

  // Check for new version of HALO
  useEffect(() => {
    if (!isNullOrUndefined(dynamicEnv?.webVersion)) {
      const currVersion = SettingsManager.getSetting(
        SettingNames.CURRENT_VERSION.name
      );
      if (isNullOrUndefined(currVersion)) {
        // No version saved yet
        SettingsManager.setSetting(
          SettingNames.CURRENT_VERSION.name,
          dynamicEnv.webVersion
        );
        debugLog(`HALO v${dynamicEnv.webVersion}`);
      } else if (currVersion !== dynamicEnv.webVersion) {
        // Version has been updated
        dispatch({
          type: "setAlertMessage",
          alertMessage: {
            messageText: t("refresh_new_version_message"),
            alertAction: (
              <button
                className="primary"
                onClick={() => {
                  window.location.reload();
                }}
              >
                {t("refresh_now")}
              </button>
            ),
            remainOpen: true,
          },
        });
      }
    }
  }, [t, dispatch, dynamicEnv]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Clear the current version so that it can be reset when the webapp reloads
      SettingsManager.removeSetting(SettingNames.CURRENT_VERSION.name);
      debugLog("Unloading HALO");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Refresh comms
  useEffect(() => {
    if (isRefreshPaused) return () => {};
    if (
      !chatSummaryLoading &&
      !chatChannelMessageSaving &&
      chatSummaryRequested
    ) {
      getChatSummary(chatDispatch);
      setChatSummaryRequested(false);
    }
  }, [
    chatDispatch,
    chatSummaryRequested,
    chatSummaryLoading,
    chatChannelMessageSaving,
    isRefreshPaused,
  ]);

  // Refresh positions
  useEffect(() => {
    if (isRefreshPaused) return () => {};
    if (!refreshPositionsRequested) return () => {};
    if (positionsLoading) return () => {};
    if (!isOnMapPage) return () => {};
    getPositions(dispatch);
    setRefreshPositionsRequested(false);
  }, [
    dispatch,
    positionsLoading,
    refreshPositionsRequested,
    isRefreshPaused,
    vehicles,
    flightsToRender,
    showMapInfo,
    showMapRoutes,
    isOnMapPage,
  ]);

  // Refresh TurnarnoundsSummary
  useEffect(() => {
    if (isRefreshPaused) return () => {};
    if (turnaroundsSummaryLoading) return () => {};
    if (!refreshTurnaroundsSummaryRequested) return () => {};
    getTurnaroundsSummary(dispatch);
    setRefreshTurnaroundsSummaryRequested(false);
  }, [
    dispatch,
    turnaroundsSummaryLoading,
    refreshTurnaroundsSummaryRequested,
    isRefreshPaused,
  ]);

  // Refresh Dyn Env
  useEffect(() => {
    if (isRefreshPaused) return () => {};
    if (dynamicEnvLoading) return () => {};
    if (!refreshDynEnvRequested) return () => {};
    getDynamicEnv(dispatch);
    setRefreshDynEnvRequested(false);
  }, [dispatch, dynamicEnvLoading, refreshDynEnvRequested, isRefreshPaused]);

  // Setup intervals here (only once)
  useEffect(() => {
    const intervals = [];

    if (POLLING_INTERVALS.MAP_POSITIONS > 0) {
      const interval = setInterval(() => {
        setRefreshPositionsRequested(true);
      }, POLLING_INTERVALS.MAP_POSITIONS);
      intervals.push(interval);
    }

    if (POLLING_INTERVALS.CHAT_SUMMARY > 0) {
      const interval = setInterval(() => {
        setChatSummaryRequested(true);
      }, POLLING_INTERVALS.CHAT_SUMMARY);
      intervals.push(interval);
    }

    if (POLLING_INTERVALS.TURNAROUNDS_SUMMARY > 0) {
      const interval = setInterval(() => {
        setRefreshTurnaroundsSummaryRequested(true);
      }, POLLING_INTERVALS.TURNAROUNDS_SUMMARY);
      intervals.push(interval);
    }

    if (POLLING_INTERVALS.DYN_ENV > 0) {
      const interval = setInterval(() => {
        setRefreshDynEnvRequested(true);
      }, POLLING_INTERVALS.DYN_ENV);
      intervals.push(interval);
    }

    return () => {
      for (let i = 0; i < intervals.length; i++) {
        clearInterval(intervals[i]);
      }
    };
  }, []);

  return <></>;
}
export default ConsoleDataHelper;
