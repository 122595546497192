import { useTranslation } from "react-i18next";
import { isEmptyList } from "../../utils";
import { TaskInfoType } from "../../turnaroundUtils";

function TaskInfoFilters(props) {
  const { t } = useTranslation();
  const { taskInfoFilters, taskInfoList, onClick } = props;

  const typeCounts = {
    operations: 0,
    gse: 0,
    users: 0,
  };

  if (!isEmptyList(taskInfoList)) {
    taskInfoList.forEach((i) => {
      if (i.type === TaskInfoType.GSE) {
        typeCounts.gse = typeCounts.gse + 1;
      } else if (i.type === TaskInfoType.CREW) {
        typeCounts.users = typeCounts.users + 1;
      } else if (i.type === TaskInfoType.OPERATION) {
        typeCounts.operations = typeCounts.operations + 1;
      }
    });
  }
  return (
    <div className="task-info-filters">
      <>
        <div>
          <TaskInforFilter
            isSelected={taskInfoFilters.showOperations}
            label={`${t("operations")} (${typeCounts.operations})`}
            className={"operations"}
            onClick={() => {
              onClick("operations");
            }}
          />
          <TaskInforFilter
            isSelected={taskInfoFilters.showGse}
            label={`${t("gse")} (${typeCounts.gse})`}
            className={"gse"}
            onClick={() => {
              onClick("gse");
            }}
          />
          <TaskInforFilter
            isSelected={taskInfoFilters.showUsers}
            label={`${t("users")} (${typeCounts.users})`}
            className={"users"}
            onClick={() => {
              onClick("users");
            }}
          />
        </div>
      </>
    </div>
  );
}

function TaskInforFilter(props) {
  const { isSelected, label, className, onClick } = props;

  return (
    <div
      className={`task-info-filter ${className}${
        isSelected ? " selected" : ""
      }`}
      onClick={onClick}
    >
      <div>
        <div>
          <div className="task-info-filter-dot"></div>
        </div>
        <div className="task-info-filter-name">{label}</div>
      </div>
    </div>
  );
}

export default TaskInfoFilters;
