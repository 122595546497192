import { Modal } from "@mui/material";
import { getByFieldValue, isBlank, isEmptyList } from "../../utils";

import { ReactComponent as Cross } from "../../assets/cross.svg";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import SearchSelector from "../SearchSelector";
import { selectInputFix } from "../../styleUtils";

function TemplateOperationModal(props) {
  const { isOpen, onClose, onAdd, operationTypes, departments } = props;
  const { t } = useTranslation();
  const [selectedOperationTypeUuid, setSelectedOperationTypeUuid] =
    useState("");
  const [selectedDepartmentUuid, setSelectedDepartmentUuid] = useState(
    departments[0].uuid
  );

  function handleClose() {
    onClose();
  }
  const isValid =
    !isBlank(selectedOperationTypeUuid) && !isBlank(selectedDepartmentUuid);

  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <div className="modal template-add-operation-modal modal-50">
          <div>
            <div className="modal-header">
              <div>
                <h2>{t("add_operation")}</h2>
              </div>
              <div className="button-icon" onClick={handleClose}>
                <Cross />
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-container">
              <div className="template-add-operation">
                <div className="template-add-operation-modal-row">
                  <div>
                    <label>{t("operation")}</label>
                  </div>
                  <div>
                    <SearchSelector
                      onSelect={(value) => {
                        setSelectedOperationTypeUuid(value.uuid);
                      }}
                      items={operationTypes}
                      searchableName="name"
                      selectedFieldName="uuid"
                      value={selectedOperationTypeUuid}
                      allowClear={false}
                    />
                  </div>
                </div>
                <div className="template-add-operation-modal-row">
                  <div>
                    <label>{t("department")}</label>
                  </div>
                  <div>
                    <select
                      value={selectedDepartmentUuid}
                      onChange={(e) => {
                        setSelectedDepartmentUuid(e.target.value);
                      }}
                      style={selectInputFix}
                    >
                      {!isEmptyList(departments) &&
                        departments.map((department) => (
                          <option key={department.uuid} value={department.uuid}>
                            {department.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="modal-footer">
              <div>
                <button className="secondary" onClick={handleClose}>
                  {t("cancel")}
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    const operationType = getByFieldValue(
                      operationTypes,
                      "uuid",
                      selectedOperationTypeUuid
                    );
                    const department = getByFieldValue(
                      departments,
                      "uuid",
                      selectedDepartmentUuid
                    );
                    onAdd(operationType, department);
                  }}
                  disabled={!isValid}
                >
                  {t("add_non_cap")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default TemplateOperationModal;
