import "./styles.css";
import { useEffect } from "react";

import { useMainContext, useMainDispatchContext } from "../../MainContext";
import MainMenu from "../MainMenu";
import UserBadge from "../UserBadge";
import Crew from "../Crew";
import Dashboard from "../Dashboard";
import Vehicles from "../Vehicles";
import { getDeployments, getDynamicEnv, getUser, logout } from "../../api";
import { isBlank, isNullOrUndefined, logAnalyticsEvent } from "../../utils";
import Flights from "../Flights";
import {
  ResourceActions,
  userHasAnyResourceAction,
  userHasResourceAction,
} from "../../resourceActionsUtils";
import Settings from "../Settings";
import SectionToolbar from "../SectionToolbar";
import Map from "../Map";
import { CookieManager } from "../../cookies";
import Templates from "../Templates";
import ConsoleDataHelper from "./ConsoleDataHelper";
import Chat from "../Chat";
import CrewShifts from "../CrewShifts";
import CrewShiftsProvider from "../../CrewShiftsProvider";
import SettingsProvider from "../../SettingsProvider";
import TurnaroundsModule from "../TurnaroundsModule";
import { useParams } from "react-router-dom";
import LiveRoster from "../LiveRoster";
import TasksOverviewProvider from "../../TasksOverviewProvider";
import Keys from "../Keys";
import { checkSupportedBrowser } from "../../i18n/browserUtils";
import UnsupportedBrowser from "../UnsupportedBrowser";
import { ANALYTICS_EVENTS, MenuMode } from "../../constants";
import MenuHandle from "../MainMenu/MenuHandle";
import { SettingNames, SettingsManager } from "../../SettingsManager";
import PlaybackTool from "../PlaybackTool";
import PlaybackProvider from "../../PlaybackProvider";

function MainConsole(props) {
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { currentUser, dynamicEnv, localSettings } = mainContext;
  const { section } = props;

  const isCompactMode = !isNullOrUndefined(localSettings)
    ? MenuMode.COMPACT === localSettings[SettingNames.MENU_MODE.name]
    : MenuMode.COMPACT ===
      SettingsManager.getSetting(SettingNames.MENU_MODE.name);

  const isInternal = currentUser?.isInternal;

  useEffect(() => {
    getDynamicEnv(dispatch);
    getDeployments(dispatch);
  }, [dispatch]);

  useEffect(() => {
    // check if we can refresh the login user
    if (isNullOrUndefined(currentUser)) {
      // const accessToken = localStorage.getItem("accessToken");
      const accessToken = CookieManager.getCookieValue("accessToken");
      if (!isBlank(accessToken)) {
        getUser(dispatch);
        return () => {};
      }
      logout();
    }
  }, [currentUser, dispatch]);

  const hasEditAdminUsers = userHasResourceAction(
    currentUser,
    ResourceActions.EditAdminUsers
  );
  const hasEditAdminGse = userHasResourceAction(
    currentUser,
    ResourceActions.EditAdminGse
  );
  const hasEditTurnTemplate = userHasAnyResourceAction(
    currentUser,
    ResourceActions.EditTurnTemplate
  );
  const hasCrewShifts = userHasAnyResourceAction(
    currentUser,
    ResourceActions.EditCrewShifts
  );
  const hasLiveRoster = userHasAnyResourceAction(
    currentUser,
    ResourceActions.ViewLiveRoster
  );

  const hasViewFlightsPage = userHasAnyResourceAction(
    currentUser,
    ResourceActions.ViewFlightsPage
  );
  const hasFlights = hasViewFlightsPage;

  const hasViewTurnProfile = userHasAnyResourceAction(
    currentUser,
    ResourceActions.ViewTurnProfile
  );

  const hasPlayback = isInternal;

  const showMap = !isNullOrUndefined(dynamicEnv?.mapBoxToken);
  const showTurnaroundTemplates = hasEditTurnTemplate;
  const showLiveRoster = hasLiveRoster;
  const showFlights = hasFlights;

  const isSupportedBrowser = checkSupportedBrowser();

  const { turnaroundUuid } = useParams();
  const isTurnarounds = section === "turnarounds";
  const showTurnaroundToolbar = isTurnarounds && isBlank(turnaroundUuid);

  const escKeyAction = () => {
    dispatch({
      type: "setSearchQuery",
      value: {
        searchTerm: "",
      },
    });
  };
  const ctrlKeyActions = {
    f: () => {
      const inputEl = document.querySelector(".search-input-container input");
      if (!isNullOrUndefined(inputEl)) {
        inputEl.focus();
      }
    },
  };

  return (
    <div className={`main-console${isCompactMode ? " compact" : ""}`}>
      <div className="main-side-panel">
        <MainMenu
          showAdminUsers={hasEditAdminUsers}
          showAdminGses={hasEditAdminGse}
          showTurnaroundTemplates={showTurnaroundTemplates}
          showGateSchedule={false} // TODO: Remove
          showCrewShifts={hasCrewShifts}
          showChat={!isInternal}
          showLiveRoster={showLiveRoster}
          showFlights={showFlights}
          showTurnarounds={hasViewTurnProfile}
          isCompactMode={isCompactMode}
        />
      </div>
      <div className="main-container">
        <MenuHandle
          onToggleCompactMode={() => {
            const menuMode = isCompactMode
              ? MenuMode.DEFAULT
              : MenuMode.COMPACT;
            SettingsManager.setSetting(SettingNames.MENU_MODE.name, menuMode);
            SettingsManager.updateState(dispatch);

            logAnalyticsEvent(
              dispatch,
              ANALYTICS_EVENTS.NAV_MENU_TOGGLE,
              menuMode
            );

            // Trigger window resize
            setTimeout(() => {
              window.dispatchEvent(new Event("resize"));
            }, 250);
          }}
        />
        <div className="main-header">
          <div className="header-left">
            {isSupportedBrowser && (
              <>
                {showTurnaroundToolbar && (
                  <SectionToolbar
                    eventName={ANALYTICS_EVENTS.TURNAROUNDS_SEARCH}
                  />
                )}
                {section === "flights" && <SectionToolbar />}
                {section === "users" && (
                  <SectionToolbar eventName={ANALYTICS_EVENTS.USERS_SEARCH} />
                )}
                {section === "vehicles" && (
                  <SectionToolbar eventName={ANALYTICS_EVENTS.GSE_SEARCH} />
                )}
                {section === "map" && (
                  <SectionToolbar eventName={ANALYTICS_EVENTS.MAP_SEARCH} />
                )}
                {section === "crew-shifts" && (
                  <SectionToolbar
                    eventName={ANALYTICS_EVENTS.CREW_SHIFTS_SEARCH}
                  />
                )}
                {section === "live-roster" && (
                  <SectionToolbar
                    eventName={ANALYTICS_EVENTS.LIVE_ROSTER_SEARCH}
                  />
                )}
                {section === "chat" && (
                  <SectionToolbar eventName={ANALYTICS_EVENTS.CHAT_SEARCH} />
                )}
                {section === "stand-assignments" && (
                  <SectionToolbar
                    eventName={ANALYTICS_EVENTS.STAND_ASSIGNMENTS_SEARCH}
                  />
                )}
              </>
            )}
          </div>
          <div className="header-center"></div>
          <div className="header-right">
            <UserBadge
              user={currentUser}
              menuEnabled={true}
              secondaryInfoField="userGroupUuid"
              isProfileBadge={true}
            />
          </div>
        </div>
        {isSupportedBrowser && (
          <div className="main-content-box">
            {section === "dashboard" && <Dashboard />}
            {showFlights && section === "flights" && <Flights />}
            {showLiveRoster && section === "live-roster" && (
              <CrewShiftsProvider>
                <TasksOverviewProvider>
                  <LiveRoster />
                </TasksOverviewProvider>
              </CrewShiftsProvider>
            )}
            {section === "map" && showMap && (
              <Map isCompactMode={isCompactMode} />
            )}
            {section === "chat" && <Chat />}
            {section === "turnarounds" && (
              <TasksOverviewProvider>
                <TurnaroundsModule />
              </TasksOverviewProvider>
            )}
            {section === "turnarounds-details" && <TurnaroundsModule />}
            {section === "turnarounds-timeline" && (
              <TurnaroundsModule subPath={"timeline"} />
            )}
            {section === "turnarounds-chat" && (
              <TurnaroundsModule subPath={"chat"} />
            )}
            {section === "users" && hasEditAdminUsers && (
              <CrewShiftsProvider>
                <Crew />
              </CrewShiftsProvider>
            )}
            {section === "vehicles" && hasEditAdminGse && <Vehicles />}
            {section === "templates" && showTurnaroundTemplates && (
              <Templates />
            )}
            {section === "crew-shifts" && hasCrewShifts && (
              <CrewShiftsProvider>
                <CrewShifts />
              </CrewShiftsProvider>
            )}
            {section === "settings" && (
              <SettingsProvider>
                <Settings />
              </SettingsProvider>
            )}
            {section === "playback" && hasPlayback && (
              <PlaybackProvider>
                <PlaybackTool />
              </PlaybackProvider>
            )}
          </div>
        )}
        {!isSupportedBrowser && (
          <div className="main-content-box">
            <UnsupportedBrowser />
          </div>
        )}
      </div>
      <ConsoleDataHelper />
      {
        // NOTE: Map page has its own keys handler
        section !== "map" && (
          <Keys ctrlKeyActions={ctrlKeyActions} escKeyAction={escKeyAction} />
        )
      }
    </div>
  );
}

export default MainConsole;
