import { ReactComponent as CloseBtn } from "../../assets/close-btn.svg";
import { ReactComponent as LeftArrow } from "../../assets/arrow-left-sm.svg";
import { useTranslation } from "react-i18next";
import TurnaroundCrew from "./TurnaroundCrew";
import {
  getByFieldValue,
  isEmptyList,
  isNullOrUndefined,
  sortByField,
} from "../../utils";
import { useMainDispatchContext } from "../../MainContext";
import { useEffect, useState } from "react";
import TurnaroundVehicle from "./TurnaroundVehicle";
import { MAPINFO_MARKER_TYPE } from "../../mapUtils";
import { getResourcesAtStand } from "../Map/mapHelpers";

function TurnaroundServiceRecordsPanel(props) {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();

  const {
    onBack,
    onClose,
    serviceRecordsPanelMode,
    selectedTurnaround,
    positions,
    airportTimezone,
    vehicles,
    users,
  } = props;
  const [selectedUuid, setSelectedUuid] = useState(null);
  const isCrewMode = serviceRecordsPanelMode === "crew";

  const resourcesAtStand = getResourcesAtStand(
    selectedTurnaround?.registration,
    positions
  );
  const gseList = [];
  const usersList = [];
  if (
    !isEmptyList(resourcesAtStand?.groundVehicles) &&
    !isEmptyList(vehicles)
  ) {
    resourcesAtStand.groundVehicles.forEach((i) => {
      const vehicle = getByFieldValue(vehicles, "trackerCode", i.trackerCode);
      if (!isNullOrUndefined(vehicle)) {
        gseList.push(vehicle);
      }
    });
  }
  if (!isEmptyList(resourcesAtStand?.users) && !isEmptyList(users)) {
    resourcesAtStand.users.forEach((i) => {
      const user = getByFieldValue(users, "uuid", i.uuid);
      if (!isNullOrUndefined(user)) {
        usersList.push(user);
      }
    });
  }

  if (!isEmptyList(usersList)) {
    sortByField(usersList, "firstName", ["lastName"]);
  }
  if (!isEmptyList(gseList)) {
    sortByField(gseList, "name");
  }

  useEffect(() => {
    resetScroll();
  }, []);

  function resetScroll() {
    document
      .querySelector(".map .map-panel .overlay-panel-content")
      .scrollTo(0, 0);
  }

  return (
    <div className="unassigned-user-panel">
      <div className="panel-module">
        <div className="context-panel-content-header">
          <div className="back-btn">
            <div>
              <button
                className="outline-filled"
                onClick={() => {
                  onBack();
                }}
              >
                <LeftArrow /> {t("back")}
              </button>
            </div>
          </div>
          <div className="close-btn" onClick={onClose}>
            <CloseBtn />
          </div>
        </div>
        <div className="turnaround-content open">
          <div>
            {isCrewMode && (
              <div>
                {t("crew")} ({!isEmptyList(usersList) ? usersList.length : 0})
              </div>
            )}
            {!isCrewMode && (
              <div>
                {t("gse")} ({!isEmptyList(gseList) ? gseList.length : 0})
              </div>
            )}
          </div>
          {isCrewMode && (
            <div className="turnaround-crew-list">
              {!isEmptyList(usersList) &&
                usersList.map((item) => {
                  return (
                    <TurnaroundCrew
                      key={item?.uuid}
                      crewInfo={{ user: item }}
                      selected={selectedUuid === item?.uuid}
                      showCertifications={false}
                      showActions={true}
                      showAssignCrew={false}
                      isSelectable={selectedUuid !== item?.uuid}
                      onClick={() => {
                        setSelectedUuid(item?.uuid);
                        dispatch({
                          type: "setShowOnMap",
                          value: {
                            markerType: MAPINFO_MARKER_TYPE.USER,
                            item: {
                              uuid: item?.uuid,
                            },
                            isLocateMode: true,
                          },
                        });
                      }}
                      isUnassigned={false}
                    />
                  );
                })}
            </div>
          )}
          {!isCrewMode && (
            <div className="turnaround-crew-list">
              {!isEmptyList(gseList) &&
                gseList.map((item) => {
                  return (
                    <TurnaroundVehicle
                      key={item.uuid}
                      vehicleInfo={{ groundVehicle: item }}
                      vehiclePosition={item}
                      airportTimezone={airportTimezone}
                      selected={selectedUuid === item?.uuid}
                      isSelectable={selectedUuid !== item?.uuid}
                      selectedTurnaround={selectedTurnaround}
                      onClick={() => {
                        setSelectedUuid(item?.uuid);
                        dispatch({
                          type: "setShowOnMap",
                          value: {
                            markerType: MAPINFO_MARKER_TYPE.VEHICLE,
                            item: {
                              uuid: item?.uuid,
                            },
                            isLocateMode: true,
                          },
                        });
                      }}
                    />
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TurnaroundServiceRecordsPanel;
