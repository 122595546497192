import { EMPTY_VALUE } from "../../constants";
import { resolveTurnaroundStands } from "../../turnaroundUtils";
import { isBlank, isNullOrUndefined } from "../../utils";

function FlightStandValue(props) {
  const { item, aircraftPosition } = props;

  const turnaroundStands = resolveTurnaroundStands(item);
  const hasPrevStand = !isBlank(turnaroundStands.prevStand);
  const currentStandFromPosition = !isNullOrUndefined(aircraftPosition)
    ? aircraftPosition.currentStandName
    : null;
  const currentStandName = !isBlank(currentStandFromPosition)
    ? currentStandFromPosition
    : turnaroundStands.currentStand;

  return (
    <div className="flight-stand-value">
      <div className={hasPrevStand ? "highlight-value" : ""}>
        {!isBlank(currentStandName) ? currentStandName : EMPTY_VALUE}
      </div>
      {hasPrevStand && (
        <div className="previous-value">{turnaroundStands.prevStand}</div>
      )}
    </div>
  );
}

export default FlightStandValue;
