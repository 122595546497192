import "./styles.css";
import { ReactComponent as Plus } from "../../assets/plus.svg";
import { useEffect, useMemo, useState } from "react";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import {
  getCertifications,
  getCrewList,
  getUserGroups,
  removeUser,
  saveUser,
} from "../../api";
import PageToolbar from "../PageToolbar";
import CrewModal from "./CrewModal";
import {
  deepCopy,
  getTimezoneFromUser,
  isEmptyList,
  isNullOrUndefined,
  logAnalyticsEvent,
} from "../../utils";
import DataTable from "../DataTable";
import { ColumnNames } from "../DataTable";
import LoadingIndicator from "../LoadingIndicator";
import {
  ANALYTICS_EVENTS,
  DEFAULT_TIMEZONE,
  SEARCH_QUERY_PLACEHOLDERS,
} from "../../constants";
import { useTranslation } from "react-i18next";
import { searchCrewShifts } from "../../crewShiftsApi";
import {
  useCrewShiftsContext,
  useCrewShiftsDispatchContext,
} from "../../CrewShiftsContext";
import moment from "moment";
import { hasCrewShiftsForDate } from "../../crewShiftUtils";
import CrewFilter from "./CrewFilter";
import { getCurrentDeployment } from "../../userUtils";

function Crew() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const crewShiftsDispatch = useCrewShiftsDispatchContext();
  const crewShiftsContext = useCrewShiftsContext();
  const { crewShifts } = crewShiftsContext;

  const {
    certifications,
    crewList,
    crewListLoading,
    userGroups,
    userSaving,
    searchQuery,
    currentUser,
    deployments,
  } = mainContext;

  const airport = !isNullOrUndefined(currentUser) ? currentUser.airport : null;
  const airportTimezone = !isNullOrUndefined(airport)
    ? airport.timezone
    : DEFAULT_TIMEZONE;

  const [selectedCrewMember, setSelectedCrewMember] = useState(null);
  const [isUserSaveInitiated, setIsUserSaveInitiated] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [crewFilters, setCrewFilters] = useState({
    userGroups: [],
  });

  useEffect(() => {
    if (isUserSaveInitiated && !userSaving) {
      setSelectedCrewMember(null);
      setIsUserSaveInitiated(false);

      // refetch
      getCrewList(dispatch);
    }
  }, [dispatch, userSaving, isUserSaveInitiated]);

  useEffect(() => {
    const startOfWeek = moment.tz(airportTimezone).startOf("week");
    const endOfWeek = moment.tz(airportTimezone).endOf("week");
    const startTime = startOfWeek.toDate().toISOString();
    const endTime = moment(endOfWeek).toDate().toISOString();
    searchCrewShifts(crewShiftsDispatch, startTime, endTime);
  }, [crewShiftsDispatch, airportTimezone]);

  useEffect(() => {
    getCrewList(dispatch);
    getUserGroups(dispatch);
    if (!isNullOrUndefined(currentUser) && !isNullOrUndefined(deployments)) {
      const currentDeployment = getCurrentDeployment(currentUser, deployments);
      getCertifications(dispatch, currentDeployment.uuid);
    }

    dispatch({
      type: "setSearchQuery",
      value: {
        searchTerm: "",
        searchPlaceholder: SEARCH_QUERY_PLACEHOLDERS.SEARCH_CREW,
      },
    });
  }, [dispatch, currentUser, deployments]);

  const crewListToRender = useMemo(() => {
    if (!isEmptyList(crewList)) {
      const timezone = getTimezoneFromUser(currentUser);
      const mToday = moment().tz(timezone).startOf("day");

      const crewListFiltered = [];
      crewList.forEach((item) => {
        if (
          isEmptyList(crewFilters.userGroups) ||
          crewFilters.userGroups.includes(item.userGroupUuid)
        )
          crewListFiltered.push({
            ...item,
            currentUser: currentUser,
            hasCrewShiftForToday: hasCrewShiftsForDate(
              item,
              crewShifts,
              mToday
            ),
          });
      });
      return crewListFiltered;
    }
  }, [crewList, currentUser, crewShifts, crewFilters]);

  const actionButtons = [
    <button
      onClick={() => {
        const defaultUserGroupId =
          !isNullOrUndefined(userGroups) && userGroups.length > 0
            ? userGroups[0].uuid
            : "";
        setSelectedCrewMember({
          firstName: "",
          lastName: "",
          email: "",
          userGroupUuid: defaultUserGroupId,
        });
      }}
    >
      <Plus /> {t("add_user")}
    </button>,
    <CrewFilter
      userGroups={userGroups}
      itemList={crewList}
      crewFilters={crewFilters}
      onChange={(userGroupId) => {
        if ("_none" === userGroupId) {
          setCrewFilters((prev) => {
            const updatedCrewFilters = deepCopy(prev);
            updatedCrewFilters.userGroups = [];
            return updatedCrewFilters;
          });
        } else {
          setCrewFilters((prev) => {
            const updatedCrewFilters = deepCopy(prev);
            if (updatedCrewFilters.userGroups.includes(userGroupId)) {
              // Remove from filters
              updatedCrewFilters.userGroups.splice(
                updatedCrewFilters.userGroups.indexOf(userGroupId),
                1
              );
            } else {
              updatedCrewFilters.userGroups.push(userGroupId);
            }
            return updatedCrewFilters;
          });
        }
        logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.USERS_CHANGE_FILTER);
      }}
    />,
  ];

  const handleDeleteReset = () => {
    dispatch({
      type: "setConfirmation",
      confirmation: null,
    });
    setIsDeleting(false);
    setSelectedCrewMember(null);
  };

  async function handleDelete(user) {
    setIsUserSaveInitiated(true);
    await removeUser(user, dispatch);
    handleDeleteReset();
    dispatch({
      type: "setAlertMessage",
      alertMessage: t("deleted_web", {
        name: `${user.firstName} ${user.lastName}`,
      }),
    });
  }

  async function handleSave(user) {
    setIsUserSaveInitiated(true);
    await saveUser(user, dispatch);
    dispatch({
      type: "setAlertMessage",
      alertMessage: t("saved_web", {
        name: `${user.firstName} ${user.lastName}`,
      }),
    });
  }

  const handleDeleteCrewMember = (user) => {
    setIsDeleting(true);
    setSelectedCrewMember(user);
    dispatch({
      type: "setConfirmation",
      confirmation: {
        message: t("delete_msg_web", {
          name: `${user.firstName} ${user.lastName}`,
        }),
        actionOK: () => {
          handleDelete(user);
          logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.USERS_DELETE);
        },
        actionCancel: () => {
          handleDeleteReset();
        },
      },
    });
  };

  const totalUsers = crewListToRender?.length ? crewListToRender?.length : 0;
  return (
    <div className="crew">
      <div className="crew-header">
        <PageToolbar
          title={t("users")}
          actionButtons={actionButtons}
          badgeValue={totalUsers}
        />
      </div>
      <div className="crew-container">
        {crewListLoading && <LoadingIndicator />}
        {!crewListLoading && (
          <DataTable
            dataList={crewListToRender}
            selectedUuids={
              !isNullOrUndefined(selectedCrewMember)
                ? [selectedCrewMember.uuid]
                : []
            }
            columnNames={[
              ColumnNames.Name,
              ColumnNames.Certifications,
              ColumnNames.CurrentShifts,
              ColumnNames.Confirmed,
            ]}
            rowActions={{
              editAction: (userObj) => {
                setSelectedCrewMember(userObj);
                logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.USERS_EDIT);
              },
              deleteAction: (userObj) => {
                handleDeleteCrewMember(userObj);
              },
            }}
            gridColumns={"repeat(2, minmax(0,1fr)) 320px 96px 96px"}
            searchQuery={searchQuery}
          />
        )}
        <CrewModal
          selectedCrewMember={!isDeleting ? selectedCrewMember : null}
          onCloseHandler={() => {
            setSelectedCrewMember(null);
          }}
          onSaveHandler={(values) => {
            handleSave(values);
          }}
          userGroups={userGroups}
          certifications={certifications}
          userSaving={userSaving}
        />
      </div>
    </div>
  );
}
export default Crew;
