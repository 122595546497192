import { createContext, useContext } from "react";

export const PlaybackContext = createContext(null);
export const PlaybackDispatchContext = createContext(null);

// Convenience methods that can be used in components
export function usePlaybackContext() {
  return useContext(PlaybackContext);
}

export function usePlaybackDispatchContext() {
  return useContext(PlaybackDispatchContext);
}
