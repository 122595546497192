import { Modal } from "@mui/material";
import { getByFieldValue, isBlank, sortByField } from "../../utils";

import { ReactComponent as Cross } from "../../assets/cross.svg";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import SearchSelector from "../SearchSelector";

function TemplateRequirementModal(props) {
  const { isOpen, onClose, onAdd, certifications } = props;
  const { t } = useTranslation();

  sortByField(certifications, "name");
  const [selectedCertificationUuid, setSelectedCertificationUuid] = useState(
    certifications[0].uuid
  );

  function handleClose() {
    onClose();
  }
  const isValid =
    !isBlank(selectedCertificationUuid) && !isBlank(selectedCertificationUuid);

  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <div className="modal template-add-operation-modal modal-50">
          <div>
            <div className="modal-header">
              <div>
                <h2>{t("add_requirement")}</h2>
              </div>
              <div className="button-icon" onClick={handleClose}>
                <Cross />
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-container">
              <div className="template-add-operation">
                <div className="template-add-operation-modal-row">
                  <div>
                    <label>{t("certifications")}</label>
                  </div>
                  <div>
                    <SearchSelector
                      onSelect={(value) => {
                        setSelectedCertificationUuid(value.uuid);
                      }}
                      items={certifications}
                      searchableName="name"
                      selectedFieldName="uuid"
                      value={selectedCertificationUuid}
                      allowClear={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="modal-footer">
              <div>
                <button className="secondary" onClick={handleClose}>
                  {t("cancel")}
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    const certification = getByFieldValue(
                      certifications,
                      "uuid",
                      selectedCertificationUuid
                    );
                    onAdd(certification);
                  }}
                  disabled={!isValid}
                >
                  {t("add_non_cap")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default TemplateRequirementModal;
