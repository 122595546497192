import "./styles.css";
import { ReactComponent as PlayIcon } from "../../assets/playback/play-fill.svg";
import { ReactComponent as PauseIcon } from "../../assets/playback/pause-fill.svg";
import { ReactComponent as Reset } from "../../assets/playback/skip-backward-fill.svg";
import { ReactComponent as Rewind } from "../../assets/playback/rewind-fill.svg";
import { ReactComponent as FastForward } from "../../assets/playback/fast-forward-fill.svg";
import moment from "moment-timezone";

function PlaybackButtons(props) {
  const {
    isPlaying,
    isDisabled,
    onPlay,
    onBack,
    onForward,
    onReset,
    currentTime,
    playbackTimeRange,
    timezone,
  } = props;

  const mCurrentTime = moment(currentTime).tz(timezone);
  const mRangeStart = moment(playbackTimeRange.startTime).tz(timezone);
  const mRangeEnd = moment(playbackTimeRange.endTime).tz(timezone);

  const hasBack = mCurrentTime.isAfter(mRangeStart);
  const hasForward = mCurrentTime.isBefore(mRangeEnd);
  return (
    <div className="playback-buttons">
      <div>
        <button
          className={`playback-button secondary`}
          disabled={isDisabled || isPlaying || !hasBack}
          onClick={onReset}
        >
          <Reset />
        </button>
      </div>
      <div>
        <button
          className={`playback-button secondary`}
          disabled={isDisabled || isPlaying || !hasBack}
          onClick={onBack}
        >
          <Rewind />
        </button>
      </div>
      <div>
        <button
          className={`playback-button primary ${isPlaying ? " playing" : ""}`}
          disabled={isDisabled || !hasForward}
          onClick={onPlay}
        >
          {!isPlaying && <PlayIcon />}
          {isPlaying && <PauseIcon />}
        </button>
      </div>
      <div>
        <button
          className={`playback-button secondary`}
          disabled={isDisabled || isPlaying || !hasForward}
          onClick={onForward}
        >
          <FastForward />
        </button>
      </div>
    </div>
  );
}

export default PlaybackButtons;
