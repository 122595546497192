import "./styles.css";
import { useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as Caret } from "../../assets/caret-down.svg";
import {
  filterBySearchQuery,
  getByFieldValue,
  isEmptyList,
  isNullOrUndefined,
  makeKeyFriendlyString,
} from "../../utils";
import { useTranslation } from "react-i18next";

function SearchSelector(props) {
  const { t } = useTranslation();
  const {
    onSelect,
    items,
    value,
    allowClear,
    searchableName,
    selectedFieldName,
  } = props;
  const componentRef = useRef(null);
  const inputRef = useRef(null);

  const [isActive, setIsActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  function onChangeHandler(newValue) {
    onSelect(newValue);
    setIsActive(false);
    setSearchQuery("");
  }

  const itemsList = useMemo(() => {
    const searchItems = [];
    if (!isEmptyList(items)) {
      items.forEach((i) => {
        let searchItem = {};
        searchItem[selectedFieldName] = i[selectedFieldName];
        searchItem.searchableName = i[searchableName];
        searchItems.push(searchItem);
      });
    }
    return searchItems;
  }, [items, selectedFieldName, searchableName]);

  const itemsFiltered = !isNullOrUndefined(itemsList)
    ? filterBySearchQuery(searchQuery, itemsList, "searchableName")
    : [];

  useEffect(() => {
    const handleClick = (e) => {
      if (componentRef.current && !componentRef.current.contains(e.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [componentRef]);

  useEffect(() => {
    if (isActive) {
      if (!isNullOrUndefined(inputRef?.current)) {
        inputRef.current.focus();
      }
    }
  }, [isActive]);

  const currentValue = getByFieldValue(items, selectedFieldName, value);

  return (
    <div
      className={`search-selector${isActive ? " active" : ""}`}
      ref={componentRef}
    >
      <div
        className="search-selector-value"
        onClick={(e) => {
          setIsActive((prev) => !prev);
          setSearchQuery("");
          e.preventDefault();
        }}
      >
        <div>
          {!isNullOrUndefined(currentValue) && (
            <>{currentValue[searchableName]}</>
          )}
        </div>
        <div>
          <Caret />
        </div>
      </div>

      {isActive && (
        <div className="search-selector-menu drop-shadow">
          <div className="search-selector-menu-container">
            <div className="search-selector-menu-content">
              <div>
                <div
                  className={`search-selector-menu-search${
                    !isEmptyList(itemsFiltered) > 0 ? " opened" : ""
                  }`}
                >
                  <input
                    type="text"
                    placeholder={t("start_typing")}
                    ref={inputRef}
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && itemsFiltered?.length === 1) {
                        onChangeHandler(itemsFiltered[0]);
                      }
                    }}
                  />
                </div>
                {!isEmptyList(itemsFiltered) && (
                  <div className="search-selector-menu-results">
                    {itemsFiltered.map((item) => (
                      <div
                        key={`${item.uuid}-${makeKeyFriendlyString(
                          item.searchableName
                        )}`}
                        className="search-selector-menu-result"
                        onClick={() => {
                          onChangeHandler(item);
                        }}
                      >
                        <div>{item.searchableName}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="search-selector-menu-actions">
              <div>
                <div></div>
                <div>
                  {allowClear && (
                    <button
                      className="secondary"
                      onClick={() => {
                        onChangeHandler(null);
                      }}
                    >
                      {t("clear")}
                    </button>
                  )}
                </div>
                <div>
                  <button
                    className="secondary"
                    onClick={() => {
                      setIsActive(false);
                    }}
                  >
                    {t("cancel")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchSelector;
