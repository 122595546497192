import { Modal } from "@mui/material";
import { deepCopy, isNullOrUndefined, logAnalyticsEvent } from "../../utils";
import { ReactComponent as Cross } from "../../assets/cross.svg";
import { ReactComponent as CircleCross } from "../../assets/circle_cross.svg";
import { ReactComponent as CriticalIcon } from "../../assets/critical.svg";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TimeInput from "../TimeRangeInput/TimeInput";
import ButtonDatePicker from "../ButtonDatePicker";
import moment from "moment-timezone";
import { ANALYTICS_EVENTS, MIN_DATE } from "../../constants";
import { useMainDispatchContext } from "../../MainContext";
import {
  getTurnaroundDetails,
  patchTurnaroundOperationStartComplete,
} from "../../api";
import { isDateAfter } from "../../dateUtils";

function TurnaroundModuleTimestampModal(props) {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const { operation, onClose, onSave, timezone, selectedTurnaroundUuid } =
    props;
  const [operationForEdit, setOperationForEdit] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (!isNullOrUndefined(operation)) {
      setOperationForEdit(deepCopy(operation));
    }
  }, [operation]);

  function resetAll() {
    setOperationForEdit(null);
    setIsProcessing(false);
    setHasChanges(false);
  }

  function handleClose() {
    onClose();
    resetAll();
  }

  async function handleSave() {
    setIsProcessing(true);
    // Create the patch request
    let patchRequest = {
      completedAt: operationForEdit.completedAt,
      startedAt: operationForEdit.startedAt,
    };

    const result = await patchTurnaroundOperationStartComplete(
      dispatch,
      operationForEdit.uuid,
      patchRequest
    );
    if (result) {
      // Reload the selected turnaround
      await getTurnaroundDetails(dispatch, { uuid: selectedTurnaroundUuid });

      dispatch({
        type: "setAlertMessage",
        alertMessage: t("saved_web", {
          name: operationForEdit.type.name,
        }),
      });
      if (!isNullOrUndefined(onSave)) {
        onSave();
      }
    }
    setIsProcessing(false);
    logAnalyticsEvent(
      dispatch,
      ANALYTICS_EVENTS.TURNAROUNDS_DETAILS_SAVE_OPERATION_TIMESTAMP
    );
    handleClose();
  }

  // If there is a completed date, it must be after the start
  const isValid = !isNullOrUndefined(operationForEdit?.completedAt)
    ? isDateAfter(operationForEdit?.completedAt, operationForEdit?.startedAt)
    : true;

  return (
    <div>
      <Modal open={!isNullOrUndefined(operationForEdit)} onClose={handleClose}>
        <div className="turnaround-timestamp-modal modal modal-small ">
          {!isNullOrUndefined(operationForEdit) && (
            <>
              <div>
                <div className="modal-header">
                  <div>
                    <h2>{`${t("operation")}: ${
                      operationForEdit.type.name
                    }`}</h2>
                    {!isValid && (
                      <div className="messages">
                        <div>
                          <CriticalIcon />
                        </div>
                        <div>{t("completed_time_error_msg")}</div>
                      </div>
                    )}
                  </div>

                  <div className="button-icon" onClick={handleClose}>
                    <Cross />
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="modal-container">
                  <div className="turnaround-module-timestamp-content">
                    <TurnaroundTimeStampRow
                      label={t("started").toLowerCase()}
                      timeValue={operationForEdit.startedAt}
                      timezone={timezone}
                      onChange={(timeValue) => {
                        const updatedOperation = deepCopy(operationForEdit);
                        updatedOperation.startedAt = timeValue;
                        setOperationForEdit(updatedOperation);
                        setHasChanges(
                          operation.startedAt !== updatedOperation.startedAt ||
                            operation.completedAt !==
                              updatedOperation.completedAt
                        );
                      }}
                      isClearAllowed={false}
                    />
                    <TurnaroundTimeStampRow
                      label={t("completed").toLowerCase()}
                      timeValue={operationForEdit.completedAt}
                      timezone={timezone}
                      onChange={(timeValue) => {
                        const updatedOperation = deepCopy(operationForEdit);
                        updatedOperation.completedAt = timeValue;
                        setOperationForEdit(updatedOperation);
                        setHasChanges(
                          operation.startedAt !== updatedOperation.startedAt ||
                            operation.completedAt !==
                              updatedOperation.completedAt
                        );
                      }}
                      isClearAllowed={false}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="modal-footer">
                  <div>
                    <button className="secondary" onClick={handleClose}>
                      {t("cancel")}
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={handleSave}
                      disabled={isProcessing || !hasChanges || !isValid}
                    >
                      {t("save")}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}

function TurnaroundTimeStampRow(props) {
  const { t } = useTranslation();
  const { label, timeValue, timezone, onChange, isClearAllowed } = props;
  const mNow = moment().tz(timezone);

  const mToday = moment().tz(timezone).startOf("day");
  const mTimeValue = !isNullOrUndefined(timeValue)
    ? moment(timeValue).tz(timezone)
    : null;
  return (
    <div className="turnaround-module-timestamp-row">
      <div>{label}</div>
      <div>
        {isNullOrUndefined(timeValue) && (
          <div className="turnaround-module-timestamp-empty">
            <button
              onClick={() => {
                onChange(mNow.toDate().toISOString());
              }}
              className="secondary"
            >
              {t("select_date_time")}
            </button>
          </div>
        )}
        {!isNullOrUndefined(timeValue) && (
          <div
            className={`turnaround-module-timestamp-input${
              isClearAllowed ? " clearable" : ""
            }`}
          >
            <div>
              <TimeInput
                timeValue={timeValue}
                onTimeChange={(dateStrVal) => {
                  const newVal = moment(dateStrVal).tz(timezone);
                  const updatedVal = moment(mTimeValue).tz(timezone);
                  updatedVal.set("hours", newVal.hours());
                  updatedVal.set("minutes", newVal.minutes());
                  onChange(updatedVal.toDate().toISOString());
                }}
                timezone={timezone}
              />
            </div>
            <div>
              <ButtonDatePicker
                label={
                  timeValue == null ? null : mTimeValue.format("MM/DD/YYYY")
                }
                value={mTimeValue}
                onChange={(newValue) => {
                  onChange(newValue.toDate().toISOString());
                }}
                minDate={moment(MIN_DATE)}
                maxDate={moment(mToday).add(2, "month")}
              />
            </div>
            {isClearAllowed && (
              <div>
                <div
                  className="time-input-clear"
                  onClick={() => {
                    onChange(null);
                  }}
                >
                  <CircleCross />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
export default TurnaroundModuleTimestampModal;
