import { getTurnaroundInfoForTurnaroundSummary } from "../../turnaroundUtils";
import { getUserInfo } from "../../userUtils";
import {
  formatTime,
  getByFieldValue,
  isEmptyList,
  isNullOrUndefined,
} from "../../utils";
import moment from "moment-timezone";
import i18next from "../../i18n";

export function getTaskInfo(sourceTask, operationTypes, turnaroundsSummary) {
  const operationType = getByFieldValue(
    operationTypes,
    "uuid",
    sourceTask.operationTypeUuid
  );
  const turnaround = getByFieldValue(
    turnaroundsSummary,
    "uuid",
    sourceTask.turnaroundUuid
  );
  const turnaroundInfo = !isNullOrUndefined(turnaround)
    ? getTurnaroundInfoForTurnaroundSummary(turnaround)
    : null;

  const mStart = sourceTask?.expectedStartTime
    ? moment(sourceTask?.expectedStartTime).startOf("minute")
    : null;
  const mEnd = sourceTask?.expectedEndTime
    ? moment(sourceTask?.expectedEndTime).startOf("minute")
    : null;
  const durationInMinutes =
    !isNullOrUndefined(mEnd) && !isNullOrUndefined(mStart)
      ? mEnd.diff(mStart, "minutes")
      : 0;

  // Task is considered confirmed if assigned manually or has been dispatched
  const isConfirmed =
    !isNullOrUndefined(sourceTask?.createdByUserUuid) ||
    !isNullOrUndefined(sourceTask?.dispatchedAt);

  if (!isNullOrUndefined(operationType) && !isNullOrUndefined(turnaroundInfo)) {
    return {
      ...sourceTask,
      turnaroundInfo: turnaroundInfo,
      operationType: operationType,
      duration: durationInMinutes,
      isConfirmed: isConfirmed,
    };
  }
  return null;
}

export function getRequirementsToPatch(slotsToEdit, origOperation) {
  const requirementsToPatch = [];
  for (let i = 0; i < slotsToEdit.length; i++) {
    const slot = slotsToEdit[i];
    const requirement = slot.requirement;

    // Original userAssignments
    const origRequirement = !isNullOrUndefined(
      origOperation?.turnaroundRequirements
    )
      ? getByFieldValue(
          origOperation.turnaroundRequirements,
          "uuid",
          requirement.uuid
        )
      : null;
    const origAssignments = origRequirement?.crewAssignments;
    const origUserAssignments = !isEmptyList(origAssignments)
      ? origAssignments.map((i) => i.userUuid)
      : [];

    const requirementToPatch = {
      uuid: requirement.uuid,
      userUuidsToAdd: [],
      userUuidsToRemove: [],
    };
    const userUuidsToKeep = [];

    // Check assignments to ADD
    const slotAssignments = slot.assignments;
    if (!isEmptyList(slotAssignments)) {
      for (let j = 0; j < slotAssignments.length; j++) {
        const assignment = slotAssignments[j];
        const userUuid = assignment?.assignedUser?.uuid;
        if (!isNullOrUndefined(userUuid)) {
          if (!origUserAssignments.includes(userUuid)) {
            requirementToPatch.userUuidsToAdd.push(userUuid);
          } else {
            userUuidsToKeep.push(userUuid);
          }
        }
      }
    }

    // Check assignments to REMOVE
    if (!isEmptyList(origUserAssignments)) {
      for (let j = 0; j < origUserAssignments.length; j++) {
        const origUserAssignment = origUserAssignments[j];
        if (
          !userUuidsToKeep.includes(origUserAssignment) &&
          !requirementToPatch.userUuidsToAdd.includes(origUserAssignment)
        ) {
          // User was not added or kept therefore remove
          requirementToPatch.userUuidsToRemove.push(origUserAssignment);
        }
      }
    }

    if (
      !isEmptyList(requirementToPatch.userUuidsToAdd) ||
      !isEmptyList(requirementToPatch.userUuidsToRemove)
    ) {
      requirementsToPatch.push(requirementToPatch);
    }
  }
  return requirementsToPatch;
}

export function getTaskDetail(taskToRender, users, timezone) {
  let taskDetail = null;
  if (taskToRender.completed) {
    taskDetail = i18next.t("completed_non_cap");
  } else if (taskToRender.started) {
    taskDetail = i18next.t("in_progress");
  } else if (!isNullOrUndefined(taskToRender?.dispatchedAt)) {
    taskDetail = i18next.t("dispatched_at_web", {
      value: formatTime(taskToRender?.dispatchedAt, timezone),
    });
  } else {
    taskDetail = i18next.t("subject_to_change_non_cap");
  }

  // Show the assigned by
  if (!isNullOrUndefined(taskToRender?.createdByUserUuid)) {
    const createdByUser = getByFieldValue(
      users,
      "uuid",
      taskToRender.createdByUserUuid
    );

    const createdByUserInfo = !isNullOrUndefined(createdByUser)
      ? getUserInfo(createdByUser)
      : null;

    const taskAdditionalDetail = i18next.t("assigned_by_web", {
      name: !isNullOrUndefined(createdByUserInfo)
        ? createdByUserInfo.fullName
        : i18next.t("na"),
    });
    if (isNullOrUndefined(taskDetail)) {
      taskDetail = taskAdditionalDetail;
    } else {
      taskDetail = `${taskDetail} - ${taskAdditionalDetail}`;
    }
  }
  return taskDetail;
}

export function isCurrentlyOnShift(timezone, userShifts) {
  if (isEmptyList(userShifts)) return false;
  const mNow = moment().tz(timezone);
  for (let i = 0; i < userShifts.length; i++) {
    const userShift = userShifts[i];
    const mShiftStart = moment(userShift.expectedStartTime).tz(timezone);
    const mShiftEnd = moment(userShift.expectedEndTime).tz(timezone);
    if (mNow.isBetween(mShiftStart, mShiftEnd, null, "[]")) {
      // Currently on-shift
      return true;
    }
  }
  return false;
}
