import { useTranslation } from "react-i18next";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { ReactComponent as Arrival } from "../../assets/turnarounds/arrival.svg";
import { ReactComponent as Departure } from "../../assets/turnarounds/departure.svg";
import { ReactComponent as Turnaround } from "../../assets/turnarounds/turnaround.svg";
import { ANALYTICS_EVENTS, DEFAULT_TIMEZONE } from "../../constants";
import {
  getAircraftInfoForMap,
  getFlightByRegistration,
  handleHover,
  MAPINFO_MARKER_TYPE,
} from "../../mapUtils";
import {
  getByFieldValue,
  isBlank,
  isEmptyList,
  isNullOrUndefined,
  logAnalyticsEvent,
} from "../../utils";
import TurnaroundProgressBar from "../Turnarounds/TurnaroundProgressBar";
import {
  getCriticalOperationsFromTurnaround,
  getTurnaroundInfo,
} from "../../turnaroundUtils";
import {
  getTurnaroundsSummary,
  patchTurnaroundOperationAckStatus,
} from "../../api";
import { useEffect } from "react";
import CriticalMessages from "../Confirmation/CriticalMessages";
import {
  ResourceActions,
  userHasAnyResourceAction,
} from "../../resourceActionsUtils";
import FlightTimeValue from "../FlightValues/FlightTimeValue";
import FlightStandValue from "../FlightValues/FlightStandValue";
import { getResourcesAtStand } from "./mapHelpers";

function TurnaroundInfoModule(props) {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { turnaroundInfo, isSelected, positions } = props;
  const { currentUser, turnaroundDetailsForConfirm, flightsToRender } =
    mainContext;
  const airport = currentUser?.airport;
  const airportTimezone = !isNullOrUndefined(airport)
    ? airport.timezone
    : DEFAULT_TIMEZONE;

  const inboundName = !isNullOrUndefined(turnaroundInfo.inboundFlightName)
    ? turnaroundInfo.inboundFlightName
    : "-";
  const outboundName = !isNullOrUndefined(turnaroundInfo.outboundFlightName)
    ? turnaroundInfo.outboundFlightName
    : "-";

  const hasViewTurnProfile = userHasAnyResourceAction(
    currentUser,
    ResourceActions.ViewTurnProfile
  );

  const hasOutbound = !isBlank(turnaroundInfo.outboundFlightName);

  const flightInfo = !isNullOrUndefined(turnaroundInfo)
    ? getFlightByRegistration(flightsToRender, turnaroundInfo?.registration)
    : null;
  const aircraft = flightInfo?.aircraft;
  const aircraftInfo = !isNullOrUndefined(flightInfo)
    ? getAircraftInfoForMap(flightInfo, aircraft, turnaroundInfo)
    : null;

  useEffect(() => {
    if (!isNullOrUndefined(turnaroundDetailsForConfirm)) {
      const turnaroundInfo = getTurnaroundInfo(turnaroundDetailsForConfirm);
      const criticalOperations =
        getCriticalOperationsFromTurnaround(turnaroundInfo);
      const criticalOperationUuids = !isEmptyList(criticalOperations)
        ? criticalOperations.map((item) => item.uuid)
        : [];

      // Callback function when the user confirms
      async function handleConfirmations() {
        dispatch({
          type: "setConfirmationProcessing",
          confirmationProcessing: true,
        });
        for (let i = 0; i < criticalOperationUuids.length; i++) {
          const criticalOperationUuid = criticalOperationUuids[i];
          await patchTurnaroundOperationAckStatus(
            dispatch,
            criticalOperationUuid
          );
        }
        // Refresh the summaries
        await getTurnaroundsSummary(dispatch);

        // Completed message
        let dispatchMessage = `${t("saved_web", {
          name: turnaroundInfo.combinedFlightName,
        })}`;
        dispatch({
          type: "setAlertMessage",
          alertMessage: dispatchMessage,
        });

        dispatch({
          type: "setConfirmation",
          confirmation: null,
        });
      }

      dispatch({
        type: "setConfirmation",
        confirmation: {
          message: <CriticalMessages turnaroundInfo={turnaroundInfo} />,
          actionLabel: t("confirm_non_cap"),
          actionOK: () => {
            handleConfirmations();
          },
          actionCancel: () => {
            dispatch({
              type: "setConfirmation",
              confirmation: null,
            });
          },
        },
      });

      // Cleanup
      dispatch({
        type: "setTurnaroundDetailsForConfirm",
        value: null,
      });
    }
  }, [t, dispatch, turnaroundDetailsForConfirm]);

  const resourcesAtStand = getResourcesAtStand(
    turnaroundInfo?.registration,
    positions
  );
  const gseAtStandCount = !isEmptyList(resourcesAtStand?.groundVehicles)
    ? resourcesAtStand.groundVehicles.length
    : 0;
  const usersAtStandCount = !isEmptyList(resourcesAtStand?.users)
    ? resourcesAtStand.users.length
    : 0;

  const showResources = hasViewTurnProfile && turnaroundInfo?.isInProgress;
  const showRemarks = !isBlank(turnaroundInfo?.remarks);
  const showAdditionalNotes = showResources || showRemarks;

  const aircraftPosition = !isEmptyList(positions?.aircrafts)
    ? getByFieldValue(
        positions?.aircrafts,
        "registration",
        turnaroundInfo?.registration
      )
    : null;

  return (
    <div
      className={`overlay-module${
        turnaroundInfo?.isCritical ? ` status-critical` : ""
      }${isSelected ? " selected" : ""}`}
      data-id={turnaroundInfo.uuid}
      data-inbound-id={turnaroundInfo?.inboundFlightId}
      data-outbound-id={turnaroundInfo?.outboundFlightId}
      data-registration={turnaroundInfo?.registration}
      onClick={() => {
        dispatch({
          type: "setShowOnMap",
          value: {
            markerType: MAPINFO_MARKER_TYPE.AIRCRAFT,
            item: {
              aircraft: {
                registration: turnaroundInfo.registration,
              },
            },
          },
        });
        logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.MAP_VIEW_TURNAROUND_TILE);
      }}
      onMouseOver={(e) => {
        handleHover(true, aircraftInfo);
      }}
      onMouseOut={(e) => {
        handleHover(false, aircraftInfo);
      }}
    >
      <div className="overlay-module-box">
        <div className="turnaround-flight-info">
          <div>
            <Arrival />
          </div>
          <div>
            <div className="value-pair">
              <label>{t("inbound")}</label>
              <div className="value">
                <div className="turnaround-flight">
                  <div>{inboundName}</div>
                  <div className="turnaround-flight-details">
                    {turnaroundInfo?.registration}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="value-pair">
              <label>
                {t(
                  turnaroundInfo?.resolvedInboundTimes?.displayTimeLabel
                    ?.inbound
                )}
              </label>
              <div className="value">
                <FlightTimeValue
                  resolvedFlightTimes={turnaroundInfo.resolvedInboundTimes}
                  timezone={airportTimezone}
                  showPlusDays={true}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="value-pair">
              <label>{t("origin_non_cap")}</label>
              <div className="value">
                {turnaroundInfo.inboundFlight?.originAirportIata}
              </div>
            </div>
          </div>
          <div>
            <div className="value-pair">
              <label>{t("stand_non_cap")}</label>
              <div className="value">
                <FlightStandValue
                  item={turnaroundInfo}
                  aircraftPosition={aircraftPosition}
                />
              </div>
            </div>
          </div>
        </div>
        {hasViewTurnProfile && (
          <div className="turnaround-info">
            <div>
              <Turnaround />
            </div>
            <div>
              <TurnaroundProgressBar
                turnaroundInfo={turnaroundInfo}
                turnaroundLevelOperationStatus={true}
                showOperationStatus={false}
              />
            </div>
          </div>
        )}
        {hasOutbound && (
          <div className="turnaround-flight-info">
            <div>
              <Departure />
            </div>
            <div>
              <div className="value-pair">
                <label>{t("outbound")}</label>
                <div className="value">
                  <div className="turnaround-flight">
                    <div>{outboundName}</div>
                    <div className="turnaround-flight-details">
                      {turnaroundInfo?.registration}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="value-pair">
                <label>
                  {t(
                    turnaroundInfo?.resolvedOutboundTimes?.displayTimeLabel
                      ?.outbound
                  )}
                </label>
                <div className="value">
                  <FlightTimeValue
                    resolvedFlightTimes={turnaroundInfo.resolvedOutboundTimes}
                    timezone={airportTimezone}
                    showPlusDays={true}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="value-pair">
                <label>{t("destination_non_cap")}</label>
                <div className="value">
                  {turnaroundInfo.outboundFlight?.destAirportIata}
                </div>
              </div>
            </div>
            <div></div>
          </div>
        )}
        {showAdditionalNotes && (
          <div className="turnaround-additional-notes">
            {showRemarks && (
              <div className="turnaround-remarks">
                <div>{turnaroundInfo.remarks}</div>
              </div>
            )}
            {showResources && (
              <div className="turnaround-resources-info">
                <div>
                  <div>{t("resources_at_aircraft")}</div>
                  <div className="resource-count">
                    {gseAtStandCount}
                    <span>{t("gse")}</span>
                  </div>
                  <div className="resource-count">
                    {usersAtStandCount}
                    <span>{t("crew")}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default TurnaroundInfoModule;
