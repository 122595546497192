import moment from "moment-timezone";
import { getTimezoneFromUser } from "../../utils";
import { getDayInitial } from "../../dateUtils";
import { Fade, Tooltip } from "@mui/material";
import CrewShiftsOutlookDetail from "./CrewShiftsOutlookDetail";
import { tooltipStyles } from "../../styleUtils";
import { hasCrewShiftsForDate } from "../../crewShiftUtils";
import { useCrewShiftsContext } from "../../CrewShiftsContext";
import { useMainContext } from "../../MainContext";

function CrewShiftsOutlook(props) {
  const { user } = props;
  const mainContext = useMainContext();
  const crewShiftsContext = useCrewShiftsContext();
  const { crewShifts } = crewShiftsContext;

  const { currentUser } = mainContext;
  const airportTimezone = getTimezoneFromUser(currentUser);

  const mDay = moment().tz(airportTimezone).startOf("week");
  const crewShiftsToRender = [];
  for (let i = 0; i < 7; i++) {
    crewShiftsToRender.push({
      dayName: mDay.day(),
      dateVal: getDayInitial(mDay),
      hasCrewShift: hasCrewShiftsForDate(
        user,
        crewShifts,
        mDay,
        airportTimezone
      ),
    });
    mDay.add(1, "day");
  }

  return (
    <Tooltip
      title={
        <CrewShiftsOutlookDetail
          crewShiftModalUser={user}
          crewShifts={crewShifts}
        />
      }
      placement="top"
      arrow
      componentsProps={tooltipStyles}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 500 }}
      key={user.uuid}
    >
      <div className="crew-shifts-outlook">
        {crewShiftsToRender &&
          crewShiftsToRender.map((crewShift, idx) => (
            <div
              key={`day_${idx}`}
              className={`${crewShift.hasCrewShift ? "on-shift" : ""}`}
            >
              {crewShift.dateVal}
            </div>
          ))}
      </div>
    </Tooltip>
  );
}

export default CrewShiftsOutlook;
