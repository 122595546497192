import {
  getAdjustedRotation,
  getWingspanInPixels,
  MAP_ZOOM_LEVELS,
  MAPINFO_MARKER_TYPE,
} from "../../mapUtils";
import { isEmptyList, isNullOrUndefined } from "../../utils";
import PinpointArrow from "../../assets/map/pinpoint-arrow.svg";
import AgentProfilePic from "../../assets/profile-pic-dark.svg";
import AgentEmptyAvailable from "../../assets/map/agent-empty-available.svg";
import Plane from "../../assets/map/plane.svg";
import PlaneMedium from "../../assets/map/plane-medium.svg";
import PlaneLarge from "../../assets/map/plane-large.svg";
import { getSignedImageSrc } from "../../mediaUtils";

export function updateMapMarker(options) {
  const { markerInfo, existingMarker } = options;
  const mapInfo = markerInfo?.mapInfo;
  // Just position the existing marker
  existingMarker.setLngLat([
    mapInfo.position.longitude,
    mapInfo.position.latitude,
  ]);
}
export function addMapMarker(mapboxgl, mapRef, options) {
  const { type, markerInfo, clickHandler, mouseoverHandler, mouseoutHandler } =
    options;
  const mapInfo = markerInfo?.mapInfo;
  const markerClass = `marker-image-anchor`;
  const markerImageBoxEl = document.createElement("div");
  markerImageBoxEl.className = markerClass;
  markerImageBoxEl.setAttribute("data-asset-type", type);
  const markerUserPic = document.createElement("div");

  // Generate the proper icon image and content
  const markerImageEl = document.createElement("img");
  if (type === MAPINFO_MARKER_TYPE.VEHICLE) {
    markerImageEl.src = PinpointArrow;
  } else if (type === MAPINFO_MARKER_TYPE.USER) {
    const profileImageBoxEl = document.createElement("div");
    // The main image is just an empty circle with status
    // Users on the map always appear as available (GREEN)
    markerImageEl.src = AgentEmptyAvailable;
    const userProfilePic = getSignedImageSrc(
      markerInfo?.profilePicture?.thumbnail
    );
    // Create an element with the profile pic
    markerUserPic.className = "marker-user-pic";
    markerUserPic.style.position = "relative";
    markerUserPic.style.marginBottom = "2px";
    // Specify the profile image and the default fallback image
    profileImageBoxEl.style.backgroundImage = `url(${userProfilePic}), url(${AgentProfilePic})`;
    profileImageBoxEl.style.width = "18px";
    profileImageBoxEl.style.height = "18px";
    profileImageBoxEl.style.borderRadius = "18px";
    profileImageBoxEl.style.backgroundSize = "cover";
    profileImageBoxEl.style.backgroundPosition = "center";
    profileImageBoxEl.style.overflow = "hidden";

    // Additional styles to overlay the empty circle above the profile pic
    markerImageEl.style.position = "absolute";
    markerImageEl.style.width = "20px";
    markerImageEl.style.height = "20px";
    markerImageEl.style.top = "-1px";
    markerImageEl.style.left = "-1px";

    // Append user pic
    markerUserPic.appendChild(profileImageBoxEl);
  } else if (type === MAPINFO_MARKER_TYPE.AIRCRAFT) {
    const zoom = mapRef.current.getZoom();
    if (zoom > MAP_ZOOM_LEVELS.AIRCRAFT) {
      markerImageEl.src = PlaneLarge;
    } else if (zoom > MAP_ZOOM_LEVELS.AIRPORT) {
      markerImageEl.src = PlaneMedium;
    } else {
      markerImageEl.src = Plane;
    }
    markerImageEl.className = "aircraft-icon";
    markerImageEl.alt = `${markerInfo.registration} ${mapInfo.combinedFlightName} (lng: ${mapInfo.position.longitude} lat:${mapInfo.position.latitude})`;
    const aircraftLat = mapInfo.position.latitude;
    const wingspanInPixels = getWingspanInPixels(zoom, aircraftLat);
    markerImageEl.style.width = `${wingspanInPixels}px`;
    markerImageEl.style.height = `${wingspanInPixels}px`;

    // Aircraft has custom rotation handling
    const adjustedRotation = getAdjustedRotation(mapRef, mapInfo);
    markerImageEl.style.transform = `rotate(${adjustedRotation}deg) translate(0, ${
      wingspanInPixels / 2
    }px)`;
  }
  if (
    type !== MAPINFO_MARKER_TYPE.AIRCRAFT &&
    !isNullOrUndefined(mapInfo?.position?.heading)
  ) {
    const adjustedRotation = getAdjustedRotation(mapRef, mapInfo);
    markerImageEl.style.transform = `rotate(${adjustedRotation}deg)`;
  }
  if (!isNullOrUndefined(markerUserPic)) {
    markerImageBoxEl.appendChild(markerUserPic);
  }
  markerImageBoxEl.appendChild(markerImageEl);
  const labelId =
    type === MAPINFO_MARKER_TYPE.AIRCRAFT
      ? markerInfo.registration
      : markerInfo.uuid;

  markerImageEl.classList.add("hoverable");
  markerImageBoxEl.setAttribute("data-anchor-id", labelId);

  // Mouse event handlers
  markerImageEl.addEventListener("mouseover", (e) => {
    if (!isNullOrUndefined(mouseoverHandler)) mouseoverHandler();
  });
  markerImageEl.addEventListener("mouseout", (e) => {
    if (!isNullOrUndefined(mouseoutHandler)) mouseoutHandler();
  });

  // Click handlers
  markerImageEl.addEventListener("click", (e) => {
    clickHandler();
    e.stopPropagation();
  });

  const marker = new mapboxgl.Marker(markerImageBoxEl)
    .setLngLat([mapInfo.position.longitude, mapInfo.position.latitude])
    .setPitchAlignment("map");
  marker.addTo(mapRef.current);
  return marker;
}

export function clearHoverStates() {
  const hoveredEls = document.querySelectorAll(".hovered");
  if (!isEmptyList(hoveredEls)) {
    hoveredEls.forEach((el) => {
      el.classList.remove("hovered");
    });
  }
}

// // Handle hover events
// export function handleHover(isHovered, markerInfo) {
//   const type = markerInfo.mapInfo.markerType;
//   const labelId =
//     type === MAPINFO_MARKER_TYPE.AIRCRAFT
//       ? markerInfo.registration
//       : markerInfo.uuid;
//   let anchorEl = document.querySelector(`[data-anchor-id='${labelId}']`);
//   let labelEl = document.querySelector(`[data-label-id='${labelId}']`);
//    let labelElMarker = !isNullOrUndefined(labelEl)
//     ? labelEl.closest(".marker-label-anchor")
//     : null;

//   if (!isNullOrUndefined(labelElMarker)) {
//     if (isHovered) {
//       labelElMarker.classList.add("hovered");
//     } else {
//       clearHoverStates();
//     }
//   }
//   if (!isNullOrUndefined(anchorEl)) {
//     if (isHovered) {
//       anchorEl.classList.add("hovered");
//     } else {
//       clearHoverStates();
//     }
//   }
// }
