import { useEffect } from "react";
import { useMainDispatchContext } from "../../MainContext";
import ChatModule, { ChatModuleMode } from "../Chat/ChatModule";
import { CHAT_CATEGORY_TYPES } from "../../commsUtils";

function TurnaroundChatHistory(props) {
  const { selectedTurnaroundInfo } = props;
  const dispatch = useMainDispatchContext();
  useEffect(() => {
    dispatch({
      type: "setSelectedChatRequest",
      value: {
        type: CHAT_CATEGORY_TYPES.TURNAROUND,
        channelId: selectedTurnaroundInfo.chatChannelId,
        selectedTurnaround: selectedTurnaroundInfo,
      },
    });
  }, [dispatch, selectedTurnaroundInfo]);
  const isChatReadonly = selectedTurnaroundInfo?.finalized;
  return (
    <div className="turnaround-chat-history">
      <ChatModule
        mode={ChatModuleMode.COMPACT}
        isActive={true}
        isReadonly={isChatReadonly}
      />
    </div>
  );
}

export default TurnaroundChatHistory;
