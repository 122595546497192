import { useTranslation } from "react-i18next";
import { isEmptyList, sortByField } from "../../utils";
import TurnaroundModuleServiceRecordRows from "./TurnaroundModuleServiceRecordRows";

function TurnaroundModuleServiceRecords(props) {
  const { t } = useTranslation();
  const { selectedTurnaroundInfo, timezone, mode } = props;

  const userRecords = selectedTurnaroundInfo?.userRecords;
  const gseRecords = selectedTurnaroundInfo?.gseRecords;
  if (!isEmptyList(userRecords)) {
    sortByField(userRecords, "user.firstName", ["user.lastName"]);
  }
  if (!isEmptyList(gseRecords)) {
    sortByField(gseRecords, "groundVehicle.name");
  }

  return (
    <div className="turnarounds-module-service-records">
      {mode === "crew" && (
        <>
          {isEmptyList(userRecords) && (
            <div className="empty-state">
              <div>{t("no_results")}</div>
            </div>
          )}
          {!isEmptyList(userRecords) && (
            <>
              <div className="turnarounds-module-service-records-header">
                <div>
                  <div>{t("name")}</div>
                </div>
                <div>
                  <div>{t("arrived")}</div>
                </div>
                <div>
                  <div>{t("departed_non_cap")}</div>
                </div>
                <div>
                  <div>{t("duration")}</div>
                </div>
              </div>
              {userRecords &&
                userRecords.map((item) => {
                  return (
                    <TurnaroundModuleServiceRecordRows
                      key={item.user.uuid}
                      item={item}
                      timezone={timezone}
                    />
                  );
                })}
            </>
          )}
        </>
      )}
      {mode === "gse" && (
        <>
          {isEmptyList(gseRecords) && (
            <div className="empty-state">
              <div>{t("no_results")}</div>
            </div>
          )}
          {!isEmptyList(gseRecords) && (
            <>
              <div className="turnarounds-module-service-records-header">
                <div>
                  <div>{t("name")}</div>
                </div>
                <div>
                  <div>{t("arrived")}</div>
                </div>
                <div>
                  <div>{t("departed_non_cap")}</div>
                </div>
                <div>
                  <div>{t("duration")}</div>
                </div>
              </div>
              {gseRecords &&
                gseRecords.map((item) => {
                  return (
                    <TurnaroundModuleServiceRecordRows
                      key={item.groundVehicle.uuid}
                      item={item}
                      timezone={timezone}
                    />
                  );
                })}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default TurnaroundModuleServiceRecords;
