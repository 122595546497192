import { OperationOffsetType } from "./turnaroundUtils";
import {
  getByFieldValue,
  isEmptyList,
  isNullOrUndefined,
  sortByField,
} from "./utils";

export const MAX_TIMELINE_DURATION_MINS = 4 * 60;

export function getTimelineFromTemplate(templateToEdit, targetBlockTime) {
  const preArrival = [];
  const postArrival = [];
  const preDeparture = [];
  const postDeparture = [];
  const fullDuration = [];
  if (isNullOrUndefined(templateToEdit)) return null;

  let maxPreArrival = 0;
  let maxPostArrival = 0;
  let maxPreDeparture = 0;
  let maxPostDeparture = 0;
  for (let i = 0; i < templateToEdit.turnaroundOperations.length; i++) {
    const turnaroundOperation = templateToEdit.turnaroundOperations[i];
    if (
      turnaroundOperation.enabled &&
      turnaroundOperation.offsetTypeId === OperationOffsetType.INBOUND
    ) {
      if (turnaroundOperation.startOffsetMinutes < 0) {
        preArrival.push(turnaroundOperation);
        const duration = turnaroundOperation.duration || 0;
        const preArrivalAmt = turnaroundOperation.startOffsetMinutes * -1;
        if (preArrivalAmt > maxPreArrival) {
          maxPreArrival = preArrivalAmt;
        }
        const postArrivalAmt =
          turnaroundOperation.startOffsetMinutes + duration;
        if (postArrivalAmt > maxPostArrival) {
          maxPostArrival = postArrivalAmt;
        }
      } else {
        postArrival.push(turnaroundOperation);
        const duration = turnaroundOperation.duration || 0;
        const postArrivalAmt =
          turnaroundOperation.startOffsetMinutes + duration;
        if (postArrivalAmt > maxPostArrival) {
          maxPostArrival = postArrivalAmt;
        }
      }
    } else if (
      turnaroundOperation.enabled &&
      turnaroundOperation.offsetTypeId === OperationOffsetType.OUTBOUND
    ) {
      if (turnaroundOperation.startOffsetMinutes < 0) {
        preDeparture.push(turnaroundOperation);
        const duration = turnaroundOperation.duration || 0;
        const preDepartureAmt = turnaroundOperation.startOffsetMinutes * -1;
        if (preDepartureAmt > maxPreDeparture) {
          maxPreDeparture = preDepartureAmt;
        }
        const postDepartureAmt =
          turnaroundOperation.startOffsetMinutes + duration;
        if (postDepartureAmt > maxPostDeparture) {
          maxPostDeparture = postDepartureAmt;
        }
      } else {
        postDeparture.push(turnaroundOperation);
        const duration = turnaroundOperation.duration || 0;
        const postDepartureAmt =
          turnaroundOperation.startOffsetMinutes + duration;
        if (postDepartureAmt > maxPostDeparture) {
          maxPostDeparture = postDepartureAmt;
        }
      }
    } else if (
      turnaroundOperation.enabled &&
      turnaroundOperation.offsetTypeId === OperationOffsetType.FULL
    ) {
      fullDuration.push(turnaroundOperation);
    }
  }
  sortByField(fullDuration, "name");
  sortByField(preArrival, "startOffsetMinutes", ["name"]);
  sortByField(postArrival, "startOffsetMinutes", ["name"]);
  sortByField(preDeparture, "startOffsetMinutes", ["name"]);
  sortByField(postDeparture, "startOffsetMinutes", ["name"]);

  let targetBlockTimeValue = !isNaN(parseInt(targetBlockTime))
    ? parseInt(targetBlockTime)
    : 0;
  if (targetBlockTimeValue > MAX_TIMELINE_DURATION_MINS) {
    targetBlockTimeValue = MAX_TIMELINE_DURATION_MINS;
  }
  const minInnerDuration = Math.max(
    maxPostArrival,
    maxPreDeparture,
    targetBlockTimeValue
  );
  const minOuterDuration = maxPreArrival + minInnerDuration + maxPostDeparture;

  const timelineOperations = [];

  for (let i = 0; i < fullDuration.length; i++) {
    const operation = fullDuration[i];
    const item = {
      operation: operation,
      timeFromAbsStart: 0,
    };
    timelineOperations.push(item);
  }
  for (let i = 0; i < preArrival.length; i++) {
    const operation = preArrival[i];
    const item = {
      operation: operation,
      timeFromAbsStart: maxPreArrival - Math.abs(operation.startOffsetMinutes),
    };
    timelineOperations.push(item);
  }
  for (let i = 0; i < postArrival.length; i++) {
    const operation = postArrival[i];
    const item = {
      operation: operation,
      timeFromAbsStart: maxPreArrival + operation.startOffsetMinutes,
    };
    timelineOperations.push(item);
  }
  for (let i = 0; i < preDeparture.length; i++) {
    const operation = preDeparture[i];
    const item = {
      operation: operation,
      timeFromAbsStart:
        maxPreArrival +
        minInnerDuration -
        Math.abs(operation.startOffsetMinutes),
    };
    timelineOperations.push(item);
  }
  for (let i = 0; i < postDeparture.length; i++) {
    const operation = postDeparture[i];
    const item = {
      operation: operation,
      timeFromAbsStart:
        maxPreArrival +
        minInnerDuration +
        Math.abs(operation.startOffsetMinutes),
    };
    timelineOperations.push(item);
  }

  return {
    minInnerDuration: minInnerDuration,
    minOuterDuration: minOuterDuration,
    maxPreArrival,
    maxPostArrival,
    maxPreDeparture,
    maxPostDeparture,
    preArrival: preArrival,
    postArrival: postArrival,
    preDeparture: preDeparture,
    postDeparture: postDeparture,
    operationsToRender: timelineOperations,
  };
}

// Handle updating the Turnaround Template
// Includes create/update/delete Operations and Requirements
export function getTemplatePatchRequest(originalTemplate, templateToUpdate) {
  const templatePatchRequest = {
    uuid: templateToUpdate.uuid,
    operationUuidsToRemove: [],
    operations: [],
  };

  // Check if any operations have been updated
  const origOperations = originalTemplate.turnaroundOperations;
  const updatedOperations = templateToUpdate.turnaroundOperations;
  if (!isEmptyList(updatedOperations)) {
    for (let i = 0; i < updatedOperations.length; i++) {
      const updatedOperation = updatedOperations[i];
      const origOperation = getByFieldValue(
        origOperations,
        "uuid",
        updatedOperation.uuid
      );
      const isCreateOp = isNullOrUndefined(origOperation);
      const isUpdateOp = hasOperationChanges(origOperation, updatedOperation);
      if (isCreateOp || isUpdateOp) {
        const updatedTurnaroundRequirements = !isEmptyList(
          updatedOperation.turnaroundRequirements
        )
          ? updatedOperation.turnaroundRequirements.map(
              (updatedRequirement) => {
                const origRequirement = getByFieldValue(
                  origOperation?.turnaroundRequirements,
                  "uuid",
                  updatedRequirement.uuid
                );

                // If there wasn't already this requirement, it should CREATE
                const isCreateRequirement = isNullOrUndefined(origRequirement);

                return {
                  quantity: updatedRequirement?.quantity,
                  userCertificationUuid:
                    updatedRequirement?.certification?.uuid,
                  uuid: isCreateRequirement ? "" : updatedRequirement?.uuid,
                };
              }
            )
          : [];

        templatePatchRequest.operations.push({
          departmentUuid: updatedOperation?.department?.uuid,
          duration: updatedOperation?.duration,
          enabled: updatedOperation?.enabled,
          offsetTypeId: updatedOperation?.offsetTypeId,
          operationTypeUuid: updatedOperation?.type?.uuid,
          startOffsetMinutes: updatedOperation?.startOffsetMinutes,
          turnaroundRequirements: updatedTurnaroundRequirements,
          uuid: isCreateOp ? "" : updatedOperation?.uuid,
        });
      }
    }
  }

  // Check for operations to delete
  if (!isEmptyList(origOperations)) {
    for (let i = 0; i < origOperations.length; i++) {
      const origOperation = origOperations[i];
      const updatedOperation = getByFieldValue(
        updatedOperations,
        "uuid",
        origOperation.uuid
      );
      if (isNullOrUndefined(updatedOperation)) {
        // Delete operation
        templatePatchRequest.operationUuidsToRemove.push(origOperation.uuid);
      }
    }
  }
  return templatePatchRequest;
}

export function hasOperationChanges(origOperation, updatedOperation) {
  if (isNullOrUndefined(origOperation)) {
    return false;
  }
  if (
    origOperation?.department?.uuid !== updatedOperation?.department?.uuid ||
    origOperation?.duration !== updatedOperation?.duration ||
    origOperation?.enabled !== updatedOperation?.enabled ||
    origOperation?.offsetTypeId !== updatedOperation?.offsetTypeId ||
    origOperation?.startOffsetMinutes !== updatedOperation?.startOffsetMinutes
  ) {
    return true;
  }
  // Also check requirements
  const origRequirements = origOperation?.turnaroundRequirements;
  const updatedRequirements = updatedOperation?.turnaroundRequirements;
  if (origRequirements?.length !== updatedRequirements?.length) {
    return true;
  }
  if (!isEmptyList(updatedRequirements) && !isEmptyList(origRequirements)) {
    for (let i = 0; i < updatedRequirements.length; i++) {
      const updatedRequirement = updatedRequirements[i];
      const origRequirement = getByFieldValue(
        origRequirements,
        "uuid",
        updatedRequirement.uuid
      );
      if (
        isNullOrUndefined(origRequirement) ||
        updatedRequirement?.quantity !== origRequirement?.quantity ||
        updatedRequirement?.certification?.uuid !==
          origRequirement?.certification?.uuid
      ) {
        return true;
      }
    }
  }
  return false;
}
