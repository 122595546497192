import "./styles.css";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseBtn } from "../../assets/close-btn.svg";
import { ReactComponent as LeftArrow } from "../../assets/arrow-left-sm.svg";
import { EMPTY_TIME } from "../../constants";
import { getNameKeyForOperationOffsetType } from "../../turnaroundUtils";
import { isUserOnMap } from "../../userUtils";
import {
  formatTime,
  getByFieldValue,
  getMapFromList,
  isBlank,
  isNullOrUndefined,
  scrollElementIntoView,
  sortByField,
} from "../../utils";
import TurnaroundCrew from "./TurnaroundCrew";
import { useEffect, useMemo, useState } from "react";
import StatusBadge from "../StatusBadge";
import { useMainDispatchContext } from "../../MainContext";
import { getTurnaroundDetails, patchTurnaroundProfile } from "../../api";

function TurnaroundOperationPanel(props) {
  const {
    onClose,
    selectedOperation,
    airportTimezone,
    selectedUserUuid,
    onSelectUser,
    positions,
    users,
    showActions,
    onBack,
    showBackButton,
    selectedTurnaround,
    showAssignCrew,
    showUnassignCrew,
    showTurnaroundChat,
    onShowTurnaroundChatClick,
  } = props;
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const originalProfile = selectedTurnaround?.originalProfile;
  const { turnaroundRequirements } = selectedOperation;
  // NOTE: Service records come from the turnaround (no longer on operations)
  const userRecords = originalProfile?.userRecords;
  const [isSavingUnassignment, setIsSavingUnassignment] = useState(false);
  const crewAssignments = [];

  // Create user lookup
  const usersByUuid = useMemo(() => {
    return getMapFromList(users, "uuid");
  }, [users]);

  // Handle scrolling to the User tile
  useEffect(() => {
    if (!isNullOrUndefined(selectedUserUuid)) {
      const containerEl = document.querySelector(
        ".map .map-panel .overlay-panel-content"
      );
      const targetEl = document.querySelector(
        `[data-uuid='${selectedUserUuid}'`
      );
      scrollElementIntoView(containerEl, targetEl);
    }
  }, [selectedUserUuid]);

  // Extract all crewAssignments
  if (!isNullOrUndefined(turnaroundRequirements)) {
    for (let i = 0; i < turnaroundRequirements.length; i++) {
      const turnaroundRequirement = turnaroundRequirements[i];
      if (
        !isNullOrUndefined(turnaroundRequirement.crewAssignments) &&
        turnaroundRequirement.crewAssignments.length > 0
      ) {
        const crewAssignmentsToAdd = turnaroundRequirement.crewAssignments.map(
          (item) => {
            return {
              ...item,
              certificationName: turnaroundRequirement?.certification?.name,
              turnaroundRequirementUuid: turnaroundRequirement?.uuid,
            };
          }
        );
        crewAssignments.push(...crewAssignmentsToAdd);
      }
    }
  }
  const userRecordsByUserUuid = {};
  if (!isNullOrUndefined(userRecords)) {
    for (let i = 0; i < userRecords.length; i++) {
      const userRecord = { ...userRecords[i] };
      if (!isNullOrUndefined(userRecord?.user?.uuid)) {
        userRecordsByUserUuid[userRecord?.user?.uuid] = userRecord;
      }
    }
  }

  // Crew assignments include Accepted + Pending
  const assignedCrew = [];
  if (!isNullOrUndefined(crewAssignments)) {
    // Construct an info object to associate the crew assignment and userRecords
    for (let i = 0; i < crewAssignments.length; i++) {
      const crewAssignent = { ...crewAssignments[i] };
      if (!isBlank(crewAssignent.userUuid)) {
        const user = usersByUuid[crewAssignent.userUuid];
        const userRecord = userRecordsByUserUuid[crewAssignent.userUuid];
        crewAssignent.userRecord = userRecord;
        crewAssignent.fullName = !isNullOrUndefined(user)
          ? `${user.firstName} ${user.lastName}`
          : t("na");
        crewAssignent.user = user;
        crewAssignent.dispatchedAt = new Date().toISOString();
        assignedCrew.push(crewAssignent);
      }
    }
    sortByField(assignedCrew, "fullName");
  }

  // Determine if any missing crew requirements (Allocated but not assigned)
  const missingCrewRequirements = [];
  if (
    !isNullOrUndefined(originalProfile) &&
    !isNullOrUndefined(selectedOperation) &&
    selectedOperation.enabled
  ) {
    const operationToCheck = getByFieldValue(
      originalProfile.turnaroundOperations,
      "uuid",
      selectedOperation.uuid
    );

    const requirements = !isNullOrUndefined(operationToCheck)
      ? operationToCheck.turnaroundRequirements
      : [];
    for (let i = 0; i < requirements.length; i++) {
      const requirement = requirements[i];
      const isCrewRequirement = isNullOrUndefined(
        requirement.groundVehicleTypeUuid
      );
      const crewRequired = isCrewRequirement ? requirement.quantity : 0;
      const crewAssignments = !isNullOrUndefined(requirement.crewAssignments)
        ? requirement.crewAssignments
        : [];
      const crewAssignmentsNeeded =
        crewAssignments.length < crewRequired
          ? crewRequired - crewAssignments.length
          : 0;
      if (crewAssignmentsNeeded > 0) {
        // Add them in one by one so we can render in the UI
        for (let j = 0; j < crewAssignmentsNeeded; j++) {
          missingCrewRequirements.push({
            id: `${requirement.uuid}_${j}`, // Only for UI id
            requirement: requirement,
          });
        }
      }
    }
  }
  const hasAssignedCrew =
    !isNullOrUndefined(assignedCrew) && assignedCrew.length > 0;

  const operationOffsetTypeName = t(
    getNameKeyForOperationOffsetType(selectedOperation?.offsetTypeId)
  );
  const expectedStartTime = selectedOperation?.expectedStartTime;
  const expectedEndTime = selectedOperation?.expectedEndTime;
  const completedAt = selectedOperation?.completedAt;
  const startedAt = selectedOperation?.startedAt;

  async function handleUnassignCrew(userInfo) {
    if (isNullOrUndefined(userInfo)) return;
    // NOTE: Profile is required, if not present then can not modify
    if (isNullOrUndefined(selectedTurnaround?.originalProfile)) return;
    setIsSavingUnassignment(true);

    const requirementsForPatch = [];
    const requirementUuid = userInfo?.turnaroundRequirementUuid;
    const requirementToUnassign = getByFieldValue(
      turnaroundRequirements,
      "uuid",
      requirementUuid
    );

    if (!isNullOrUndefined(requirementToUnassign)) {
      // Remove from crew assignments, quantity stays the same
      requirementsForPatch.push({
        userUuidsToRemove: [userInfo.userUuid],
        uuid: requirementToUnassign.uuid,
      });

      const patchRequest = {
        uuid: selectedTurnaround?.originalProfile?.uuid,
        operations: [],
        requirements: requirementsForPatch,
        parentLabelUuidsToApply: [],
        labelUuidsToRemove: [],
      };
      const result = await patchTurnaroundProfile(dispatch, patchRequest);
      await getTurnaroundDetails(dispatch, selectedTurnaround);
      if (result) {
        let dispatchMessage = `${t("saved_web", {
          name: selectedTurnaround.combinedFlightName,
        })}`;
        dispatch({
          type: "setAlertMessage",
          alertMessage: dispatchMessage,
        });
      }
    }
    setIsSavingUnassignment(false);
  }

  const showTurnaroundChatButton =
    showTurnaroundChat &&
    !isNullOrUndefined(onShowTurnaroundChatClick) &&
    !isNullOrUndefined(selectedTurnaround?.chatChannelId);

  return (
    <>
      <div className="context-panel-content-header">
        <div className="back-btn">
          <div>
            {showBackButton && (
              <button className="outline-filled" onClick={onBack}>
                <LeftArrow /> {t("back")}
              </button>
            )}
          </div>
        </div>
        <div className="close-btn" onClick={onClose}>
          <CloseBtn />
        </div>
      </div>
      <div className="context-panel-titlebar">
        <h4>
          {selectedOperation?.type.name}
          {true && (
            <>
              <div className="status-info-pulse fadeInLoop"></div>
            </>
          )}
        </h4>
        <div className="context-panel-titlebar-details">
          <div>
            <div>
              <StatusBadge
                isAllCap={true}
                isOutlined={true}
                message={operationOffsetTypeName}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="context-panel-container">
        <div className="turnaround-content open">
          <div className="turnaround-general">
            <div className="stat">
              {isNullOrUndefined(startedAt) && (
                <>
                  <label>{t("expected_start")}</label>
                  <span>
                    {!isNullOrUndefined(expectedStartTime)
                      ? formatTime(expectedStartTime, airportTimezone)
                      : EMPTY_TIME}
                  </span>
                </>
              )}
              {!isNullOrUndefined(startedAt) && (
                <>
                  <label>{t("started")}</label>
                  <span>{formatTime(startedAt, airportTimezone)}</span>
                </>
              )}
            </div>
            <div className="stat">
              {isNullOrUndefined(startedAt) &&
                isNullOrUndefined(completedAt) && (
                  <>
                    <label>{t("expected_complete")}</label>
                    <span>
                      {!isNullOrUndefined(expectedEndTime)
                        ? formatTime(expectedEndTime, airportTimezone)
                        : EMPTY_TIME}
                    </span>
                  </>
                )}
              {(!isNullOrUndefined(startedAt) ||
                !isNullOrUndefined(completedAt)) && (
                <>
                  <label>{t("completed_non_cap")}</label>
                  <span>
                    {!isNullOrUndefined(completedAt)
                      ? formatTime(completedAt, airportTimezone)
                      : EMPTY_TIME}
                  </span>
                </>
              )}
            </div>
          </div>
          {showTurnaroundChatButton && (
            <div className="turnaround-action">
              <button className="alternate" onClick={onShowTurnaroundChatClick}>
                {t("view_turnaround_chat")}
              </button>
            </div>
          )}

          {hasAssignedCrew && (
            <>
              <div className="turnaround-crew-list">
                <div>
                  <div className="context-panel-section-header">
                    {t("crew")}
                  </div>
                </div>
                {assignedCrew &&
                  assignedCrew.map((item) => (
                    <TurnaroundCrew
                      key={item.uuid}
                      crewInfo={item}
                      operationName={selectedOperation?.name}
                      airportTimezone={airportTimezone}
                      selected={selectedUserUuid === item?.user?.uuid}
                      showCertifications={true}
                      showActions={showActions}
                      isSelectable={
                        !isNullOrUndefined(onSelectUser) &&
                        isUserOnMap(item?.user, positions)
                      }
                      onClick={() => {
                        dispatch({
                          type: "setItemsToWhitelist",
                          value: null,
                        });
                        if (
                          !isNullOrUndefined(onSelectUser) &&
                          !isNullOrUndefined(item?.user?.uuid)
                        ) {
                          onSelectUser(item.user.uuid);
                        }
                      }}
                      showAssignCrew={showAssignCrew}
                      showUnassignCrew={showUnassignCrew}
                      onUnassignCrew={() => {
                        if (!showUnassignCrew) return;
                        handleUnassignCrew(item);
                      }}
                      isSavingUnassignment={isSavingUnassignment}
                    />
                  ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
export default TurnaroundOperationPanel;
