import {
  deepCopy,
  getByFieldValue,
  isEmptyList,
  isNullOrUndefined,
} from "./utils";

export function mergeRosters(departmentRosterByDepartmentId) {
  let rosterInfo = null;
  if (!isNullOrUndefined(departmentRosterByDepartmentId)) {
    const departmentIds = Object.keys(departmentRosterByDepartmentId);
    for (let i = 0; i < departmentIds.length; i++) {
      const departmentId = departmentIds[i];
      const departmentRoster = departmentRosterByDepartmentId[departmentId];
      if (isNullOrUndefined(rosterInfo)) {
        rosterInfo = deepCopy(departmentRoster);
      } else {
        // Not merging any unassignedTasks for now
        if (!isEmptyList(departmentRoster.users)) {
          for (let j = 0; j < departmentRoster.users.length; j++) {
            const user = departmentRoster.users[j];
            const existingUserRoster = getByFieldValue(
              rosterInfo.users,
              "uuid",
              user.uuid
            );
            if (existingUserRoster) {
              // Merge shifts
              if (!isEmptyList(user?.shifts)) {
                user.shifts.forEach((shift) => {
                  if (isNullOrUndefined(existingUserRoster.shifts)) {
                    existingUserRoster.shifts = [deepCopy(shift)];
                  } else {
                    existingUserRoster.shifts.push(deepCopy(shift));
                  }
                });
              }
              // Merge tasks
              if (!isEmptyList(user?.tasks)) {
                user.tasks.forEach((task) => {
                  if (isNullOrUndefined(existingUserRoster.tasks)) {
                    existingUserRoster.tasks = [deepCopy(task)];
                  } else {
                    existingUserRoster.tasks.push(deepCopy(task));
                  }
                });
              }
            } else if (!isNullOrUndefined(user)) {
              // Add the user roster
              if (isNullOrUndefined(rosterInfo.users)) {
                rosterInfo.users = [];
              }
              rosterInfo.users.push(deepCopy(user));
            }
          }
        }
      }
    }
  }
  return rosterInfo;
}
