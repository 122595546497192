import { getLocalMoment, isBlank, isNullOrUndefined } from "./utils";
import moment from "moment-timezone";

export const SEARCH_PARAM_DATE_FORMAT = "YYYY-MM-DD";

export function getDayInitial(dateObj) {
  return getDayAbbreviation(dateObj).substring(0, 1);
}

export function getDayAbbreviation(dateObj) {
  return getLocalMoment(dateObj)
    .format("ddd")
    .toUpperCase()
    .replaceAll(".", "");
}

// Returns true if d1 after d2
export function isDateAfter(d1, d2) {
  if (isNullOrUndefined(d1) || isNullOrUndefined(d2)) return false;
  const date1 = new Date(d1);
  const date2 = new Date(d2);
  return date1.getTime() > date2.getTime();
}

// Returns true if d1 before d2
export function isDateBefore(d1, d2) {
  if (isNullOrUndefined(d1) || isNullOrUndefined(d2)) return false;
  const date1 = new Date(d1);
  const date2 = new Date(d2);
  return date1.getTime() < date2.getTime();
}

// Returns duration in minutes
export function getDuration(d1, d2) {
  if (isNullOrUndefined(d1) || isNullOrUndefined(d2)) return 0;

  return moment
    .duration(moment(d2).startOf("minute").diff(moment(d1).startOf("minute")))
    .as("minutes");
}

export function isValidDateString(dateString) {
  return !isBlank(dateString) && !isNaN(Date.parse(dateString));
}

export function dateAdd(d, numOfYears) {
  const newDate = !isNullOrUndefined(d) ? new Date(d) : new Date();
  if (!isNaN(numOfYears)) {
    newDate.setFullYear(d.getFullYear() + numOfYears);
  }
  return newDate;
}
