import {
  dedupeByField,
  isEmptyList,
  logAnalyticsEvent,
  sortByField,
} from "../../utils";
import { ReactComponent as Pencil } from "../../assets/pencil.svg";
import { useTranslation } from "react-i18next";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import {
  ResourceActions,
  userHasAnyResourceAction,
} from "../../resourceActionsUtils";
import { ANALYTICS_EVENTS } from "../../constants";
import { Fade, Tooltip } from "@mui/material";
import { tooltipLightStyles } from "../../styleUtils";

function TurnaroundLeads(props) {
  const { item, onClick } = props;
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();

  const mainContext = useMainContext();
  const { currentUser } = mainContext;

  const isReadonly =
    !userHasAnyResourceAction(currentUser, ResourceActions.EditTurnProfile) ||
    item.isCompleted;

  const leadsToRender = !isEmptyList(item?.leads)
    ? dedupeByField(item?.leads, "user.uuid")
    : null;
  if (!isEmptyList(leadsToRender)) {
    sortByField(leadsToRender, "user.fullName");
  }

  return (
    <Tooltip
      title={
        <div className="tooltip-content">
          {isEmptyList(leadsToRender) && <>{t("na")}</>}
          {!isEmptyList(leadsToRender) &&
            leadsToRender.map((userInfo) => (
              <div key={userInfo.uuid}>{userInfo.fullName}</div>
            ))}
        </div>
      }
      placement="top"
      componentsProps={tooltipLightStyles}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 500 }}
      enterDelay={1000}
      arrow
    >
      <div
        className={`turnaround-leads${!isReadonly ? " active" : ""}`}
        onClick={(e) => {
          if (!isReadonly) {
            onClick();
            logAnalyticsEvent(
              dispatch,
              ANALYTICS_EVENTS.TURNAROUNDS_ASSIGN_LEADS
            );
          }
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div>
          <div
            className={`turnaround-leads-rows${
              !isEmptyList(leadsToRender) && leadsToRender.length > 1
                ? " trimmed"
                : ""
            }`}
          >
            {isEmptyList(leadsToRender) && (
              <div className="turnaround-leads-row empty">{t("na")}</div>
            )}
            {!isEmptyList(leadsToRender) &&
              leadsToRender.map((userInfo) => (
                <div className="turnaround-leads-row" key={userInfo.uuid}>
                  {userInfo.fullName}
                </div>
              ))}
          </div>

          <div className="turnaround-leads-actions">
            <div>
              <div className="action-icon">
                <Pencil />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Tooltip>
  );
}

export default TurnaroundLeads;
