import {
  formatDate,
  formatHoursMins,
  formatTime,
  isBlank,
  isEmptyList,
  isNullOrUndefined,
  zeroPad,
} from "../../utils";
import {
  resolveTurnaroundStands,
  TurnaroundStatus,
} from "../../turnaroundUtils";
import TurnaroundLeads from "../TurnaroundsModule/TurnaroundLeads";
import TurnaroundRemarks from "../TurnaroundsModule/TurnaroundRemarks";

import { ColumnActionType } from ".";
import { EMPTY_VALUE, FliteTimeTypes } from "../../constants";
import TurnaroundName from "../TurnaroundsModule/TurnaroundName";
import { getUserInfo } from "../../userUtils";
import { dateAdd } from "../../dateUtils";
import TurnaroundOperationProgress from "../TurnaroundsModule/TurnaroundOperationProgress";

export const TurnaroundsModuleColumns = {
  TurnaroundName: {
    id: "turnaround_name",
    displayName: "turnaround",
    formatter: (item) => {
      const origin = !isBlank(item?.inboundFlight?.originAirportIata)
        ? item?.inboundFlight?.originAirportIata
        : null;
      const destination = !isBlank(item?.outboundFlight?.destAirportIata)
        ? item?.outboundFlight?.destAirportIata
        : null;

      const detail =
        !isBlank(origin) && !isBlank(destination)
          ? `${origin} - ${destination}`
          : origin;

      return (
        <div className="turnaround-name">
          <div>
            <TurnaroundName item={item} detail={detail} />
          </div>
        </div>
      );
    },
    sortValue: (item) => `${item.combinedFlightName}`,
  },
  Registration: {
    id: "registration",
    displayName: "registration",
    formatter: (item) => {
      return item?.registration;
    },
    sortValue: (item) => `${item.registration}`,
  },
  Landing: {
    id: "landing",
    displayName: "landing_non_cap",
    formatter: (item) => {
      return formatTime(item?.landing, item?.timezone);
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.landing) ? new Date(item?.landing) : null,
  },
  ScheduledIn: {
    id: "scheduled_in",
    displayName: "scheduled_time_arrival_abbr",
    excludeFromSearch: true,
    formatter: (item) => {
      const isInvalidTime =
        !item?.hasValidTurnaroundTime &&
        item?.resolvedInboundTimes?.displayTimeType ===
          FliteTimeTypes.SCHEDULED;

      const itemTime = item?.inboundFlight?.timestamps?.scheduledGateIn;
      const timeVal = !isNullOrUndefined(itemTime)
        ? formatTime(itemTime, item?.timezone, !item?.showDate)
        : EMPTY_VALUE;

      const dateVal = !isNullOrUndefined(itemTime)
        ? formatDate(
            item?.inboundFlight?.timestamps?.scheduledGateIn,
            item?.timezone
          )
        : null;
      return (
        <div
          className={`turnaround-time-values${
            isInvalidTime ? " invalid-time" : ""
          }`}
        >
          <div>{timeVal}</div>
          {item?.showDate && !isNullOrUndefined(itemTime) && (
            <div>
              <span>{dateVal}</span>
            </div>
          )}
        </div>
      );
    },
    sortValue: (item) => {
      return generateDateSortHash(
        item,
        item?.inboundFlight?.timestamps?.scheduledGateIn
      );
    },
  },
  EstimatedIn: {
    id: "estimated_in",
    displayName: "estimated_time_arrival_abbr",
    excludeFromSearch: true,
    formatter: (item) => {
      const isInvalidTime =
        !item?.hasValidTurnaroundTime &&
        item?.resolvedInboundTimes?.displayTimeType ===
          FliteTimeTypes.ESTIMATED;
      const itemTime = item?.inboundFlight?.timestamps?.estimatedGateIn;
      const hasValue = !isNullOrUndefined(itemTime);
      const timeVal = hasValue
        ? formatTime(
            item?.inboundFlight?.timestamps?.estimatedGateIn,
            item?.timezone,
            !item?.showDate
          )
        : EMPTY_VALUE;
      const dateVal = hasValue
        ? formatDate(
            item?.inboundFlight?.timestamps?.estimatedGateIn,
            item?.timezone
          )
        : null;
      return (
        <div
          className={`turnaround-time-values${
            hasValue ? " highlight-value" : ""
          }${isInvalidTime ? " invalid-time" : ""}`}
        >
          <div>{timeVal}</div>
          {item?.showDate && !isNullOrUndefined(itemTime) && (
            <div>
              <span>{dateVal}</span>
            </div>
          )}
        </div>
      );
    },
    sortValue: (item) => {
      return generateDateSortHash(
        item,
        item?.inboundFlight?.timestamps?.estimatedGateIn
      );
    },
  },
  ActualIn: {
    id: "actual_in",
    displayName: "actual_time_arrival_abbr",
    excludeFromSearch: true,
    formatter: (item) => {
      const isInvalidTime =
        !item?.hasValidTurnaroundTime &&
        item?.resolvedInboundTimes?.displayTimeType === FliteTimeTypes.ACTUAL;
      const itemTime = item?.inboundFlight?.timestamps?.actualGateIn;
      const hasValue = !isNullOrUndefined(itemTime);
      const timeVal = hasValue
        ? formatTime(
            item?.inboundFlight?.timestamps?.actualGateIn,
            item?.timezone,
            !item?.showDate
          )
        : EMPTY_VALUE;
      const dateVal = hasValue
        ? formatDate(
            item?.inboundFlight?.timestamps?.actualGateIn,
            item?.timezone
          )
        : null;
      return (
        <div
          className={`turnaround-time-values${
            hasValue ? " highlight-value" : ""
          }${isInvalidTime ? " invalid-time" : ""}`}
        >
          <div>{timeVal}</div>
          {item?.showDate && !isNullOrUndefined(itemTime) && (
            <div>
              <span>{dateVal}</span>
            </div>
          )}
        </div>
      );
    },
    sortValue: (item) => {
      return generateDateSortHash(
        item,
        item?.inboundFlight?.timestamps?.actualGateIn
      );
    },
  },
  ScheduledOut: {
    id: "scheduled_out",
    displayName: "scheduled_time_departure_abbr",
    excludeFromSearch: true,
    formatter: (item) => {
      const isInvalidTime =
        !item?.hasValidTurnaroundTime &&
        item?.resolvedOutboundTimes?.displayTimeType ===
          FliteTimeTypes.SCHEDULED;
      const itemTime = item?.outboundFlight?.timestamps?.scheduledGateOut;
      const hasValue = !isNullOrUndefined(itemTime);
      const timeVal = hasValue
        ? formatTime(
            item?.outboundFlight?.timestamps?.scheduledGateOut,
            item?.timezone,
            !item?.showDate
          )
        : EMPTY_VALUE;
      const dateVal = hasValue
        ? formatDate(
            item?.outboundFlight?.timestamps?.scheduledGateOut,
            item?.timezone
          )
        : null;
      return (
        <div
          className={`turnaround-time-values${
            isInvalidTime ? " invalid-time" : ""
          }`}
        >
          <div>{timeVal}</div>
          {item?.showDate && !isNullOrUndefined(itemTime) && (
            <div>
              <span>{dateVal}</span>
            </div>
          )}
        </div>
      );
    },
    sortValue: (item) => {
      return generateDateSortHash(
        item,
        item?.outboundFlight?.timestamps?.scheduledGateOut
      );
    },
  },
  EstimatedOut: {
    id: "estimated_out",
    displayName: "estimated_time_departure_abbr",
    excludeFromSearch: true,
    formatter: (item) => {
      const isInvalidTime =
        !item?.hasValidTurnaroundTime &&
        item?.resolvedOutboundTimes?.displayTimeType ===
          FliteTimeTypes.ESTIMATED;
      const itemTime = item?.outboundFlight?.timestamps?.estimatedGateOut;
      const hasValue = !isNullOrUndefined(itemTime);
      const timeVal = hasValue
        ? formatTime(
            item?.outboundFlight?.timestamps?.estimatedGateOut,
            item?.timezone,
            !item?.showDate
          )
        : EMPTY_VALUE;
      const dateVal = hasValue
        ? formatDate(
            item?.outboundFlight?.timestamps?.estimatedGateOut,
            item?.timezone
          )
        : null;
      return (
        <div
          className={`turnaround-time-values${
            hasValue ? " highlight-value" : ""
          }${isInvalidTime ? " invalid-time" : ""}`}
        >
          <div>{timeVal}</div>
          {item?.showDate && !isNullOrUndefined(itemTime) && (
            <div>
              <span>{dateVal}</span>
            </div>
          )}
        </div>
      );
    },
    sortValue: (item) => {
      return generateDateSortHash(
        item,
        item?.outboundFlight?.timestamps?.estimatedGateOut
      );
    },
  },
  ActualOut: {
    id: "actual_out",
    displayName: "actual_time_departure_abbr",
    excludeFromSearch: true,
    formatter: (item) => {
      const isInvalidTime =
        !item?.hasValidTurnaroundTime &&
        item?.resolvedOutboundTimes?.displayTimeType === FliteTimeTypes.ACTUAL;
      const itemTime = item?.outboundFlight?.timestamps?.actualGateOut;
      const hasValue = !isNullOrUndefined(itemTime);
      const timeVal = hasValue
        ? formatTime(
            item?.outboundFlight?.timestamps?.actualGateOut,
            item?.timezone,
            !item?.showDate
          )
        : EMPTY_VALUE;
      const dateVal = hasValue
        ? formatDate(
            item?.outboundFlight?.timestamps?.actualGateOut,
            item?.timezone
          )
        : EMPTY_VALUE;
      return (
        <div
          className={`turnaround-time-values${
            hasValue ? " highlight-value" : ""
          }${isInvalidTime ? " invalid-time" : ""}`}
        >
          <div>{timeVal}</div>
          {item?.showDate && !isNullOrUndefined(itemTime) && (
            <div>
              <span>{dateVal}</span>
            </div>
          )}
        </div>
      );
    },
    sortValue: (item) => {
      return generateDateSortHash(
        item,
        item?.outboundFlight?.timestamps?.actualGateOut
      );
    },
  },
  Takeoff: {
    id: "takeoff",
    displayName: "takeoff_non_cap",
    formatter: (item) => {
      return formatTime(item?.takeoff, item?.timezone);
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.takeoff) ? new Date(item?.takeoff) : null,
  },
  TotalGate: {
    id: "total_gate_time",
    displayName: "total_gate_time",
    formatter: (item) => {
      return formatHoursMins(item?.gateTimeMinutes);
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.gateTimeMinutes) ? item?.gateTimeMinutes : 0,
  },
  TotalGround: {
    id: "total_ground_time",
    displayName: "total_ground_time",
    formatter: (item) => {
      return formatHoursMins(item?.groundTimeMinutes);
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.groundTimeMinutes) ? item?.groundTimeMinutes : 0,
  },
  Status: {
    id: "status",
    displayName: "status",
    formatter: (item) => {
      let statusClass = `status-${item.statusCode}`;
      const statusName = item?.statusDisplayName;
      return (
        <div
          className={`datatable-turnaround-status ${statusClass}`}
          data-status={statusName}
        >
          {statusName}
        </div>
      );
    },
    sortValue: (item) => {
      if (item.statusCode === TurnaroundStatus.TurnaroundInProgress) {
        return 0;
      } else if (item.statusCode === TurnaroundStatus.TurnaroundComplete) {
        return 3;
      } else if (item.statusCode === TurnaroundStatus.TurnaroundPlanned) {
        return 1;
      } else if (item.statusCode === TurnaroundStatus.TurnaroundNotConfigured) {
        return 2;
      }
    },
  },
  Leads: {
    id: "lead",
    displayName: "lead",
    formatter: (item, actionHandler) => {
      return (
        <div>
          <TurnaroundLeads
            item={item}
            onClick={() => {
              actionHandler(ColumnActionType.DISPATCH_EVENT, null, null, {
                type: "setTurnaroundForAssignment",
                value: item,
              });
            }}
          />
        </div>
      );
    },
    sortValue: (item) => {
      let leadNames = "";
      if (!isEmptyList(item?.leads)) {
        const nameList = item.leads.map((i) => {
          const userInfo = getUserInfo(i.user);
          return userInfo.fullName;
        });
        nameList.sort();
        leadNames = nameList.join(",");
      }
      return leadNames;
    },
  },
  Stand: {
    id: "stand",
    displayName: "stand_non_cap",
    formatter: (item) => {
      const turnaroundStands = resolveTurnaroundStands(item);
      const hasPrevStand = !isBlank(turnaroundStands.prevStand);

      return (
        <div className="turnaround-stand">
          <div className={hasPrevStand ? "highlight-value" : ""}>
            {turnaroundStands.currentStand}
          </div>
          {hasPrevStand && (
            <div className="previous-value">{turnaroundStands.prevStand}</div>
          )}
        </div>
      );
    },
    sortValue: (item) => {
      const turnaroundStands = resolveTurnaroundStands(item);
      return turnaroundStands.currentStand;
    },
  },
  Remarks: {
    id: "remarks",
    displayName: "remarks",
    formatter: (item) => {
      return (
        <div>
          <TurnaroundRemarks item={item} isReadonly={true} />
        </div>
      );
    },
    sortValue: (item) => item.combinedFlightName,
  },
  OriginDestination: {
    id: "origin_destination",
    displayName: "origin_destination",
    formatter: (item) => {
      const origin = !isBlank(item?.inboundFlight?.originAirportIata)
        ? item?.inboundFlight?.originAirportIata
        : null;
      const destination = !isBlank(item?.outboundFlight?.destAirportIata)
        ? item?.outboundFlight?.destAirportIata
        : null;
      return (
        <div className="origin-destination">
          <div>
            {origin}
            {!isBlank(origin) && !isBlank(destination) && <>&nbsp;-&nbsp;</>}
          </div>
          <div>{destination}</div>
        </div>
      );
    },
    sortValue: (item) => item.combinedFlightName,
  },
  TurnaroundOperationProgress: {
    id: "turnaround-operation-progress",
    displayName: "turnaround_operation_progress",
    formatter: (item) => {
      return <TurnaroundOperationProgress item={item} />;
    },
    sortValue: (item) => {
      // return a modified version of the date sort hash with progress values
      const dateSortHash = generateDateSortHash(
        item,
        item?.resolvedInboundTimes?.displayTime
      );
      const progressRemainingHash = zeroPad(
        Math.round((1 - item.operationPercentComplete) * 100),
        3
      );
      return `${progressRemainingHash}_${dateSortHash}`;
    },
  },
};

// Creates hash based on timestamp and other fields for sorting the Turnarounds
// In-progress always at the top and then timestamp and flight names (tie break if timestamp is same)
function generateDateSortHash(item, timestamp) {
  let statusCode = "";
  if (item.operationPercentComplete > 0) {
    statusCode = 0;
  } else if (item.operationPercentComplete === 100) {
    // Completed last/bottom
    statusCode = 2;
  } else {
    // All other status sort after in-progress
    statusCode = 1;
  }

  const dateVal = !isNullOrUndefined(timestamp)
    ? new Date(timestamp).getTime()
    : dateAdd(new Date(), 100).getTime(); // Sets way out into the future so that it sorts last
  const dateValPadded = zeroPad(dateVal, 18);
  return `${statusCode}_${dateValPadded}_${item.combinedFlightName}`;
}
