import { isNullOrUndefined } from "../../utils";
import "./styles.css";
function ProgressRing(props) {
  const { operationSummary } = props;
  const FULL_VALUE = 64; //
  const cx = 12;
  const cy = 12;
  const r = 10;

  const enabledCount = !isNullOrUndefined(operationSummary?.enabledCount)
    ? operationSummary?.enabledCount
    : 0;
  const completedCount = !isNullOrUndefined(operationSummary?.completedCount)
    ? operationSummary?.completedCount
    : 0;
  const startedCount = !isNullOrUndefined(operationSummary?.startedCount)
    ? operationSummary?.startedCount
    : 0;

  const isComplete = enabledCount > 0 && completedCount === enabledCount;

  const mainPercent =
    enabledCount > 0 && completedCount > 0 ? completedCount / enabledCount : 0;

  const secondaryPercent =
    enabledCount > 0 && startedCount > 0 ? startedCount / enabledCount : 0;

  // Compute the strokeDashoffset, zero is considered full
  const secondaryStyle = {
    strokeDashoffset: (1 - secondaryPercent) * FULL_VALUE,
  };
  const mainStyle = {
    strokeDashoffset: (1 - mainPercent) * FULL_VALUE,
  };
  return (
    <div className={`progress-ring${isComplete ? " complete" : ""}`}>
      <div>
        <div className="progress-ring-circles">
          <svg height="24" width="24">
            <circle
              cx={cx}
              cy={cy}
              r={r}
              strokeLinecap="round"
              className="base"
            />
            <circle
              cx={cx}
              cy={cy}
              r={r}
              strokeLinecap="round"
              className="secondary"
              style={secondaryStyle}
            />
            <circle
              cx={cx}
              cy={cy}
              r={r}
              strokeLinecap="round"
              className="main"
              style={mainStyle}
            />
          </svg>
        </div>
      </div>
      <div className="progress-ring-stats">
        {completedCount}/{enabledCount}
      </div>
    </div>
  );
}

export default ProgressRing;
