import { useReducer } from "react";
import {
  TasksOverviewContext,
  TasksOverviewDispatchContext,
} from "./TasksOverviewContext";
import { deepCopy, isNullOrUndefined } from "./utils";
import { mergeRosters } from "./rosterUtils";

export const initialState = {
  globalError: null,
  departmentRoster: [],
  departmentRosterLoading: false,
  departmentRosterByDepartmentId: {},
  departmentRosterByDepartmentIdLoading: false,
  mergedDepartmentRosters: {},
};

export function tasksOverviewReducer(state, action) {
  switch (action.type) {
    case "setGlobalError":
      return {
        ...state,
        globalError: action.globalError,
      };
    case "setDepartmentRoster":
      return {
        ...state,
        departmentRoster: action.value,
      };
    case "setDepartmentRosterLoading":
      return {
        ...state,
        departmentRosterLoading: action.value,
      };
    case "setDepartmentRosterByDepartmentId":
      const resourceName = action.resourceName;
      const resourceNameParts = !isNullOrUndefined(resourceName)
        ? resourceName.split("/")
        : null;
      const departmentId =
        resourceNameParts?.length > 2 ? resourceNameParts[1] : null;
      const updatedValue = deepCopy(state.departmentRosterByDepartmentId);
      updatedValue[departmentId] = action.value;

      // Update the merged value here
      const mergedDepartmentRosters = mergeRosters(updatedValue);
      return {
        ...state,
        departmentRosterByDepartmentId: updatedValue,
        mergedDepartmentRosters,
      };
    case "setDepartmentRosterByDepartmentIdLoading":
      return {
        ...state,
        departmentRosterByDepartmentIdLoading: action.value,
      };
    default:
      console.log(`Unknown action: ${action.type}`);
      return {
        ...state,
      };
  }
}
export default function TasksOverviewProvider({ children }) {
  const [main, dispatch] = useReducer(tasksOverviewReducer, initialState);
  return (
    <TasksOverviewContext.Provider value={main}>
      <TasksOverviewDispatchContext.Provider value={dispatch}>
        {children}
      </TasksOverviewDispatchContext.Provider>
    </TasksOverviewContext.Provider>
  );
}
