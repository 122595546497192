import { useTranslation } from "react-i18next";
import { isNullOrUndefined } from "../../utils";
import CrewInfo from "../CrewInfo";
import { getUserInfo } from "../../userUtils";
import { useMainContext } from "../../MainContext";
import StatusBadge from "../StatusBadge";
function TurnaroundCrew(props) {
  const {
    crewInfo,
    selected,
    isSelectable,
    onClick,
    showCertifications,
    showActions,
    showCrewStatus,
    showAssignCrew,
    onAssignCrew,
    showUnassignCrew,
    onUnassignCrew,
    isSavingUnassignment,
    isUnassigned,
  } = props;
  const mainContext = useMainContext();
  const { positions, turnaroundsSummary } = mainContext;
  if (isNullOrUndefined(crewInfo?.user)) return null;
  const userInfo = getUserInfo(crewInfo.user, turnaroundsSummary, positions);

  return (
    <div
      className={`turnaround-crew${selected ? " selected" : ""}${
        isSelectable ? " selectable" : ""
      }`}
      key={userInfo?.user.uuid}
      data-uuid={userInfo?.user.uuid}
      onClick={() => {
        if (isSelectable) {
          onClick();
        }
      }}
    >
      {userInfo && userInfo.user && (
        <CrewInfo
          user={userInfo.user}
          showActions={showActions}
          showAssignCrew={showAssignCrew}
          onAssignCrew={onAssignCrew}
          showUnassignCrew={showUnassignCrew}
          onUnassignCrew={onUnassignCrew}
          isSavingUnassignment={isSavingUnassignment}
          details={
            <CrewDetails
              crewInfo={crewInfo}
              showCertifications={showCertifications}
              showCrewStatus={showCrewStatus}
              isUnassigned={isUnassigned}
            />
          }
          mapStatus={userInfo.mapStatus}
        />
      )}
    </div>
  );
}

// Content to insert into the "details" for the CrewInfo component
function CrewDetails(props) {
  const { crewInfo, showCertifications, isUnassigned } = props;
  const { t } = useTranslation();
  if (isNullOrUndefined(crewInfo)) return null;

  return (
    <>
      {!isUnassigned && showCertifications && (
        <div>
          <div className="certification-name">
            <div>{crewInfo.certificationName}</div>
          </div>
        </div>
      )}
      {isUnassigned && (
        <div>
          <StatusBadge
            message={t("unassigned").toUpperCase()}
            isCritical={true}
          />
        </div>
      )}
    </>
  );
}

export default TurnaroundCrew;
